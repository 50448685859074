import React from "react";
import ReactDOM from "react-dom";
import AppRoute from "./router";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.less";
import "./assets/styles/style.css";
import "./assets/styles/font-apercu.css";
import "./assets/styles/font-acumin.css";
import "./assets/styles/font-montserrat.css"
import "./assets/styles/mobile.css";
import "./assets/styles/mobile2.css";

const client = new ApolloClient({
  uri: "https://rarity-v2.herokuapp.com/",
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    light: {
      main: "#fff",
      contrastText: "#000",
    },
    dark: {
      main: "#000",
      contrastText: "#fff",
    },
    ijo: {
      main: "#697f61",
      contrastText: "#697f61",
    },
    putih: {
      main: "#ffffff",
      contrastText: "#ffffff",
    }
  },
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  FormControl: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      BorderColor: "blue",
    },
  },
  Select: {
    "&:before": {
      BorderColor: "red",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRoute />
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
