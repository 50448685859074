import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Container, Button, IconButton } from "@mui/material";
import { Main, LoadingModal } from "../../components";
import { setStaking, setUnStaking, setWithdrawRewards } from "../../redux/actions/setupAction";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import CircularProgress from "@mui/material/CircularProgress";
import { getDailyReward, getFuzzBalance } from "../../redux/actions/shopAction";

export class Stacking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _unStakeGrid: true,
      _unStakeActive: [],
      _stakedGrid: true,
      _stakeActive: [],
      _submitStatus: {
        open: false,
        loading: true,
        wording: "",
        status: null,
      },
      stakingVersion: 2,
    };
  }

  renderEmpty = () =>
    new Array(0).fill().map((_, i) => (
      <Box component="li" key={i + 1} className="default">
        <Box className="img">
          <img src={require(`../../assets/img/web/img-default.png`)} alt="img default" />
        </Box>
      </Box>
    ));

  loadingBox = () => (
    <Box className="loadingBox">
      <CircularProgress sx={{ color: "#516946" }} />
    </Box>
  );

  componentDidMount() {
    const isStakingOld = window.location.href.includes("stakev1");
    const wallet_address = localStorage.getItem("LOCAL_STORAGE_USER_WALLET_ADDRESS");
    const {actionsetFuzzBalance} = this.props;
    const {actionsetDailyReward} = this.props;
    actionsetDailyReward({wallet_address});
    actionsetFuzzBalance({wallet_address});
    if (isStakingOld) {
      this.setState({
        stakingVersion: 1,
      });
    } else {
      this.setState({
        stakingVersion: 2,
      });
    }
  }

  render() {
    const { configStaking, listUnstake, listStake } = this.props;
    const { _unStakeGrid, _unStakeActive, _stakedGrid, _stakeActive, _submitStatus } = this.state;
    return (
      <Main passThis={this} passData={this.state}>
        <Box component="section" className="introduceStack" id="introduce__">
          <LoadingModal
            passData={_submitStatus}
            handleClose={() =>
              this.setState({
                _submitStatus: { open: false, loading: false, wording: "" },
              })
            }
          />
          <Container>
            <Box className="title">Staking</Box>
            <Box className="info-btn">
              <Box component="ul" className="info">
                <Box component="li" className="listInfo">
                  <div style={{ fontWeight: "bold", color: "#fff" }}>
                    {" "}
                    {/* Balance: {configStaking.balance || 0} */}
                    Balance: {this.props.shop.fuzzBalance || 0}

                  </div>
                </Box>
                
                <Box component="li" className="listInfo">
                  <div style={{ fontWeight: "bold", color: "#fff" }}>
                    {" "}
                    {/* $FUZZ/Day: {configStaking.fuzzPerDay || 0} */}
                    $FUZZ/Day: {this.props.shop.dailyReward || 0}

                  </div>
                </Box>
              </Box>
              <Box
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              
              </Box>
            </Box>

            <Box className="introduceStackOut">
              <Box className="introduceStackIns">
                <Box className="stack-ins">
                  <Box className="ttl_btn">
                    <Box className="title">Unstake Fuzzies</Box>
                    <Box>
                      <IconButton
                        component="span"
                        className={`btn_grid ${_unStakeGrid ? "active" : ""}`}
                        onClick={() => this.setState({ _unStakeGrid: true })}
                      >
                        <GridViewIcon />
                      </IconButton>
                      <IconButton
                        component="span"
                        className={`btn_grid ${_unStakeGrid ? "" : "active"}`}
                        onClick={() => this.setState({ _unStakeGrid: false })}
                      >
                        <ViewListIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  {
                    <Box component="ul" className={`list-stack ${_unStakeGrid ? "" : "_list"}`}>
                      {listUnstake.data.length > 0
                        ? listUnstake.data.map((res, i) => {
                            const name = res?.metadata
                              ? res?.metadata?.name
                              : `Fuzz-${res?.token_id}`;
                            const img = res?.metadata ? res?.metadata?.image : "-";
                            return (
                              <Box
                                component="li"
                                key={i}
                                className={_unStakeActive.includes(res.token_id) ? "active" : null}
                              >
                                <Button onClick={() => this.onChooseItem(res, "_unStakeActive")}>
                                  <Box className="_id">{name}</Box>
                                  <Box className="img">
                                    <img
                                      src={
                                        res?.metadata
                                          ? `https://fuzzyfighters.mypinata.cloud/ipfs/${img.replace(
                                              "ipfs://",
                                              ""
                                            )}?img-width=512`
                                          : require("../../assets/img/web/img-default.png")
                                      }
                                      alt="img list stack"
                                    />
                                  </Box>
                                </Button>
                              </Box>
                            );
                          })
                        : null}
                    </Box>
                  }

                  <Box className="btn_submit">
                    <Button
                      component="span"
                      onClick={this.onStaking}
                      disabled={_unStakeActive.length < 1 || listUnstake.loading}
                      sx={{
                        p: ".5rem 1rem",
                        background: "#CD7555",
                        border: "1px solid #000",
                        borderBottom: "5px solid #000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div style={{ fontWeight: "bold", color: "#000000", fontSize: "10px"  }}>
                        Stake Your Fuzzies!
                      </div>
                    </Button>
                  </Box>
                </Box>

                <Box className="stack-ins">
                  <Box className="ttl_btn">
                    <Box className="title">Staked Fuzzies</Box>
                    <Box>
                      <IconButton
                        component="span"
                        className={`btn_grid ${_stakedGrid ? "active" : ""}`}
                        onClick={() => this.setState({ _stakedGrid: true })}
                      >
                        <GridViewIcon />
                      </IconButton>
                      <IconButton
                        component="span"
                        className={`btn_grid ${!_stakedGrid ? "active" : ""}`}
                        onClick={() => this.setState({ _stakedGrid: false })}
                      >
                        <ViewListIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  {
                    <Box component="ul" className={`list-stack ${_stakedGrid ? "" : "_list"}`}>
                      {listStake.data.length > 0
                        ? listStake.data.map((res, i) => {
                            const name = res?.metadata
                              ? res?.metadata?.name
                              : `Fuzz-${res?.token_id}`;
                            const img = res?.metadata ? res?.metadata?.image : "-";
                            console.log(999, res, img);
                            return (
                              <Box
                                component="li"
                                key={i}
                                className={_stakeActive.includes(res.token_id) ? "active" : null}
                              >
                                <Button onClick={() => this.onChooseItem(res, "_stakeActive")}>
                                  <Box className="_id">{name}</Box>
                                  <Box className="img">
                                    <img
                                      src={
                                        res?.metadata
                                          ? `https://fuzzyfighters.mypinata.cloud/ipfs/${img.replace(
                                              "ipfs://",
                                              ""
                                            )}?img-width=512`
                                          : require("../../assets/img/web/img-default.png")
                                      }
                                      alt="img list stack"
                                    />
                                  </Box>
                                </Button>
                              </Box>
                            );
                          })
                        : null}
                    </Box>
                  }
                  <Box className="btn_submit">
                    <Button
                      component="span"
                      onClick={this.onUnStaking}
                      disabled={_stakeActive.length < 1 || listStake.loading}
                      sx={{
                        p: ".5rem 1rem",
                        background: "#BEBAB1",
                        border: "1px solid #000",
                        borderBottom: "5px solid #000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div style={{ fontWeight: "bold", color: "#000000", fontSize: "10px" }}>
                        Unstake Your Fuzzies!
                      </div>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Main>
    );
  }

  onChooseItem = (data, stateName) => {
    const token_id = data.token_id;
    var nData = this.state[stateName];
    !nData.includes(token_id) ? nData.push(token_id) : nData.splice(nData.indexOf(token_id), 1);
    return this.setState({
      [stateName]: nData,
    });
  };

  onStaking = async () => {
    const { actionsetStaking } = this.props;
    const { _unStakeActive } = this.state;
    return this.setState(
      {
        _submitStatus: { open: true, loading: true, wording: "Stake in Progress", status: null },
      },
      () => {
        return actionsetStaking(
          _unStakeActive,
          () => {
            return this.setState({
              _unStakeActive: [],
              _submitStatus: {
                open: true,
                loading: false,
                status: "success",
                wording: "Stake Successful",
              },
            });
          },
          () => {
            return this.setState({
              _unStakeActive: [],
              _submitStatus: {
                open: true,
                loading: false,
                status: "failed",
                wording: "Stake Failed",
              },
            });
          }
        );
      }
    );
  };

  onUnStaking = async () => {
    const { actionsetUnStaking } = this.props;
    const { _stakeActive } = this.state;
    return this.setState(
      {
        _submitStatus: { open: true, loading: true, wording: "Unstake in Progress", status: null },
      },
      () => {
        return actionsetUnStaking(
          _stakeActive,
          () => {
            return this.setState({
              _stakeActive: [],
              _submitStatus: {
                open: true,
                loading: false,
                status: "success",
                wording: "Unstake Successful",
              },
            });
          },
          () => {
            return this.setState({
              _stakeActive: [],
              _submitStatus: {
                open: true,
                loading: false,
                status: "failed",
                wording: "Unstake Failed",
              },
            });
          }
        );
      }
    );
  };

  onWithdraw = () => {
    const { actionsetWithdrawRewards } = this.props;
    return this.setState(
      {
        _submitStatus: {
          open: true,
          loading: true,
          wording: "Withdrawal in progress",
          status: null,
        },
      },
      () => {
        return actionsetWithdrawRewards(
          () => {
            return this.setState({
              _submitStatus: {
                open: true,
                loading: false,
                status: "success",
                wording: "Withdrawal Successful",
              },
            });
          },
          () => {
            return this.setState({
              _submitStatus: {
                open: true,
                loading: false,
                status: "failed",
                wording: "Withdrawal Failed",
              },
            });
          }
        );
      }
    );
  };

  connectWallet = () => {
    window.location.href = "/stakev1";
    // return this.props.passThis.props.history.push('/stakev1')
  };

  connectWallet2 = () => {
    window.location.href = "/stakev2";
    // return this.props.passThis.props.history.push('/stakev2')
  };
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  listUnstake: state.stake.unstake,
  listStake: state.stake.stake,
  wallet: state.wallet,
  shop: state.shop
});

const mapDispatchToProps = {
  actionsetStaking: setStaking,
  actionsetUnStaking: setUnStaking,
  actionsetWithdrawRewards: setWithdrawRewards,
  actionsetDailyReward: getDailyReward,
  actionsetFuzzBalance: getFuzzBalance
};

export default connect(mapStateToProps, mapDispatchToProps)(Stacking);