import React, { Component } from 'react'

export default class NotFound extends Component {
  render() {
    return (
      <section className='notfound' id="introduce__">
          <div className='notfoundIns'>
            404
          </div>
      </section>
    )
  }
}