import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useCountdown } from "../../../hooks/useCountdown";
import { connect } from "react-redux";
import { setRandomWinner } from "../../../redux/actions/shopAction";

const Countdown = ({ date, color = "black", inModalPurchase = false, showEnded = false, discord_id, actionGetProducts, title, setRandomWinner, isClosed }) => {
  const { daysString, hoursString, minutesString, secondsString, stopped, days } =
    useCountdown(date);

  useEffect(() => {
        if (stopped && !isClosed) {
      setRandomWinner(
        {
          // data: {
            shop: title
          // }
        }).then((res) => {
          // if (!res.error) {
            actionGetProducts({
              "pagination[page]": 1,
              discord_id,
              sort: "id:desc",
            })
          // }
        })
    }
  }, [stopped])

  let width = days <= 0 ? "40%" : "50%";
  if (inModalPurchase) width = "auto";
  if (stopped) {
    if (showEnded) {
      return (
        <Box
          bgcolor={"#8ca5b5"}
          width={width}
          style={{ color }}
          display={inModalPurchase ? "flex" : undefined}
          justifyContent={inModalPurchase ? "center" : undefined}
          alignItems={inModalPurchase ? "center" : undefined}
          p={inModalPurchase ? 1 : undefined}
        >
          <Typography
            fontSize={20}
            color={color}
            textAlign="center"
            style={{ marginTop: inModalPurchase ? 0 : -5 }}
            p={0}
          >
            ENDED
          </Typography>
        </Box>
      );
    }
    return null;
  }

  return (
    <Box
      bgcolor={"#FFD62C"}
      width={width}
      style={{ color }}
      display={inModalPurchase ? "flex" : undefined}
      justifyContent={inModalPurchase ? "space-between" : undefined}
      alignItems={inModalPurchase ? "center" : undefined}
      p={inModalPurchase ? 1 : undefined}
    >
      <Typography
        fontSize={10}
        color={color}
        textAlign="center"
        fontWeight={"bolder"}
        mb={0}
        p={0}
        mr={inModalPurchase ? 1 : 0}
      >
        End in
      </Typography>
      <Typography
        fontSize={20}
        color={color}
        textAlign="center"
        style={{ marginTop: inModalPurchase ? 0 : -5 }}
        p={0}
      >
        {days <= 0
          ? `${hoursString}:${minutesString}:${secondsString}`
          : `${daysString}:${hoursString}:${minutesString}:${secondsString}`}
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  shop: state.shop,
});

const mapDispatchToProps = {
  setRandomWinner: setRandomWinner
};


export default connect(mapStateToProps, mapDispatchToProps)(Countdown);
