import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setDisconnectWallet,
  seClearAlert,
  setConnectWallet,
  setInit,
} from "../redux/actions/setupAction";
import {
  AppBar,
  Toolbar,
  Link,
  Container,
  Box,
  IconButton,
  Button,
  ButtonBase,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import BtnDropDown from "./buttons/BtnDropDown";
import BtnDropDownWhite from "./buttons/BtnDropDownWhite";
import Modal from "@mui/material/Modal";
import ModalAbout from "../container/Home/partial/ModalAbout";
import ModalRoadmap from "../container/Home/partial/ModalRoadmap";
import ModalUtility from "../container/Home/partial/ModalUtility";
import ModalTeam from "../container/Home/partial/ModalTeam";

const release = process.env.REACT_APP_RELEASE;

function ElevationScroll(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "#FFFCEB" : "#FFFCEB",
      transition: trigger ? "0.3s" : "0.5s",
    },
  });
}

export class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _openMenu: false,
      show: true,
      showRoadmap: true,
      showUtility: true,
      showTeam: true,
      _menu: [
        { menuName: "About Us", link: "#about__" },
        { menuName: "Roadmap", link: "#roadmap__" },
        { menuName: "Utility", link: "#utility__" },
        { menuName: "Team", link: "#fuzzClan__" },
        { menuName: "FAQ", link: "#faq__" },
        { menuName: "Rarity", link: "rarity" },
      ],
      _menuLeft: [
        {
          menuName: "Discord",
          link: "https://discord.gg/qYWjcWFYYn",
          icon: require("../assets/img/web/ic_discord.png"),
        },
        {
          menuName: "Opensea",
          link: "https://opensea.io/collection/astrofuzz",
          icon: require("../assets/img/web/ic_opensea.png"),
        },
        {
          menuName: "Twitter",
          link: "https://twitter.com/AstroFuzz_",
          icon: require("../assets/img/web/ic_twitter.png"),
        },
      ],
      _openMenuBig: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    this.showModalRoadmap = this.showModalRoadmap.bind(this);
    this.hideModalRoadmap = this.hideModalRoadmap.bind(this);

    this.showModalUtility = this.showModalUtility.bind(this);
    this.hideModalUtility = this.hideModalUtility.bind(this);

    this.showModalTeam = this.showModalTeam.bind(this);
    this.hideModalTeam = this.hideModalTeam.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  showModalRoadmap = () => {
    this.setState({ showRoadmap: true });
  };

  hideModalRoadmap = () => {
    this.setState({ showRoadmap: false });
  };

  showModalUtility = () => {
    this.setState({ showUtility: true });
  };

  hideModalUtility = () => {
    this.setState({ showUtility: false });
  };

  showModalTeam = () => {
    this.setState({ showTeam: true });
  };

  hideModalTeam = () => {
    this.setState({ showTeam: false });
  };

  handleMenu = (type) => {
    return this.setState({
      _openMenu: type,
    });
  };

  handleMenuBig = (type) => {
    return this.setState({
      _openMenuBig: type,
    });
  };

  connectWallet = () => {
    const { actionSetConnectWallet, passThis } = this.props;
    return passThis.setState({ _loading: true }, () => {
      return actionSetConnectWallet({ reconnect: false, wallet: null })
        .then((_) => {
          return passThis.setState({ _loading: false });
        })
        .catch((_) => {
          return passThis.setState({ _loading: false });
        });
    });
  };

  to = (link) => {
    if (link) window.open(link, true);
  };

  componentDidMount() {
    // const { actionsetInit, passThis } = this.props;
    // console.log(this.props)
    // return actionsetInit().then(_ => {
    //   alert(1)
    //   return passThis.setState({ _loading: false })
    // }).catch(_ => {
    //   return passThis.setState({ _loading: false })
    // })
  }

  render() {
    const { _menu, _openMenu, _menuLeft } = this.state;
    const { wallet, actionseClearAlert, alert } = this.props;
    const userAddress = wallet?.address || null;
    console.log(32321, release);
    return (
      <React.Fragment>
        <Box 
        // overflow={window.location.href === "http://localhost:30000" ? "hidden" : "auto"}
        >
          <Box className={`content-hide2 ${this.state.show ? "hide-content2" : ""}`}>
            <ModalAbout />
            <Box className="close2">
              <Button onClick={this.showModal}>
                <CloseIcon sx={{ color: "white", fontSize: 40 }} />
              </Button>
            </Box>
          </Box>

          <Box className={`content-hide2 ${this.state.showRoadmap ? "hide-content2" : ""}`}>
            <ModalRoadmap />
            <Box className="close2">
              <Button onClick={this.showModalRoadmap}>
                <CloseIcon sx={{ color: "white", fontSize: 40 }} />
              </Button>
            </Box>
          </Box>
          <Box className={`content-hide2 ${this.state.showUtility ? "hide-content2" : ""}`}>
            <ModalUtility />
            <Box className="close2">
              <Button onClick={this.showModalUtility}>
                <CloseIcon sx={{ color: "white", fontSize: 40 }} />
              </Button>
            </Box>
          </Box>
          <Box className={`content-hide2 ${this.state.showTeam ? "hide-content2" : ""}`}>
            <ModalTeam />
            <Box className="close2">
              <Button onClick={this.showModalTeam}>
                <CloseIcon sx={{ color: "white", fontSize: 40 }} />
              </Button>
            </Box>
          </Box>
          <ElevationScroll {...this.props}>
            <AppBar
              position="fixed"
              color="default"
              elevation={0}
              enableColorOnDark
              sx={{ background: "transparent" }}
            >
              <Container
                disableGutters
                maxWidth="xl"
                component="header"
                height={{ sm: "10vh", md: "8vh" }}
                sx={{ px: { xs: 2, sm: 2, md: 2 } }}
              >
                <Toolbar
                  sx={{ flexWrap: "wrap" }}
                  style={{ paddingLeft: 0, paddingRight: 0, justifyContent: "space-between" }}
                >
                  <Box
                    component="div"
                    sx={{ display: { xs: "none", md: "flex", alignItems: "center" }, width: "33%" }}
                  >
                    <ButtonBase sx={{ marginRight: "1.5rem" }}>
                      <MenuIcon
                        sx={{
                          color: "#000000",
                          fontSize: 50,
                          cursor: "pointer",
                          margin: "1.5rem 1rem",
                        }}
                        onClick={() => this.handleMenuBig(true)}
                      />
                    </ButtonBase>

                    <Drawer
                      PaperProps={{ sx: { backgroundColor: "#000000" } }}
                      anchor={"left"}
                      open={this.state._openMenuBig}
                      onClose={() => this.handleMenuBig(false)}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", top: 50, width: "15rem" }}
                        role="presentation"
                        onClick={() => this.handleMenuBig(false)}
                        onKeyDown={() => this.handleMenuBig(false)}
                      >
                        <MenuIcon
                          sx={{
                            color: "#ffffff",
                            fontSize: 50,
                            cursor: "pointer",
                            margin: "1.5rem 1rem",
                          }}
                          onClick={() => this.handleMenuBig(false)}
                        />

                        <Link
                          variant="button"
                          color="#ffffff"
                          onClick={this.hideModal}
                          underline="none"
                          sx={{
                            margin: "1.5rem 1rem",
                            color: "#fffff",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          About us
                        </Link>

                        <Link
                          variant="button"
                          color="#ffffff"
                          onClick={this.hideModalRoadmap}
                          underline="none"
                          sx={{
                            margin: "1.5rem 1rem",
                            color: "#fffff",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Roadmap
                        </Link>

                        <Link
                          variant="button"
                          color="#ffffff"
                          href={"/stakev2"}
                          underline="none"
                          sx={{
                            margin: "1.5rem 1rem",
                            color: "#fffff",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Staking
                        </Link>

                        <Link
                          variant="button"
                          color="#ffffff"
                          onClick={this.hideModalTeam}
                          underline="none"
                          sx={{
                            margin: "1.5rem 1rem",
                            color: "#fffff",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Team
                        </Link>

                        <Link
                          variant="button"
                          color="#ffffff"
                          href={"/rarity"}
                          underline="none"
                          sx={{
                            margin: "1.5rem 1rem",
                            color: "#fffff",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Rarity
                        </Link>

                        {/* {_menu.map((res, i) => (
                        <Link
                          key={i}
                          variant="button"
                          color="#ffffff"
                          href={`/${res.link}`}
                          underline="none"
                          sx={{ margin: "1.5rem 1rem", color: "#fffff", fontWeight: "bold" }}
                        >
                          {res.menuName}
                        </Link>
                      ))} */}
                      </Box>
                    </Drawer>

                    {_menuLeft.map((x, i) => (
                      <ButtonBase
                        key={i}
                        color="#000000"
                        // href={`${x.link}`}
                        // underline="none"
                        sx={{ mr: 2 }}
                        onClick={() => this.to(x.link)}
                      >
                        <img src={x.icon} alt={x.menuName} style={{ width: 20 }} />
                      </ButtonBase>
                    ))}
                  </Box>

                  <Box component="div" sx={{ py: 2 }}>
                    <Link
                      variant="button"
                      color="#fff"
                      href="/"
                      underline="none"
                      // onClick={() => {
                      //   wallet.isConnect && this.disconnectWallet()
                      // }}
                    >
                      <img
                        src={require("../assets/img/web/logo_full.png")}
                        alt="Logo White"
                        style={{ width: 80 }}
                      />
                    </Link>
                  </Box>

                  <Box
                    component="div"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      width: "33%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {wallet.isConnect ? (
                      <Box className="connected" display={"flex"} alignItems="center">
                        {/* <Button
                        variant="outlined"
                        onClick={this.disconnectWallet} 
                        color="light"
                        size="small"
                      >
                        DISCONNECT
                      </Button> */}
                        <BtnDropDownWhite
                          style={{ marginRight: 3 }}
                          label={userAddress?.slice(0, 10) + "..."}
                          items={[
                            {
                              label: "Logout",
                              onClick: () => {
                                this.disconnectWallet();
                              },
                            },
                          ]}
                        />
                        {release === "true" && (
                          <>
                            <Link
                              color="#fff"
                              href="/shop"
                              underline="none"
                              sx={{ my: 1, mx: 1.5 }}
                            >
                              <img
                                src={require("../assets/img/web/ic_shop.png")}
                                alt="Twitter"
                                style={{ width: 20 }}
                              />
                            </Link>
                            <Link
                              color="#fff"
                              href="/stakev2"
                              underline="none"
                              sx={{ my: 1, mx: 1.5 }}
                            >
                              <img
                                src={require("../assets/img/web/ic_stake.png")}
                                alt="Twitter"
                                style={{ width: 20 }}
                              />
                            </Link>
                          </>
                        )}
                      </Box>
                    ) : (
                      <Box className="connected" display={"flex"} alignItems="center">
                        {release === "true" && (
                          <>
                            <Link
                              color="#fff"
                              href="/shop"
                              underline="none"
                              sx={{ my: 1, mx: 1.5 }}
                            >
                              <img
                                src={require("../assets/img/web/ic_shop.png")}
                                alt="Twitter"
                                style={{ width: 20 }}
                              />
                            </Link>
                            <Link
                              color="#fff"
                              href="/stakev2"
                              underline="none"
                              sx={{ my: 1, mx: 1.5 }}
                            >
                              <img
                                src={require("../assets/img/web/ic_stake.png")}
                                alt="Twitter"
                                style={{ width: 20 }}
                              />
                            </Link>
                          </>
                        )}
                      </Box>
                    )}
                    {/* <Link
                    color="#fff"
                    href="https://twitter.com/fuzzy_fighters?t=ATTdDI21LYMSdOzMlZE-Mg&s=08"
                    underline="none"
                    sx={{ my: 1, mx: 1.5 }}
                  >
                    <img
                      src={require("../assets/img/web/ic_twitter.png")}
                      alt="Twitter"
                      style={{ width: 20 }}
                    />
                  </Link> */}
                    <Link href="/profile" underline="none" sx={{ my: 1, mx: 1.5 }}>
                      <img
                        src={require("../assets/img/web/ic_profile.png")}
                        alt="profile"
                        style={{ width: 20 }}
                      />
                    </Link>
                  </Box>

                  {this.renderHeaderMobile()}

                  {alert.show && (
                    <Box className="network">
                      <Box className="container">
                        {alert.wording || "-"}
                        {alert.type !== "Network" && (
                          <IconButton
                            size="small"
                            onClick={() => actionseClearAlert(true)}
                            className="ic_close"
                          >
                            <CloseIcon sx={{ color: "#fff", fontSize: 20 }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  )}
                </Toolbar>
              </Container>
            </AppBar>
          </ElevationScroll>

          <Box
            className="main"
            paddingTop={"98px"}
            // overflow={window.location.href === "http://localhost:30000" ? "hidden" : "auto"}
          >
            {this.props.children}
          </Box>

          {/* <Box component="footer" className="footer">
          <Container>
            <Box sx={{ color: "#fff", fontSize: 14 }}>
              © 2022 Fuzzy Fighters. All rights reserved.
            </Box>
          </Container>
        </Box> */}
        </Box>
      </React.Fragment>
    );
  }

  renderHeaderMobile = () => {
    const { _menu, _openMenu } = this.state;
    return (
      <Box component="div" sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => this.handleMenu(true)}
          color="inherit"
        >
          <MenuIcon sx={{ color: "#00000", fontSize: 40 }} />
        </IconButton>

        <Drawer
          PaperProps={{ sx: { backgroundColor: "#000000" } }}
          anchor={"top"}
          open={_openMenu}
          onClose={() => this.handleMenu(!_openMenu)}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", top: 50 }}
            role="presentation"
            onClick={() => this.handleMenu(false)}
            onKeyDown={() => this.handleMenu(false)}
          >
            <Link
              variant="button"
              color="#ffffff"
              onClick={this.hideModal}
              underline="none"
              sx={{
                margin: "1.5rem 1rem",
                color: "#fffff",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              About us
            </Link>

            <Link
              variant="button"
              color="#ffffff"
              onClick={this.hideModalRoadmap}
              underline="none"
              sx={{ margin: "1.5rem 1rem", color: "#fffff", fontWeight: "bold", cursor: "pointer" }}
            >
              Roadmap
            </Link>

            <Link
              variant="button"
              color="#ffffff"
              href={"/stakev2"}
              underline="none"
              sx={{ margin: "1.5rem 1rem", color: "#fffff", fontWeight: "bold", cursor: "pointer" }}
            >
              Staking
            </Link>

            <Link
              variant="button"
              color="#ffffff"
              onClick={this.hideModalTeam}
              underline="none"
              sx={{ margin: "1.5rem 1rem", color: "#fffff", fontWeight: "bold", cursor: "pointer" }}
            >
              Team
            </Link>

            <Link
              variant="button"
              color="#ffffff"
              href={"/rarity"}
              underline="none"
              sx={{ margin: "1.5rem 1rem", color: "#fffff", fontWeight: "bold", cursor: "pointer" }}
            >
              Rarity
            </Link>
            {/* {_menu.map((res, i) => (
              <Link
                key={i}
                variant="button"
                color="#000"
                href={`/${res.link}`}
                underline="none"
                sx={{ my: 1, mx: 1.5, color: "#ffffff", fontWeight: "bold" }}
              >
                {res.menuName}
              </Link>
            ))} */}
          </Box>
        </Drawer>
      </Box>
    );
  };

  disconnectWallet = async () => {
    const { actionsetDisconnectWallet, passThis } = this.props;
    return passThis.setState({ _loading: true }, () => {
      return actionsetDisconnectWallet().then((_) => {
        return passThis.setState({
          _loading: false,
        });
      });
    });
  };

  toShop = () => {
    console.log(this.props);
    this.props.passThis.props.history.push("/shop");
  };
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  network: state.network,
  alert: state.alert,
});

const mapDispatchToProps = {
  actionsetDisconnectWallet: setDisconnectWallet,
  actionSetConnectWallet: setConnectWallet,
  actionseClearAlert: seClearAlert,
  actionSetInit: setInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);