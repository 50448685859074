import { Box, ButtonBase, CircularProgress, Grid, Input, Modal, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useDebugValue, useState } from "react";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Image } from "@mui/icons-material";

const BoxContainer = ({ children, sm = 4, display = "flex" }) => {
  return (
    <Grid sm={sm} justifyContent="center" display={display}>
      {children}
    </Grid>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#BEBAB1",
};

const ModalToken = ({ open, onClose, sendTransaction, loading, message }) => {
  //   const [loading, setLoading] = useState(false);
  const [qty, setQty] = useState(1);
  const price = process.env.REACT_APP_TOKEN_PRICE;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#CD7555",
            padding: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2
            id="modal-modal-title"
            variant="h6"
            style={{ fontWeight: "bold", color: "#000000", textAlign:"center" }}
          >
            How many tokens would you like to purchase?
          </h2>
          <ButtonBase onClick={onClose}>
            <CloseRoundedIcon sx={{ color: "#000000", fontSize: 36 }} />
          </ButtonBase>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingY: 2,
              paddingX: 4,
              width: "400px",
            }}
          >
            <Box 
              display="flex"
              alignItems="center"
              sx={{
                width: "150px",
              }}
            >
              <input
                type={"text"}
                value={qty}
                style={{ padding: 5, width: "100px", textAlign: "center" }}
                onChange={(e) => {
                  if (typeof Number(e.target.value) === "number" && e.target.value > 0) setQty(e.target.value);
                }}
              />
              <Box display="flex" flexDirection="column">
                <ButtonBase onClick={()=> setQty(qty+1)}>
                <ArrowDropUpRoundedIcon sx={{ fontSize: 20 }} />
                </ButtonBase>
                <ButtonBase onClick={()=> {qty === 1 ? setQty(1) : setQty(qty-1)}}>
                <ArrowDropDownRoundedIcon sx={{ fontSize: 20 }} />
                </ButtonBase>
              </Box>
            </Box>
            {loading ? (
              <>
                <CircularProgress sx={{ color: "#000000" }} />
                <Box sx={{ marginTop: 2, color: "#000000" }} width="100%">
                  <Typography textAlign="center">{message}</Typography>
                </Box>
              </>
            ) : !loading && !message ? (
              <Box
                style={{
                  backgroundColor: "#CD7555",
                  color: "#000000",
                  padding: ".5rem 1.75rem",
                  cursor: "pointer",
                }}
                onClick={() =>
                  sendTransaction(Number((price * qty).toFixed(5) * 10 ** 18).toString(16), qty)
                }
              >
                Purchase
              </Box>
            ) : (
              <>
                <Box
                  style={{
                    backgroundColor: "#CD7555",
                    color: "#000000",
                    padding: ".5rem 1.75rem",
                    cursor: "pointer",
                    }}
                  onClick={() =>
                    sendTransaction(Number((price * qty).toFixed(5) * 10 ** 18).toString(16), qty)
                  }
                >
                  Purchase
                </Box>
                <Box sx={{ marginTop: 2, color: "#000000" }} width="100%">
                  <Typography textAlign="center">{message}</Typography>
                </Box>
              </>
            )}
          </Grid>
        </Box>
        <hr style={{ width: "99%", height: ".5px", color: "#000000" }} />
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingY: 0.5,
              paddingX: 2,
              width: "350px",
            }}
          >
            <div style={{ color: "#000000", fontWeight: "semi-bold" }}>Total</div>
            <div style={{ fontWeight: "bold", color: "#000000" }}>
              {(price * qty).toFixed(5)} ETH
            </div>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalToken;