import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { GET_META, GET_TOKENS } from "../../../queries";
import CardItem from "./CardItem";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


function TokenList({ searchId, onChangePage, traits, onDetail, setTraits, clearAll }) {
  const [page, setPage] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState("rarity_score_normalized:desc");
  const { data: tokenData } = useQuery(GET_TOKENS, {
    variables: {
      first: 15,
      orderBy: orderBy.split(":")?.[0] || "rarity_score_normalized",
      id: searchId ? Number(searchId) : undefined,
      skip: searchId ? 0 : (page - 1) * 15,
      traits: traits?.length > 0 ? traits : undefined,
      orderDirection: orderBy.split(":")?.[1] || "desc",
    },
  });
  const { data: tokenDataFull } = useQuery(GET_TOKENS, {
    variables: {
      orderBy: orderBy.split(":")?.[0] || "rarity_score_normalized",
      id: searchId ? Number(searchId) : undefined,
      traits: traits?.length > 0 ? traits : undefined,
      orderDirection: orderBy.split(":")?.[1] || "desc",
    },
  });
  const { data: metadata } = useQuery(GET_META);
  console.log("metadata", metadata);
  const totalData = tokenDataFull?.tokens?.length || 0;
  const pageCount = searchId ? 1 : Math.ceil(totalData / 15);
  const searchResult = searchId ? 1 : totalData;

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  });


  useEffect(() => {
    console.log("traits", traits);
  }, [traits]);

  return (
    <Grid item xs={12} sm={12} md={9} style={{ backgroundColor: "transparent" }}>
      <Grid container justifyContent={"space-between"} columns={9} mb={4} alignItems={"center"}>
        <Typography sx={{ fontWeight: "bold", fontSize: 16, color: "#ffffff" }}>
          {searchResult} result
        </Typography>
        <Pagination
          count={pageCount}
          variant="text"
          // color="light"
          // sx={{ color: "putih" }}
          onChange={(event, page) => {
            setPage(page);
          }}
        />
        <Grid item md={3} xs={9}>
          <FormControl
            size="small"
            fullWidth
          >
        <CssTextField
              id="sortBy"
              value={orderBy}
              select
              label="Sort By"
              onChange={(e) => {
                // alert(e.target.value);
                setOrderBy(e.target.value);
              }}
              componentsProps={{ sx: { borderColor: "red", paddingY: 8.5 } }}
              sx={{ borderColor: "red", borderRadius: 0 }}
              inputProps={{ sx: { borderColor: "red" } }}
              style={{ borderColor: "red", borderRadius: 3 }}
            >
              <MenuItem value={"rarity_score_normalized:desc"}>Rarity high > low</MenuItem>
              <MenuItem value={"rarity_score_normalized:asc"}>Rarity low > high</MenuItem>
              <MenuItem value={"id:desc"}>ID high > low</MenuItem>
              <MenuItem value={"id:asc"}>ID low > high</MenuItem>

            </CssTextField>
            {/* <Select
              placeholder="Sort By"
              componentsProps={{ sx: { borderColor: "red" } }}
              sx={{ borderColor: "red", borderRadius: 0 }}
              inputProps={{ sx: { borderColor: "red" } }}
              style={{ borderColor: "red", borderRadius: 3 }}
              labelId="sortBy-label"
              id="sortBy"
              value={orderBy}
              label="Age"
              onChange={(e) => {
                // alert(e.target.value);
                setOrderBy(e.target.value);
              }}
            // MenuProps={{
            //   PaperProps: {
            //     sx: {
            //       bgcolor: "pink",
            //       "& .MuiMenuItem-root": {
            //         padding: 2,
            //       },
            //     },
            //   },
            // }}
            >
              <MenuItem value={"rarity_score_normalized:desc"}>Rarity high > low</MenuItem>
              <MenuItem value={"rarity_score_normalized:asc"}>Rarity low > high</MenuItem>
              <MenuItem value={"id:desc"}>ID high > low</MenuItem>
              <MenuItem value={"id:asc"}>ID low > high</MenuItem>
            </Select> */}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        direction={"column"}
        spacing={2}
        style={{ backgroundColor: "#BEBAB1", marginLeft: window.innerWidth <= 425 ? -8 : -16 }}
        p={2}
      // pb={2}
      >
        <Grid
          container
          columns={10}
          spacing={2}
          justifyItems={"center"}
          justifyContent={"flex-start"}
          p={2}
        >
          {traits && traits.map((item, index) => (
            <Box key={index} spacing={2} justifyItems={"center"} justifyContent={"space-around"} sx={{ border: '1px solid #CD7000', borderRadius: 2, paddingX: 1, paddingY: 0.5, marginRight: 1, marginBottom: 1, display: 'flex' }}>
              <Typography sx={{ fontSize: 14, color: "#CD7555" }}>{item.trait_type}: {item.value}</Typography>
              <CloseRoundedIcon sx={{ color: '#CD7555', fontSize: 20, cursor: 'pointer'}} onClick={()=> {setTraits(item, true); (document.getElementById(`${item.value}-${item.trait_type}`).click())}} />
            </Box>
          ))}
              {traits.length > 0 && (
              <Typography sx={{ fontSize: 14, color: "#CD7555", fontWeight: 'bold', marginTop: 0.5, cursor: 'pointer' }} 
              align={"center"}
              onClick={clearAll}>Clear all</Typography>)}
        </Grid>
        <Grid
          container
          columns={10}
          spacing={2}
          justifyItems={"center"}
          justifyContent={"flex-start"}
        >
          {/* <p>hai</p> */}
          {tokenData?.tokens.map((item, index) => (
            <Grid item xs={5} sm={12} md={2} key={item.id.toString()}>
              <CardItem
                onDetail={() => {
                  onDetail(item);
                }}
                item={item}
                soldOut={item.attributes.is_sold_out}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TokenList;
