import { ethers } from 'ethers';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { networkName } from '../../helper';
import { 
  getWeb3, 
  getListUnStake, 
  getListStake,
  connectContract, 
  isContractEnable, 
  enableContract, 
  balanceOf,
  calculateRewards,
  fuzzPerDay,
  submitStake,
  submitUnstake,
  withdrawRewards,
  getMetaData
} from './contractAction';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { LOCAL_STORAGE_USER_WALLET_ADDRESS } from '../../config';
import { SET_USER_WALLET_ADDRESS } from '../reducers/walletReducer';
import { setWithdraw } from './shopAction';


const web3Modal = new Web3Modal({
  // network: "rinkeby",
  cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "8ce97ac16c8041a0b9a3101dd303eb60"
      }
    }
  }
});

export const checkNetwork = (name, id) => async (dispatch) => {
  const networkETH = `${id}`
  const network = name || null; 
  const envNetwork = process.env.REACT_APP_NETWORK_ID;
  await dispatch({ type: 'SET_NETWORK' })
  if(networkETH === envNetwork){
    await dispatch({ type: "SET_NETWORK_SUCCESS", payload: { name: network, id: networkETH } })
    await dispatch({ type: 'SET_ALERT_UNMOUNT' });
    return Promise.resolve('SET_NETWORK_SUCCESS')
  }else{
    await dispatch({ type: "SET_NETWORK_FAILED", payload: { name: network, id:  networkETH} })
    await dispatch({ type: "SET_ALERT_SUCCESS", payload: { 
      wording: `You are on the wrong network, please connect to the ${networkName[process.env.REACT_APP_NETWORK_ID]} network`, 
      type: 'Error'
    }})
    return Promise.reject('SET_NETWORK_FAILED')
  }
}

export const setInit = () => async (dispatch) => {
  if(web3Modal.cachedProvider){
    return dispatch(setConnectWallet()).then(_ => {
      return Promise.resolve()
    })
  }else{
    return Promise.resolve()
  }
}

export const setConnectWallet = () => async dispatch => {
  await dispatch({ type: 'SET_WALLET' });
  await dispatch({ type: 'SET_LIST_UNSTAKE' });
  return connectContract(web3Modal).then(async (eth) => {
    const network = await eth.net.getId();
    const networkName = await eth.net.getNetworkType();
    const accounts = await eth.getAccounts();
    const userAddress = toChecksumAddress(accounts[0])
    return dispatch(checkNetwork(networkName, network)).then(async _ => {
      return isContractEnable(userAddress).then(async isContract => {       //checking isApprovedForAll
        await dispatch({ type: 'SET_ALERT_UNMOUNT' });
        localStorage.setItem(LOCAL_STORAGE_USER_WALLET_ADDRESS, userAddress);
        await dispatch({ type: 'SET_WALLET_SUCCESS', payload: {
          isConnect: true,
          address: userAddress
        }});
        await dispatch({ type: 'SET_CONFIG_CONTRACT_SUCCESS', payload: { 
          isContractEnable: isContract 
        }});
        if(isContract || !isContract){
          await dispatch(setConfigStaking(isContract, userAddress)).then(_ => {
            return Promise.resolve()
          }).catch(_ => {
            return Promise.reject('setConfigStakingFailed')
          })
          console.log("berhasil connect wallet")
        }else{
          await dispatch({ type: 'SET_LIST_UNSTAKE_FAILED' });
          return Promise.resolve()
        }
      });      
    }).catch(_ => {
      return Promise.reject('SET_NETWORK_FAILED')
    });
  }).catch(_ => {
    return Promise.reject('connectContractFailed')
  })
}

export const setChangeStatusContract = status => async (dispatch, getState) => {
  await dispatch({ type: 'SET_CONFIG_CONTRACT' });
  await dispatch({ type: 'SET_ALERT_UNMOUNT' });
  const userAddress = getState().wallet.address
  return enableContract(status).then(async _ => {
    await dispatch(setConfigStaking(status, userAddress));
    return Promise.resolve();
  }).catch(async err => {
    await dispatch({ type: 'SET_CONFIG_CONTRACT_FAILED' });
    await dispatch({ type: "SET_ALERT_SUCCESS", payload: { 
      wording: err?.message || 'Error', 
      type: 'Error'
    }})
    return Promise.reject()
  })   
}

export const setConfigStaking = ( isContractStatus, userAddress ) => async (dispatch, getState) => {
  await dispatch({ type: 'SET_CONFIG_CONTRACT' });
  await dispatch({ type: 'SET_ALERT_UNMOUNT' });
  return balanceOf(userAddress).then(async resBalance => {
    await dispatch({ type: 'SET_CONFIG_CONTRACT_SUCCESS', payload: { 
      isContractEnable: isContractStatus,
      balance: (resBalance / 1e18).toFixed(2),
      // fuzzPerDay: 0,
      // fuzzEarned: 0,
    }});
    await dispatch(getListItemUnstake(userAddress));
    console.log(6665, 'getListItemUnstake SUCCESS');
    await dispatch(getListItemStake(userAddress));
    console.log(6665, 'getListItemStake SUCCESS');
    return Promise.resolve('setConfigStaking');
  }).catch(async _ => {
    console.log('error setConfigStaking', _.message)
    return Promise.reject()
  })
}

export const getListItemUnstake = (userAddress) => async dispatch => {
  await dispatch({ type: 'SET_LIST_UNSTAKE' });
  return getListUnStake(userAddress).then(async resShop => {
    const nData = [];
    var getListMeta = resShop.result.length > 0 ? resShop.result.map(async (resTkn, i) => {
      await getMetaData(resTkn.token_id, i).then(resMeta => {
        const nMeta = { ...resTkn, metadata: resMeta.data }
        console.log(21312, nMeta);
        return nData.push(nMeta);
      }).catch(_ => {
        const nMeta = { ...resTkn, metadata: null }
        console.log(213121, nMeta);
        return nData.push(nMeta);
      })
    }) : [];

    return Promise.all(getListMeta).then(async () => {
      await dispatch({ type: 'SET_LIST_UNSTAKE_SUCCESS', payload: {
        data: nData
      }});
      return Promise.resolve('getListItemUnstake')
    });
  }).catch(async  _ => {
    await dispatch({ type: 'SET_LIST_UNSTAKE_FAILED' });
    return Promise.reject('SET_LIST_UNSTAKE_FAILED')  
  })
}

export const getListItemStake = (userAddress) => async dispatch => {
  await dispatch({ type: 'SET_LIST_STAKE' });
  return getListStake(userAddress).then(async resShop => {
    const newData = resShop.map(res => ({
      token_id: ethers.BigNumber.from(res).toString(),
      name: `Fuzz-${ethers.BigNumber.from(res).toString()}`
    }));
    const token_id = resShop.map(res_id => ethers.BigNumber.from(res_id).toString() );
    
    // getEarned
    const getEarned = await calculateRewards(userAddress, token_id).then(async res_earned => {
      const newRes = await res_earned.length > 0 ? res_earned.map(res_ => {
        const string = ethers.BigNumber.from(res_).toString();
        return ethers.utils.formatEther(string)
      }).reduce((a, b) => Number(a) + Number(b)) : '0';
      return newRes
    });
    
    const getFuzzPerDay = await fuzzPerDay(token_id).then(async res_fuzzDay => {
      const string = ethers.BigNumber.from(res_fuzzDay).toString();
      return ethers.utils.formatEther(string)
    });

    // getListMeta
    const nData = [];
    var getListMeta = newData.length > 0 ? newData.map(async (resTkn, i) => {
      await getMetaData(resTkn.token_id, i).then(resMeta => {
        const nMeta = { ...resTkn, metadata: resMeta.data }
        console.log(21312, nMeta);
        return nData.push(nMeta);
      }).catch(_ => {
        const nMeta = { ...resTkn, metadata: null }
        console.log(213121, nMeta);
        return nData.push(nMeta);
      })
    }) : [];

    return Promise.all(getListMeta).then(async () => {
      console.log(55542, nData, getEarned);
      await dispatch({ type: 'SET_CONFIG_EARNED_SUCCESS', payload: {
        fuzzEarned: Number(getEarned).toFixed(2)
      }});
      await dispatch({ type: 'SET_CONFIG_FUZZ_PER_DAY_SUCCESS', payload: {
        fuzzPerDay: Number(getFuzzPerDay).toFixed(2)
      }});
      await dispatch({ type: 'SET_LIST_STAKE_SUCCESS', payload: {
        data: nData
      }});
      return Promise.resolve('getListItemStake')
    });

    

    
  }).catch(async err => {
    console.log(999, err);
    await dispatch({ type: 'SET_LIST_STAKE_FAILED' });
    return Promise.reject('SET_LIST_STAKE_FAILED')  
  })
}

export const setStaking = (nfts, successCB, failedCB) => async (dispatch, getState) => {
  const userAddress = getState().wallet.address
  await dispatch({ type: 'SET_LIST_STAKE' });
  await dispatch({ type: 'SET_LIST_UNSTAKE' });
  await dispatch({ type: 'SET_ALERT_UNMOUNT' });
  return submitStake(nfts).then(async res => {
    console.log(231, res);
    await res.wait();
    setTimeout(async () => {
      await dispatch(setConfigStaking(true, userAddress))
      return successCB && successCB();
    }, 5000);
  }).catch(async err => {
    await dispatch(setConfigStaking(true, userAddress));
    await dispatch({ type: "SET_ALERT_SUCCESS", payload: { 
      wording: 'Failed: Insufficient Funds', 
      type: 'Error'
    }})
    return failedCB && failedCB()
  })
}

export const setUnStaking = (nfts, successCB, failedCB) => async (dispatch, getState) => {
  const userAddress = getState().wallet.address
  await dispatch({ type: 'SET_LIST_STAKE' });
  await dispatch({ type: 'SET_LIST_UNSTAKE' });
  await dispatch({ type: 'SET_ALERT_UNMOUNT' });
  await dispatch(setWithdraw({wallet_address: userAddress}));
  return submitUnstake(nfts).then(async res => {
    await res.wait();
    setTimeout(async () => {
      await dispatch(setConfigStaking(true, userAddress));
      return successCB && successCB();
    }, 5000);
  }).catch(async err => {
    console.log(1321, err);
    await dispatch(setConfigStaking(true, userAddress));
    await dispatch({ type: "SET_ALERT_SUCCESS", payload: { 
      wording: 'Failed: Insufficient Funds', 
      type: 'Error'
    }})
    return failedCB && failedCB()
  })
}

export const setWithdrawRewards = (successCB, failedCB) => async (dispatch, getState) => {
  const userAddress = getState().wallet.address
  const data = getState().stake.stake.data;
  const nfts = data.map(res => res.token_id);
  await dispatch({ type: 'SET_LIST_STAKE' });
  await dispatch({ type: 'SET_LIST_UNSTAKE' });
  return withdrawRewards(nfts).then(async res => {
    await dispatch(setConfigStaking(true, userAddress));
    successCB && successCB();
    return Promise.resolve(res);
  }).catch(async err => {
    await dispatch(setConfigStaking(true, userAddress));
    await dispatch({ type: "SET_ALERT_SUCCESS", payload: { 
      wording: 'Failed: Insufficient Funds', 
      type: 'Error'
    }})
    failedCB && failedCB()
    return Promise.reject(err);
  })
}

export const seClearAlert = () => async dispatch => {
  await dispatch({ type: 'SET_ALERT_UNMOUNT' });
  return Promise.resolve()
}

export const setDisconnectWallet = () => async dispatch => {
  localStorage.removeItem(LOCAL_STORAGE_USER_WALLET_ADDRESS)
  await dispatch({ type: 'LOGOUT_SUCCESS' })
  const provider = await web3Modal.connect();
  return getWeb3(provider).then(async _ => {
    web3Modal.clearCachedProvider();
    return dispatch(setInit()).then(async _ => {
      return Promise.resolve()
    }).catch(_ => {
      return Promise.reject()
    })
  })
}

export const setUserWalletAddress = (value) => async (dispatch) => {
  await dispatch({ type: SET_USER_WALLET_ADDRESS, payload: value });
  return true;
};

export const restoreUserWalletAddress = (value) => async (dispatch) => {
  const userAddress = localStorage.getItem(LOCAL_STORAGE_USER_WALLET_ADDRESS || value);
  console.log('userAddress', userAddress);
  await dispatch({ type: SET_USER_WALLET_ADDRESS, payload: userAddress });
  return true;
};

export const getBalanceOf = (userAddress) => async dispatch => {
  balanceOf(userAddress)
    .then(async resBalance => {
      const balance = (resBalance / 1e18).toFixed(2)
      const payload = { balance }
      // alert(balance)
      await dispatch({ type: 'SET_CONFIG_CONTRACT_SUCCESS', payload });
    }).catch(err => {
      console.log('error getBalanceOf', err);
    })
}