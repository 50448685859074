const initialState = {
  loading: true,
  isContractEnable: false,
  balance: 0,
  fuzzEarned: 0,
  fuzzPerDay: 0
}

const configStakingReducer = (state = initialState, action) => {
  switch (action.type){
    case 'SET_CONFIG_CONTRACT':
      return{ 
        ...state, 
        loading: true,
      }
    case 'SET_CONFIG_CONTRACT_SUCCESS':
      return{ 
        ...state, 
        loading: false,
        isContractEnable: action.payload.isContractEnable || false,
        balance: action.payload.balance || 0,
        // fuzzEarned: action.payload.fuzzEarned || 0,
        fuzzPerDay: action.payload.fuzzPerDay || 0
      }
    case 'SET_CONFIG_CONTRACT_FAILED':
      return{ 
        ...state, 
        loading: false,
        isContractEnable: false,
        balance: 0,
        fuzzEarned: 0,
        fuzzPerDay: 0
      }
    case 'SET_CONFIG_CONTRACT_UNMOUNT':
      return{ 
        ...state, 
        loading: false,
        isContractEnable: false,
        balance: 0,
        fuzzEarned: 0,
        fuzzPerDay: 0
      }
    case 'SET_CONFIG_EARNED_SUCCESS':
      return{ 
        ...state, 
        loading: false,
        fuzzEarned: action.payload.fuzzEarned || 0,
      }
    case 'SET_CONFIG_FUZZ_PER_DAY_SUCCESS':
      return{ 
        ...state, 
        loading: false,
        fuzzPerDay: action.payload.fuzzPerDay || 0,
      }
    default:
      return state
  }
}

export default configStakingReducer;