import React, { Component } from "react";
import { Main, Loading } from "../../components";
import { connect } from "react-redux";
import { setInit, setDisconnectWallet, getBalanceOf } from "../../redux/actions/setupAction";
import { Box } from "@mui/material";
import Shopping from "./partial/Shopping";
import {
  getProducts,
  postOrder,
  postOrderRaffles,
  postPaymentOrder,
  reduceBalance,
} from "../../redux/actions/shopAction";
import ModalPurchase from "./partial/ModalPurchase";
import { fuzzTokenTransfer } from "../../redux/actions/contractAction";
import MustConnectWalletDiscord from "./partial/MustConnectWalletDiscord";
import ModalToken from "./partial/ModalToken";

export class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _purchasing: false,
      _openPurchase: false,
      _purchaseMsg: "",
      item: null,
      ticket: 0,
    };
  }

  componentDidMount() {
    const { actionsetInit } = this.props;

    return actionsetInit()
      .then((_) => {
        // this.props
        //   .actionFuzzTransfer("1")
        //   .then((resP) => {
        //     console.log("suksesDong", resP);
        //   })
        //   .catch((error) => {
        //     console.log("errorDong", error);
        //   });
        return this.setState({ _loading: false });
      })
      .catch((_) => {
        return this.setState({ _loading: false });
      });
  }

  purchase = (item) => {
    this.setState({ _openPurchase: true, item, _purchaseMsg: "" });
  };

  setItem = (item) => {
    this.setState({ item });
  };

  onClosePurchase = () => {
    this.setState({ _openPurchase: false });
  };

  onTicketChange = (e) => {
    this.setState({
      ticket: e.target.value,
    });
  };

  onPurchaseOld = (cb) => {
    this.setState({ _purchasing: true });

    const isRaffle =
      this.state.item?.attributes.sale_types?.data?.filter(
        (item) => item.attributes.name === "Raffles"
      ).length > 0;

    const data = {
      discord_id: this.props.discord.user?.id,
      wallet_id: this.props.wallet.address,
      shop: this.state.item.id,
      price: this.state.item.attributes.price,
      discord_nickname:
        this.props.discord?.user?.username + "#" + this.props.discord?.user?.discriminator,
    };

    this.props
      .actionFuzzTransfer(
        isRaffle
          ? this.state.item.attributes.price * data["ticket"]
          : this.state.item.attributes.price
      )
      .then((payment_response) => {
        // console.log(this.props.discord?.user)

        let order = null;
        if (isRaffle) {
          data["ticket"] = this.state.ticket;
          order = this.props.postOrderRaffles(data);
        } else {
          order = this.props.postOrder(data);
        }
        order.then((res) => {
          cb();
          this.props.actionGetShop({
            "pagination[page]": 1,
            discord_id: this.props.discord.user?.id,
          });
          if (!res.error) {
            this.setState({
              item: this.props.shop.products?.find((item) => item.id === this.state.item.id),
            });
          }

          // save payment order
          this.props
            .postPaymentOrder({
              id: res.response.data.id,
              is_raffle: isRaffle,
              payment_response,
            })
            .then(async (resPayment) => {
              console.log("resPayment", resPayment);
              await payment_response?.wait?.();
              // get balance lagi
              setTimeout(() => {
                this.props.actionGetBalance(this.props.wallet.address);
              }, 3000);

              this.setState({
                _purchasing: false,
                _purchaseMsg: res.error ? "Purchase Failed" : "Purchase Successfully",
              });
            });
        });
      })
      .catch((error) => {
        this.setState({
          _purchasing: false,
          _purchaseMsg: "Purchase Failed",
        });
      });
  };

  onPurchase = async (cb) => {
    this.setState({
      _purchasing: true,
      _purchaseMsg: "Transaction in progress, please do not close or reload this page",
    });

    const isRaffle =
      this.state.item?.attributes.sale_types?.data?.filter(
        (item) => item.attributes.name === "Raffles"
      ).length > 0;

    const data = {
      discord_id: this.props.discord.user?.id,
      wallet_id: this.props.wallet.address || localStorage.getItem("LOCAL_STORAGE_USER_WALLET_ADDRESS"),
      shop: this.state.item.id,
      price: this.state.item.attributes.price,
      discord_nickname:
        this.props.discord?.user?.username + "#" + this.props.discord?.user?.discriminator,
    };

    try {
      let order = null;
      if (isRaffle) {
        data["ticket"] = this.state.ticket;
        order = this.props.postOrderRaffles(data);
      } else {
        order = this.props.postOrder(data);
      }
      const price = isRaffle
        ? this.state.item.attributes.price * data["ticket"]
        : this.state.item.attributes.price;
      order.then((res) => {
        if (res.response && !res.error) {
          this.props
            .reduceBalance({price, wallet_address: data.wallet_id})
            .then((payment_response) => {
              cb();
              this.props.actionGetShop({
                "pagination[page]": 1,
                discord_id: this.props.discord.user?.id,
              });
              // if (!res.error) {  //on comment karna udah if cond di line 175
                this.setState({
                  item: this.props.shop.products?.find((item) => item.id === this.state.item.id),
                });
              // }

              // save payment order
              this.props
                .postPaymentOrder({
                  id: res.response.data.id,
                  is_raffle: isRaffle,
                  payment_response: {status: "success", from: data.wallet_id, price},
                  // shop: data.shop
                })
                .then(async (resPayment) => {
                  console.log("resPayment", resPayment);
                  await payment_response?.wait?.();
                  // get balance lagi
                  // setTimeout(() => {
                  //   this.props.actionGetBalance(this.props.wallet.address);
                  // }, 15000);

                  this.setState({
                    _purchasing: false,
                    // _purchaseMsg: res.error ? "Purchase Failed" : "Purchase Successfully",
                    _purchaseMsg: resPayment.error ? "Purchase Failed" : "Purchase Succeeded",
                  });
                });
            })
            .catch((error) => {
              this.setState({
                _purchasing: false,
                _purchaseMsg: "Purchase Failed",
              });
            });
        } else {
          this.setState({
            _purchasing: false,
            _purchaseMsg: "Purchase Failed",
          });
        }
      });
      // order.then((res) => {
      //   if (res.response && !res.error) {
      //     this.props
      //       .actionFuzzTransfer(price)
      //       .then((payment_response) => {
      //         cb();
      //         this.props.actionGetShop({
      //           "pagination[page]": 1,
      //           discord_id: this.props.discord.user?.id,
      //         });
      //         // if (!res.error) {  //on comment karna udah if cond di line 175
      //           this.setState({
      //             item: this.props.shop.products?.find((item) => item.id === this.state.item.id),
      //           });
      //         // }

      //         // save payment order
      //         this.props
      //           .postPaymentOrder({
      //             id: res.response.data.id,
      //             is_raffle: isRaffle,
      //             payment_response,
      //             // shop: data.shop
      //           })
      //           .then(async (resPayment) => {
      //             console.log("resPayment", resPayment);
      //             await payment_response?.wait?.();
      //             // get balance lagi
      //             setTimeout(() => {
      //               this.props.actionGetBalance(this.props.wallet.address);
      //             }, 15000);

      //             this.setState({
      //               _purchasing: false,
      //               // _purchaseMsg: res.error ? "Purchase Failed" : "Purchase Successfully",
      //               _purchaseMsg: resPayment.error ? "Purchase Failed" : "Purchase Succeeded",
      //             });
      //           });
      //       })
      //       .catch((error) => {
      //         this.setState({
      //           _purchasing: false,
      //           _purchaseMsg: "Purchase Failed",
      //         });
      //       });
      //   } else {
      //     this.setState({
      //       _purchasing: false,
      //       _purchaseMsg: "Purchase Failed",
      //     });
      //   }
      // });
    } catch (error) { }
  };

  render() {
    return (
      <Main passThis={this} passData={this.state}>
        <Box className="home">
          <MustConnectWalletDiscord
            show={true}
            passThis={this}
            passProps={this.props}
            passData={this.state}
          />
        </Box>
        <Box className="home">
          <Shopping show={true} passThis={this} passProps={this.props} passData={this.state} openPurchaseToken={this._openPurchaseToken} />
        </Box>
        <ModalPurchase
          purchasing={this.state._purchasing}
          item={this.state.item}
          open={this.state._openPurchase}
          onClose={this.onClosePurchase}
          onPurchase={this.onPurchase}
          purchaseMsg={this.state._purchaseMsg}
          onTicketChange={this.onTicketChange}
        />
      </Main>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  discord: state.discord,
  shop: state.shop,
});

const mapDispatchToProps = {
  getProducts: getProducts,
  postOrder: postOrder,
  postOrderRaffles: postOrderRaffles,
  postPaymentOrder: postPaymentOrder,
  actionsetInit: setInit,
  actionGetShop: getProducts,
  actionFuzzTransfer: fuzzTokenTransfer,
  actionGetBalance: getBalanceOf,
  reduceBalance: reduceBalance
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);