import { getStrapi, postStrapi } from "../../helper/axios";
import {
  SET_ITEM_TYPES,
  SET_TYPES,
} from "../reducers/shopReducer";
import {
  SET_DISCORD_ACCESS_TOKEN,
  SET_DISCORD_CODE,
  SET_DISCORD_USER,
} from "../reducers/discordReducer";
import { DISCORD_CLIENT_ID, DISCORD_CLIENT_SECRET, DISCORD_REDIRECT_URI, LOCAL_STORAGE_DISCORD_USER } from "../../config";

export const getToken =
  (code ) =>
  async (dispatch) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("client_id", DISCORD_CLIENT_ID);
      urlencoded.append("client_secret", DISCORD_CLIENT_SECRET);
      urlencoded.append("grant_type", "authorization_code");
      urlencoded.append("code", code);
      urlencoded.append("redirect_uri", "http://localhost:3000/shop");
      urlencoded.append("scope", "identify");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch("https://discord.com/api/oauth2/token", requestOptions)
        .then((response) => response.json())
        .then(async (result) => {
          console.log(result);
          dispatch({ type: SET_DISCORD_ACCESS_TOKEN, payload: result.access_token });
          const user = await getMe(result.access_token);
          if (user.error === false) {
            console.log("user", user);
            localStorage.setItem(LOCAL_STORAGE_DISCORD_USER, JSON.stringify(user.data));
            dispatch({ type: SET_DISCORD_USER, payload: user.data });
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("error getToken", error?.message);
      return false;
    }
  };

export const getMe = async (accessToken, params = {}) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + accessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch("https://discordapp.com/api/users/@me", requestOptions);
    const result = await response.json();
    return { error: false, data: result };
  } catch (error) {
    console.log("error getMe", error?.message);
    return { error: true, data: error };
  }
};

export const getMeFromCms =
  (code) =>
  async (dispatch) => {
    try {
      const response = await getStrapi("discord", { code, redirect_uri: DISCORD_REDIRECT_URI });
      console.log("response", response);
      localStorage.setItem(LOCAL_STORAGE_DISCORD_USER, JSON.stringify(response.data));
      dispatch({ type: SET_DISCORD_USER, payload: response.data });
      return { error: false, data: response.data };
    } catch (error) {
      console.log("error getMeFromCms", error?.message);
      return { error: true, data: error };
    }
  };

export const setDiscordCode = (value) => async (dispatch) => {
  await dispatch({ type: SET_DISCORD_CODE, payload: value });
  return true;
};

export const restoreDiscordUser =
  (value = null) =>
  async (dispatch) => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISCORD_USER) || null);
    await dispatch({ type: SET_DISCORD_USER, payload: value || user });
    return true;
  };
