import React from "react";
import "./BtnDropDownWhite.css";

const BtnDropDownWhite = ({
  borderColor = "black",
  label,
  color = "black",
  items = [],
  style = {},
}) => {
  return (
    <label className="dropdown" style={{ ...style }}>
      <div className="dd-button" style={{ borderColor, color }}>
        {label}
      </div>

      <input type="checkbox" className="dd-input" id="test" />

      <ul className="dd-menu">
        {items.map((item) => (
          <li key={item?.label}>
            <a
              onClick={(e) => {
                e.preventDefault();
                item.onClick();
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </label>
  );
};

export default BtnDropDownWhite;
