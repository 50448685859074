import { statusName, statusNameMint, networkName } from "./status";

export const formatURL = (url) => {
  const prefixUrl = "https://fuzzyfighters.mypinata.cloud/ipfs/";
  // const prefixUrl = "https://ipfs.io/ipfs/";
  if (url.substring(0, 7) === "ipfs://") return prefixUrl + url.substring(7);
  else return url;
};

export function makeId(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export { statusName, statusNameMint, networkName };
