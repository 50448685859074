import React, { Component } from "react";
import * as Containers from "../container";
import { GeneralRoute } from "./route";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class AppRoute extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <GeneralRoute
            exact
            path="/"
            component={Containers.Home}
            private={false}
            passData={this.state}
            passThis={this}
          />
          <GeneralRoute
            exact
            path="/shop"
            component={Containers.Shop}
            private={false}
            passData={this.state}
            passThis={this}
          />
          <GeneralRoute
            exact
            path="/rarity"
            component={Containers.Rarity}
            private={false}
            passData={this.state}
            passThis={this}
          />
          <GeneralRoute
            exact
            path="/stakev1"
            component={Containers.Stake}
            private={false}
            passData={this.state}
            passThis={this}
          />
          <GeneralRoute
            exact
            path="/stakev2"
            component={Containers.Stake}
            private={false}
            passData={this.state}
            passThis={this}
          />
           <GeneralRoute
            exact
            path="/profile"
            component={Containers.Profile}
            private={false}
            passData={this.state}
            passThis={this}
          />
          
          <Route component={Containers.NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default AppRoute;