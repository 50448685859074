import React, { Component } from "react";
import { Main, Loading } from "../../components";
import { connect } from "react-redux";
import { setInit, setDisconnectWallet, getBalanceOf } from "../../redux/actions/setupAction";
import { Box } from "@mui/material";
import RarityComp from "./partial/RarityComp";
import {
  getProducts,
  postOrder,
  postOrderRaffles,
  postPaymentOrder,
} from "../../redux/actions/shopAction";
import ModalPurchase from "./partial/ModalPurchase";
import { fuzzTokenTransfer } from "../../redux/actions/contractAction";
import MustConnectWalletDiscord from "./partial/MustConnectWalletDiscord";

export class Rarity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _purchasing: false,
      _openPurchase: false,
      _purchaseMsg: "",
      item: null,
      ticket: 0,
    };
  }

  componentDidMount() {
    if (window.location.search.includes("nft") && window.location.search.includes("_token")) {
      const id = window.location.search.replace("?nft=", "");
      // console.log("id", id);
      this.setState({
        _openPurchase: true,
        item: { id },
      });
    }
  }

  purchase = (item) => {
    // alert(item);
    console.log("item", item);
    this.setState({ _openPurchase: true, item, _purchaseMsg: "" });
  };

  setItem = (item) => {
    this.setState({ item });
  };

  onClosePurchase = () => {
    this.setState({ _openPurchase: false });
  };

  onTicketChange = (e) => {
    this.setState({
      ticket: e.target.value,
    });
  };

  onPurchase = (cb) => {};

  render() {
    return (
      <Main passThis={this} passData={this.state}>
        {/* <Box className="home">
          <MustConnectWalletDiscord
            show={true}
            passThis={this}
            passProps={this.props}
            passData={this.state}
          />
        </Box> */}
        <Box className="home">
          <RarityComp show={true} passThis={this} passProps={this.props} passData={this.state} />
        </Box>

        <ModalPurchase
          purchasing={this.state._purchasing}
          item={this.state.item}
          open={this.state._openPurchase}
          onClose={this.onClosePurchase}
          onPurchase={this.onPurchase}
          purchaseMsg={this.state._purchaseMsg}
          onTicketChange={this.onTicketChange}
        />
      </Main>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  discord: state.discord,
  shop: state.shop,
});

const mapDispatchToProps = {
  getProducts: getProducts,
  postOrder: postOrder,
  postOrderRaffles: postOrderRaffles,
  postPaymentOrder: postPaymentOrder,
  actionsetInit: setInit,
  actionGetShop: getProducts,
  actionFuzzTransfer: fuzzTokenTransfer,
  actionGetBalance: getBalanceOf,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rarity);
