import { Box, Button, Container, Divider, Link, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Main } from "../../../components";

const ModalRoadmap = () => {
  return (
    <Box component="section" className="roadmap2" id="roadmap__">
      <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 1, md: 1 } }}>
        <Typography component="div" className="title" style={{ mt: 0, pt: 0 }}>
          Roadmap
        </Typography>

        <Box className="content">
          <Box className="imgRoadmap">
            <img src={require("../../../assets/img/web/introduce/ill_roadmap2.png")} alt="" />
          </Box>
        </Box>
        <Box className="div-phase1">
          <div
            style={{
              color: "#ffff",
              position: "absolute",
              top: "30%",
              left: "25%",
              width: "2px",
              height: "40%",
            }}
          >
            <Divider orientation="vertical" style={{ color: "#fff", backgroundColor: "#fff" }} />
          </div>
        </Box>
        <Box className="phase1">
          {/* <div
            style={{
              color: "#ffff",
              position: "absolute",
              top: "30%",
              left: "26%",
              width: "30%",
            }}
          > */}
            <p className="title-text">Phase I</p>
            <p></p>
            <p className="text1" >Season 1: Fuzzy Fighters</p>
            <p></p>
            <p className="text2">Alpha Hub + Tools </p>
            <p></p>
            <p className="text3" >DAO, Charity & FuzzShop 1.0</p>

          {/* </div> */}
        </Box>
        <Box className="div-phase2">
          <div
            style={{
              color: "#ffff",
              position: "absolute",
              top: "86%",
              left: "48%",
              width: "2px",
              height: "25%",
            }}
          >
            <Divider orientation="vertical" style={{ color: "#fff", backgroundColor: "#fff" }} />
          </div>
        </Box>
        <Box className="phase2">
          {/* <div
            style={{
              color: "#ffff",
              position: "absolute",
              top: "87%",
              left: "49%",
              width: "30%",
            }}
          > */}
            <p className="title-text" style={{ fontSize: "12px", fontWeight: "bold" }}>Phase II</p>
            <p></p>
            <p className="text1" >Season 2: AstroFuzz</p>
            <p></p>
            <p className="text2" >MoonPack</p>
            <p></p>
            <p className="text3" >Fuzz Shop 2.0</p>
          {/* </div> */}
        </Box>
        <Box className="div-phase3">
          <div
            style={{
              color: "#ffff",
              position: "absolute",
              top: "30%",
              right: "25%",
              width: "2px",
              height: "38%",
            }}
          >
            <Divider orientation="vertical" style={{ color: "#fff", backgroundColor: "#fff" }} />
          </div>
        </Box>
        <Box className="phase3">
          {/* <div
            style={{
              color: "#ffff",
              position: "absolute",
              top: "30%",
              right: "14%",
              width: "10%",
            }}
          > */}
            <p className="title-text">Phase III</p>
            <p></p>
            <p className="text1" >
            To be revealed 
            </p>
          {/* </div> */}
        </Box>
      </Container>
    </Box>
  );
};

export default ModalRoadmap;
