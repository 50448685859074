import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const [targetDateFix, setTargetDateFix] = useState(targetDate);
  const countDownDate = new Date(targetDateFix).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  useEffect(() => {
    setTargetDateFix(targetDate);
  }, [targetDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  let daysString = days < 10 ? `0${days}` : days;
  if (days < 0) daysString = "00";

  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let hoursString = hours < 10 ? `0${hours}` : hours;
  if (hours < 0) hoursString = "00";

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  let minutesString = minutes < 10 ? `0${minutes}` : minutes;
  if (minutes < 0) minutesString = "00";

  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  let secondsString = seconds < 10 ? `0${seconds}` : seconds;
  if (seconds < 0) secondsString = "00";

  let stopped = false;
  if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
    stopped = true;
  }

  return {
    days,
    hours,
    minutes,
    seconds,
    secondsString,
    minutesString,
    hoursString,
    daysString,
    stopped,
  };
};

export { useCountdown };
