import React, { Component } from 'react';
import { Box, Grid, Container, Typography, Link } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import clan from '../../../data/clan';

export default class FuzzClan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _clan: clan
    }
  }
  
  render() {
    const { _clan } = this.state;
    return (
      <Box component="section" className='fuzzClan' id="fuzzClan__" sx={{ py: '4%' }}>
        <Container maxWidth="xl"  sx={{ px: {xs: 2,  sm: 2, md: 2 } }}>
          <Typography component="div" className="title" style={{ mt: 0, pt: 0 }}>
            The Fuzz Clan
          </Typography>

          <Grid container rowSpacing={6} columnSpacing={{xs: 5, md: 10}} columns={16}>
            {_clan.map((res, i) => (
              <Grid item xs={8} sm={8} md={4} key={i}>
                <Box component="div" align="center" sx={{ color: '#414042', fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>
                  {res.name}
                </Box>
                <Box component="div" align="center" sx={{ mt: 1, color: '#414042', fontSize: 14, textAlign: 'center' }}>
                  {res.role}
                </Box>
                <Box sx={{ mt: 1, mb: 2, color: '#414042', fontSize: 14, textAlign: 'center', minHeight: res.twitter ? 0 : 28}}>
                  {res.twitter  ? 
                    <Link 
                      color="#414042" 
                      href={res.twitter} 
                      underline="none" 
                      target="_blank"
                    >
                      <TwitterIcon />
                    </Link>
                  : ''}
                </Box>
                <Box component="div" sx={{ border: '10px solid #fff' }}>
                  <img src={require(`../../../assets/img/web/new_clan/${res.character}`)} alt="Character Fuzz Clan" />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }
}
