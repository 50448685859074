import { Button } from "@mui/material";
import React from "react";

const BtnIjo = ({ onPress, label, outlined = false, endIcon }) => {
  return (
    <Button
      onClick={onPress}
      sx={{
        backgroundColor: outlined === false ? "#CD7555" : undefined,
        borderColor: outlined ? "#CD7555" : undefined,
        color: outlined ? "#CD7555" : "#000000",
        borderRadius: 0,
        paddingX: 2,
        textTransform: "unset",
        fontWeight: "bold",
      }}
      variant={outlined ? "outlined" : undefined}
      endIcon={endIcon}
    >
      {label}
    </Button>
  );
};

export default BtnIjo;
