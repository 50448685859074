import React from "react";
import useConfigStaking from "./useConfigStaking";

const useBalance = () => {
  const configStaking = useConfigStaking();

  //   return 1000;
  return configStaking?.balance || 0;
};

export default useBalance;
