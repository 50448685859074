import { Box, Button, Container, Link, Modal } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ModalUtility from "./ModalUtility";
import StakingFuzz from "./StakingFuzz";

const Utility2 = () => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    // <Box sx={{width: {xs: "100%", md: "50%"}}} borderRight="solid 2px" borderColor="#000000" backgroundColor="red">
    <Box
      className="container-utility bg-utility"
      sx={{ width: { xs: "100%", md: "50%" } }}
      borderRight="solid 2px"
      borderColor="#000000"
    >
      <Box
        width="100%"
        height="100%"
        sx={{ display: "flex" }}
        justifyContent="center"
        alignItems="center"
        position="relative"
        zIndex={2}
      >
        <img
          style={{ height: "auto", width: "55%" }}
          src={require("../../../assets/img/web/introduce/ill_utility_new.png")}
          alt="illustration"
        />
        <Link href="/stakev2">
          <Box class="overlay-utility" style={{ cursor: "pointer" }}>
            <div class="text" style={{ fontWeight: "bold", zIndex: 2 }}>
              Staking
            </div>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Utility2;
