import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <CircularProgress sx={{ color: '#ffffff' }} />
      </div>
    )
  }
}