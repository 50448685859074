import React, { Component } from "react";
import {
  Box,
  Container,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  background: "transparent",
  borderBottom: "1px solid #fff",
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem", color: "#fff" }} />}
    {...props}
  />
))(({ theme }) => ({
  color: "#fff",
  padding: 0,
  fontSize: 30,
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: "#fff",
}));

export default class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _faq: [
        // {
        //   title: "What is Fuzzy Fighters?",
        //   desc: "We are an NFT collection on the Ethereum blockchain providing high utility for holders and the wider community. <br /><br /> Phase 1 will be a genesis collection of 1000 Fuzzy Fighters, NFTs which you may stake to receive $FUZZ token. Holders will also have voting rights in the project's DAO. <br /><br /> Phase 2 will be our main collection of Mutated Fuzzy Fighters (size TBA). Here we will launch our Creator Space which is a community-driven launchpad for talented artists to launch their own NFT collection. We will have two tracks: Established Artists & New Artists. In Phase 2, $FUZZ token will have a lot of utility including burning for presale access and minting at a discounted price..",
        // },
        // {
        //   title: "How do I purchase a Fuzzy Fighter?",
        //   desc: "For the initial launch, you will be able to mint Fuzzy Fighters here on our website. Our secondary market will be on Opensea.io",
        // },
        // { title: "Wen Mint?", desc: "Presale 15 April 10am EST. Public Sale 15 April 10pm EST" },
        // { title: "What is the mint price?", desc: "0.088 ETH" },
        {
          title: "What is Fuzzy Fighters?",
          desc: `We are an NFT collection on the Ethereum blockchain focused on providing utility for holders and the wider community. <br /><br />
Phase 1 is a genesis collection of 1000 unique NFTs personifying adorable foxes and pandas on the Ethereum blockchain. Featuring original artwork from
an independent female artist (“Tiny Cozy Cub”), this collection aims to deliver a preview of the project’s utility, community, and sustainability — principles
of the Fuzzy Fighter ecosystem. <br /><br />
We aim to be a central resource for artists of all mediums entering the metaverse through our launchpad program in Phase II. Staking of a Genesis Fuzzy
Fighter will enable holders to earn $FUZZ and unlock benefits to our full suite of utility.`,
        },
        {
          title: "How do I purchase a Fuzzy Fighter?",
          desc: `We are a post-mint project so you can purchase our collection from the following marketplaces: <br /><br />
<a target="_blank" style="text-decoration: underline;" href="https://opensea.io/collection/fuzzy-fighters-genesis">https://opensea.io/collection/fuzzy-fighters-genesis</a> <br />
<a target="_blank" style="text-decoration: underline;" href="https://looksrare.org/collections/0xAc0e8B91745aC44A6b19e4039CD6F297EF7e2744">https://looksrare.org/collections/0xAc0e8B91745aC44A6b19e4039CD6F297EF7e2744</a> <br />
<a target="_blank" style="text-decoration: underline;" href="https://x2y2.io/collection/fuzzy-fighters-genesis">https://x2y2.io/collection/fuzzy-fighters-genesis</a>`,
        },
        {
          title: "How do I join the community?",
          desc: `You can join our discord here <a target="_blank" style="text-decoration: underline;" href="http://discord.gg/frDMMUxhCE">http://discord.gg/frDMMUxhCE</a> <br /><br />
It is open to public with limited access. Enjoy the full benefits such as free tools, Alpha, constant Whitelist giveaways, discord bots, crypto news, mint
calendar and more by becoming a holder.`,
        },
      ],
    };
  }

  render() {
    const { _faq } = this.state;
    return (
      <Box component="section" className="faq" id="faq__" sx={{ pt: "6%" }}>
        <Container maxWidth="lg">
          <Box className="ttl">Frequently Asked Questions</Box>
          <Box className="faqIns">
            {_faq.map((res, i) => (
              <Accordion key={i}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{res.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography dangerouslySetInnerHTML={{ __html: res.desc }} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </Box>
    );
  }
}
