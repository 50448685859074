import { Box, Button, Container, Grid, Link, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Main } from "../../../components";
import TwitterIcon from "@mui/icons-material/Twitter";
import clan from "../../../data/clan2";

const ModalTeam = () => {
  const [open, setOpen] = useState(true);

  const _clan = clan;

  return (
    <Box component="section" className="fuzzClan2" id="fuzzClan__" >
      <Container maxWidth="xl" sx={{ px: { xs: 2, sm: 2, md: 36 } }}>
        <Typography component="div" className="title" style={{ mt: 0, pt: 0 }}>
          Team
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, md: 2 }} columns={16}>
          {_clan.map((res, i) => (
            <Grid item xs={4} sm={4} md={3} key={i}>
              <Box component="div" className="img">
                <img
                  src={require(`../../../assets/img/web/clan_astro/${res.character}`)}
                  alt="Character Fuzz Clan"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    mt: 1,
                    mb: 1,
                    color: "#000",
                    fontSize: 10,
                    textAlign: "center",
                    fontWeight: "bold",
                    minHeight: res.twitter ? 0 : 10,
                    paddingLeft: "5px",
                  }}
                >
                  {res.twitter ? (
                    <Link color="#000" href={res.twitter} underline="none" target="_blank">
                      <TwitterIcon sx={{fontSize: 16, paddingRight:"5px"}} />
                    </Link>
                  ) : (
                    ""
                  )}
                </Box>{" "}
                <Box
                  sx={{
                    mt: 1,
                    mb: 1,
                    color: "#000",
                    fontSize: 11,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {res.name}
                </Box>
              </Box>
             
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ModalTeam;