import React from "react";
import "./../../assets/styles/button-19.css";

const BtnOrangeShadow = ({ children, onClick, style={} }) => {
  return (
    <button onClick={onClick} className="button-19" style={style}>
      {children}
    </button>
  );
};

export default BtnOrangeShadow;
