import { LanguageOutlined, Twitter, Web } from "@mui/icons-material";
import { Avatar, Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Badge from "../../../components/Badge";
import { IMG_DISCORD } from "../../../data/images";
import { strapiImg } from "../../../helper/strapi";
import { useCountdown } from "../../../hooks/useCountdown";
import ReactImageFallback from "react-image-fallback";
import SocialMediaIcon from "./SocialMediaIcon";
import BadgeTypes from "./BadgeTypes";
import Countdown from "./Countdown";
import { formatURL } from "../../../helper";

const CardItem = ({ item, soldOut = false, onDetail }) => {
  return (
    <Box position={"relative"}>
      <Box>
        <Box position={"relative"}>
          {JSON.parse(item?.id) == 183 || JSON.parse(item?.id) == 875 ? (
            <video autoPlay loop width="100%" controls>
              <source src={formatURL(item.image)} type="video/mp4" />
            </video>
          ) : (
            <ReactImageFallback
              src={formatURL(item.image)}
              fallbackImage={"https://picsum.photos/300?random=" + item.id}
              initialImage="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
              alt={item.name}
              className="my-image"
            />
          )}{" "}
        </Box>
        <Box style={{ backgroundColor: "#CD7555", minHeight: 75, position: "relative" }} p={1}>
          <Grid container justifyContent={"space-between"} mb={1}>
            <Grid>
              <a href="#" onClick={() => onDetail(item)}>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#000000",
                    fontSize: "14px",
                    lineHeight: `1.2em`,
                  }}
                >
                  {item.name}
                </div>
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            style={{ position: "absolute", left: 0, right: 0, bottom: 0, padding: 10 }}
          >
            <Grid>
              <div style={{ color: "#000000", fontSize: "11px" }}>Rarity Rank</div>
            </Grid>
            <Grid>
              <div style={{ color: "#000000", fontSize: "11px" }}>#{item.rank_normalized}</div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CardItem;