import { LanguageOutlined, Twitter, Web } from "@mui/icons-material";
import { Avatar, Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Badge from "../../../components/Badge";
import { IMG_DISCORD } from "../../../data/images";
import { strapiImg } from "../../../helper/strapi";
import { useCountdown } from "../../../hooks/useCountdown";
import ReactImageFallback from "react-image-fallback";
import SocialMediaIcon from "./SocialMediaIcon";
import BadgeTypes from "./BadgeTypes";
import Countdown from "./Countdown";

const CardItem = ({ item, soldOut = false, onPurchase, discord_id="", actionGetProducts, isClosed }) => {
  const isRaffle =
    item?.attributes.sale_types?.data?.filter((item) => item.attributes.name === "Raffles").length >
    0;
  const isFCFS =
    item?.attributes.sale_types?.data?.filter((item) => item.attributes.name === "FCFS").length > 0;

  const title = item?.attributes?.title

  return (
    <Box position={"relative"}>
      <Box>
        <Box position={"relative"}>
          <ReactImageFallback
            // src={item.attributes.image_url || strapiImg(item.attributes.image.data.attributes.url)}
            src={item.attributes.image_url}
            fallbackImage={"https://picsum.photos/300?random=" + item.id}
            initialImage="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
            alt={item.attributes.title}
            className="my-image"
          />
          {!soldOut && isRaffle ? (
            <Box
              position={"absolute"}
              top={0}
              style={{ width: "100%" }}
              justifyContent={"center"}
              display="flex"
            >
              <Countdown discord_id={discord_id} isClosed={isClosed} actionGetProducts={actionGetProducts} date={item.attributes.end_date} title={title} />
            </Box>
          ) : null}
          <Box
            position={"absolute"}
            bottom={0}
            style={{ width: "100%" }}
            py={5}
            className="img-title-shadow"
            justifyContent={"flex-end"}
            alignItems="flex-end"
          >
            <Typography
              variant="h6"
              fontWeight={"bold"}
              color={"white"}
              position={"absolute"}
              bottom={5}
              left={15}
            >
              {item.attributes.title}
            </Typography>
          </Box>
        </Box>
        <Box style={{ backgroundColor: "#BEBAB1" }} p={2}>
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <Typography fontWeight="bold">{item.attributes.price} $FUZZ</Typography>
              <Typography fontSize={10} mb={2}>
                Supply {item.attributes.supply}
              </Typography>
            </Grid>
            <Grid>
              <SocialMediaIcon item={item} />
            </Grid>
          </Grid>
          <Divider />
          <Typography my={1} fontSize={10}>
            {item.attributes.short_description}
          </Typography>

          <BadgeTypes
            types={{
              ...item.attributes?.item_types,
              data: [...item.attributes?.item_types.data, ...item.attributes?.sale_types.data],
            }}
          />
          {/* <BadgeTypes types={item.attributes?.sale_types} /> */}

          <div
            style={{
              justifyContent: "center",
              display: "flex",
              paddingTop: 20,
              paddingBottom: 5,
            }}
          >
            <Button
              onClick={() => onPurchase(item)}
              sx={{
                backgroundColor: "#CD7555",
                color: "#000000",
                borderRadius: 0,
                paddingX: 2,
                textTransform: "unset",
                fontWeight: "bold",
              }}
            >
              Purchase
            </Button>
          </div>
        </Box>
      </Box>
      {soldOut || item.past || item?.orders?.length > 0 ? (
        <Box
          top={0}
          bgcolor={"rgb(50 49 47 / 80%)"}
          position={"absolute"}
          width={"100%"}
          height={"100%"}
        >
          <Box
            position={"absolute"}
            top={10}
            style={{ width: "100%" }}
            justifyContent={"center"}
            display="flex"
          >
            {/* {isRaffle ? ( */}
            <Box bgcolor={"#FFD62C"} sx={{ p: ".5rem 1rem" }}>
              <h6
              style={{
                color: "#000000",
                textAlign: "center",
                margin: 0,
                padding: 0,
                fontWeight: "bold"
              }}
                // color={"#000000"}
                // textAlign="center"
                // mb={0}
                // p={0}
                // fontWeight={"bold"}
              >
                {item?.youWon
                  ? "YOU WON" :
                  item?.orders?.length > 0
                    ? "PURCHASED"
                    : item?.youLost && isRaffle
                      ? "YOU LOST"
                      : "SOLD OUT"}
              </h6>
            </Box>
            {/* ) : (
              <Box width={"40%"}>
                <Typography
                  variant="h6"
                  color={"rgba(255,255,255,0.5)"}
                  textAlign="center"
                  mb={0}
                  p={0}
                  fontWeight={"bold"}
                >
                  SOLD OUT
                </Typography>
              </Box>
            )} */}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default CardItem;