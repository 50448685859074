import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { Loading } from "../../../components";
import BtnIjo from "../../../components/buttons/BtnIjo";
import { formatURL } from "../../../helper";
import { strapiImg } from "../../../helper/strapi";
import useBalance from "../../../hooks/useBalance";
import useConfigStaking from "../../../hooks/useConfigStaking";
import { GET_TOKEN } from "../../../queries";
import BadgeTypes from "./BadgeTypes";
import Countdown from "./Countdown";
import SocialMediaIcon from "./SocialMediaIcon";

const BoxContainer = ({ children, sm = 4, display = "flex" }) => {
  return (
    <Grid sm={sm} justifyContent="center" display={display}>
      {children}
    </Grid>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#BEBAB1",
  padding: 0,
};

function ModalPurchase({
  open,
  onClose,
  item,
  onPurchase,
  purchasing,
  purchaseMsg,
  onTicketChange,
}) {
  const [isPurchase, setIsPurchase] = React.useState(false);
  const purchase = () => setIsPurchase(true);

  const { data } = useQuery(GET_TOKEN, {
    variables: {
      id: typeof item?.id === "number" ? Number(item?.id) : Number(item?.id.split("&")[0]),
    },
  });

  console.log("dataToken", data);
  // console.log("item", item?.id);
  const token = data?.token;

  if (window.innerWidth <= 425) {
    style.width = window.innerWidth * 0.9;
  }

  if (!token) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
    >
      <Box sx={{ ...style, sm: { width: 200 }, lg: { width: 1000 } }}>
        <Grid container columns={12} columnSpacing={2} rowSpacing={2}>
          <Grid item sm={6}>
            {JSON.parse(token?.id) == 183 || JSON.parse(token?.id) == 875 ? (
              <video autoPlay loop width="100%" controls>
                <source src={formatURL(token?.image)} type="video/mp4" />
              </video>
            ) : (
              <ReactImageFallback
                src={formatURL(token?.image)}
                fallbackImage={"https://picsum.photos/300?random=" + item?.id}
                initialImage="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
                alt={token?.name}
                className="my-image"
              />
            )}
          </Grid>
          <Grid item sm={6} p={2} sx={{ marginLeft: window.innerWidth <= 425 ? 2 : 0 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography id="itemName" variant="h6" fontWeight={"bold"} color={"#000000"} mt={3}>
                RANK #{token?.rank_normalized}
              </Typography>
              <ButtonBase onClick={onClose}>
                <CloseRoundedIcon sx={{ color: "#000000", fontSize: 20 }} />
              </ButtonBase>
            </Grid>

            <Grid
              container
              columns={12}
              columnSpacing={2}
              rowSpacing={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item sm={9}>
                <Typography id="itemSupply" sx={{ mt: 1 }} variant="subtitle2" color="#000000">
                  {token?.name}
                </Typography>
              </Grid>

              <Grid item sm={3}>
                <SocialMediaIcon item={item} />
              </Grid>
            </Grid>

            <Grid container columns={12} columnSpacing={2} rowSpacing={2} sx={{ mt: 0 }}>
              {token?.attributes?.map((item) => (
                <Grid item sm={4} xs={3}>
                  <Box bgcolor={"#CD7555"} paddingY={1}>
                    <Typography variant="subtitle2" color="#000000" textAlign={"center"}>
                      {item.trait_type}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontSize={12}
                      color="#000000"
                      textAlign={"center"}
                      fontWeight={"bold"}
                    >
                      {item.value}
                    </Typography>
                    <Typography variant="subtitle2" color="#000000" textAlign={"center"}>
                      {Math.round(item.percentile * 10000) / 100}%
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ModalPurchase;