import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setConnectWallet } from '../../../redux/actions/setupAction'
import { Box, Container, Button } from '@mui/material';
const release = process.env.REACT_APP_RELEASE;

export class Introduce extends Component {
  render() {
    const { wallet } = this.props;
    return(
      <Box component="section" className='introduce' id="introduce__">
        <Container disableGutters maxWidth="lg" component="header" sx={{ px: {xs: 2,  sm: 2, md: 2 } }}>
          <Box className='intoduceIns'>
            <Box className='art_landing art_left'>
              <Box className="img_art">
                <img src={require('../../../assets/img/web/introduce/art-intro-left.gif')} alt='Art Left' />
                <Box className="mountain">
                  <img src={require('../../../assets/img/web/introduce/mountain-left.png')} alt='Mountain Left' />
                </Box>
              </Box>
              <Box className="wave">
                <img src={require('../../../assets/img/web/introduce/wave-left.png')} alt='Wave Left' />
              </Box>
            </Box>
            
            <Box className='logo_landing'>
              <img src={require('../../../assets/img/web/logo-black.png')} alt='Logo Black'/>
              <Box className='divider'>
                <img src={require('../../../assets/img/web/divider.png')} alt='Divider'/>
              </Box>
              <Box className={`text_landing ${wallet.isConnect ? 'connected' : null}`}>
                In the not so distant future, record-high sea levels leave but only a few inhabitable islands on our planet. Fuzzy creatures from two tribal factions are left to fight it out for survival.
                <br /><br />
                <b>Join the battle.</b>
                <br />
                Which tribe will you support?
              </Box>
              {/* <Box className="btn-mint">
                {release === 'true' && 
                  <Button onClick={this.connectWallet} >
                    STAKE NOW
                  </Button>
                }
              </Box> */}
              <Box style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box className="btn-stakev1">
                  {release === 'true' && 
                    <Button onClick={this.connectWallet} >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;STAKE V1
                    </Button>
                  }
                </Box>
                <Box style={{ width: 100, height: 1 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Box>
                <Box className="btn-stakev2">
                  {release === 'true' && 
                    <Button onClick={this.connectWallet2} >
                      STAKE V2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                  }
                </Box>
              </Box>
            </Box>

            <Box className='art_landing art_right'>
              <Box className="img_art">
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <img src={require('../../../assets/img/web/introduce/art-intro-right.gif')} alt='Art right' />
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                  <img src={require('../../../assets/img/web/introduce/art-intro-mobile.gif')} alt='Art Mobile' />
                </Box>
                <Box className="mountain">
                  <img src={require('../../../assets/img/web/introduce/mountain-right.png')} alt='Mountain right' />
                </Box>
              </Box>
              <Box className="wave">
                <img src={require('../../../assets/img/web/introduce/wave-right.png')} alt='Wave right' />
              </Box>
            </Box>
          </Box>

          {release === 'true' ? <Box style={{ textAlign: 'center', marginTop: 20 }}>
            Please unstake from old contract (Stake V1) and proceed to stake in new contract (Stake V2).
          </Box> : null}

        </Container>
      </Box>
    )
  }


  connectWallet = () => {
    return this.props.passThis.props.history.push('/stakev1')
  }

  connectWallet2 = () => {
    return this.props.passThis.props.history.push('/stakev2')
  }

  connectWalletx = () => {
    const { actionsetConnectWallet, passThis } = this.props
    return passThis.setState({ _loading: true }, () => {
      return actionsetConnectWallet({reconnect: false, wallet: null}).then(_ => {
        return passThis.setState({ _loading: false })
      }).catch(_ => {
        return passThis.setState({ _loading: false })
      })
    })
  }

}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  mint: state.mint,
  network: state.network,
})

const mapDispatchToProps = {
  actionsetConnectWallet: setConnectWallet
}

export default connect(mapStateToProps, mapDispatchToProps)(Introduce)
