export const STRAPI_BASE_URL = process.env.REACT_APP_STRAPI_BASE_URL;
export const SHOP_BASE_URL = "https://staking-fuzzy.herokuapp.com/";

// discord
export const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
export const DISCORD_CLIENT_SECRET = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
export const DISCORD_REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URI;

// localStorage
export const LOCAL_STORAGE_DISCORD_CODE = "DISCORD_CODE";
export const LOCAL_STORAGE_DISCORD_ACCESS_TOKEN = "DISCORD_ACCESS_TOKEN";
export const LOCAL_STORAGE_DISCORD_USER = "DISCORD_USER";
export const LOCAL_STORAGE_USER_WALLET_ADDRESS = "LOCAL_STORAGE_USER_WALLET_ADDRESS";
