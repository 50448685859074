const initialState = {
  loading: false,
  products: [],
  saleTypes: [],
  itemTypes: [],
  types: [],
  meta: null,
  filterSaleTypes: [],
  filterItemTypes: [],
  filterType: null,
  sortBy: null,
  sortMethod: null,
  fuzzBalance: null,
  dailyReward: null
};

export const SET_LOADING = "SET_LOADING";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_SALE_TYPES = "SET_SALE_TYPES";
export const SET_ITEM_TYPES = "SET_ITEM_TYPES";
export const SET_TYPES = "SET_TYPES";
export const SET_META = "SET_META";
export const SET_FILTER_SALE_TYPES = "SET_FILTER_SALE_TYPES";
export const SET_FILTER_ITEM_TYPES = "SET_FILTER_ITEM_TYPES";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_BALANCE = "SET_BALANCE";
export const SET_REDUCE_BALANCE = "SET_REDUCE_BALANCE";
export const SET_CREATE_BALANCE = "SET_CREATE_BALANCE";
export const SET_DAILY_REWARD = "SET_DAILY_REWARD";

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case SET_ITEM_TYPES:
      return {
        ...state,
        itemTypes: action.payload,
      };
    case SET_SALE_TYPES:
      return {
        ...state,
        saleTypes: action.payload,
      };
    case SET_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case SET_META:
      return {
        ...state,
        meta: action.payload,
        loading: false,
      };
    case SET_FILTER_SALE_TYPES:
      const a = [...state.filterSaleTypes];
      if (a.includes(action.payload)) a.splice(a.indexOf(action.payload), 1);
      else a.push(action.payload);
      return {
        ...state,
        filterSaleTypes: a,
      };
    case SET_FILTER_ITEM_TYPES:
      const b = [...state.filterItemTypes];
      if (b.includes(action.payload)) b.splice(b.indexOf(action.payload), 1);
      else b.push(action.payload);
      return {
        ...state,
        filterItemTypes: b,
      };
    case SET_FILTER_TYPE:
      return {
        ...state,
        filterType: action.payload,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortMethod: action.payload.sortMethod,
      };
    case SET_BALANCE:
      return {
        ...state,
        fuzzBalance: action.payload,
      };
    case SET_CREATE_BALANCE:
      return {
        ...state,
        fuzzBalance: action.payload,
      };
      case SET_DAILY_REWARD:
        return {
          ...state,
          dailyReward: action.payload,
        }; 

    default:
      return state;
  }
};

export default shopReducer;
