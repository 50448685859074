import React from 'react'
import { Box, Container, IconButton, Button } from '@mui/material';
import { Loading } from '../../components'

const EnableContract = ({ onEnableContract, loading }) => {
  if(loading){
    return <Loading />
  }
  return(
    <Box component="section" className="introduceStack eContract" id="introduce__">
      <Container>
        <Box className="introduceStackOut">
          <Box className="btn_enable">
            <Box
              component="span"
              onClick={() => onEnableContract(true)}
              sx={{
                p: ".5rem 1rem",
                background: "#BEBAB1",
                width: "20%",
                height: "20%",
                border: "2px solid #000",
                borderBottom: "5px solid #000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div style={{ fontWeight: "bold", color: "#000000" }}>Enable Staking Contract</div>
              {/* <img src={require('../../assets/img/web/btn-enable-connect.png')} alt="button connect" /> */}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default EnableContract;
