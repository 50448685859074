import {
  Box,
  FormControl,
  Grid,
  TextField,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  ButtonBase,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { ArrowRight } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AccordionDetails } from "../../Home/partial/Faq";
import { green } from "@mui/material/colors";
import { useQuery } from "@apollo/client";
import { GET_META } from "../../../queries";
import CheckBox from "../../../components/forms/Checkbox";

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRight sx={{ fontSize: "1.5rem", color: "#000000" }} />}
    {...props}
  />
))(({ theme }) => ({
  color: "#fff",
  padding: 0,
  fontSize: 30,
}));

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  background: "transparent",
  border: "0px solid rgba(0, 0, 0, .125)",
}));

function Sidebar({ onChangeId, setTraits, traits, id }) {
  const { data } = useQuery(GET_META);

  // const handleSetId = (e) => {
  //   setId(e.target.value)
  //   onChangeId(id)
  //   console.log(id);
  // }

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "whitw",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  });
  return (
    <Grid item xs={12} sm={12} md={3}>
      <FormControl
        size="medium"
        fullWidth
        sx={{ borderColor: "white", borderRadius: 0, position: "relative" }}
        style={{ borderColor: "white", borderRadius: 0, position: "relative" }}
      >
        <ButtonBase
          sx={{
            right: 0,
            bottom: 9,
            position: "absolute",
            width: "20%",
            zIndex: 2,
            display: id ? "block" : "none",
          }}
          onClick={() => onChangeId("")}
        >
          <CloseRoundedIcon sx={{ color: "#ffffff", fontSize: 20 }} />
        </ButtonBase>
        <CssTextField
        focused
          label="Search By ID"
          size="small"
          id="custom-css-outlined-input"
          onChange={(e) => {
            // alert(e.target.value);
            onChangeId(e.target.value);
          }}
          value={id ? id : ""}
        />
        {/* <TextField
          sx={{ borderRadius: 0, position: 'relative' }}
          style={{ borderRadius: 0 }}
          size="small"
          id="searchById"
          label="Search By ID"
          variant="outlined"
          color="secondary"
          onChange={(e) => {
            // alert(e.target.value);
            onChangeId(e.target.value);
          }}
          value={id ? id : ""}
        /> */}
      </FormControl>

      <Box style={{ backgroundColor: "#BEBAB1" }} pb={5}>
        <Box className="default" mx={2} mt={1}>
          <Box mb={2}>
            {data?.meta.traitTypes.map((item, i) => (
              <Accordion key={i}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold", fontSize: 16, color: "#000000" }}>
                      {item.trait_type}
                    </Typography>
                    <Typography sx={{ fontSize: 14, color: "#000000" }}>
                      {traits?.filter((x) => x.trait_type === item.trait_type).length || ""}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {item.attributes
                    .filter((attr) => attr.value !== "none")
                    .map((attr, key) => (
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        alignItems={"center"}
                        minHeight={"content"}
                        padding={"5 0"}
                        key={key}
                      >
                        <Typography sx={{ fontSize: 14, color: "#000000", width: "100%" }}>
                          {attr.value}
                        </Typography>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          width={"100%"}
                          alignItems={"center"}
                        >
                          <Typography mr={3} sx={{ fontSize: 14, color: "#000000" }}>
                            {attr.count}
                          </Typography>
                          <CheckBox
                            id={`${attr.value}-${attr.trait_type}`}
                            sx={{
                              color: "#000000",
                              "&.Mui-checked": {
                                color: "#000000",
                              },
                            }}
                            label={""}
                            onChange={(e) => {
                              // console.log(e.target.value);
                              // console.log(e.target.checked);
                              // console.log({ trait_type: attr.trait_type, value: attr.value });
                              // attr.value === 'none' && attr.trait_type !== 'Accessories' ?
                              // setTraits(
                              //   { trait_type: "Trait Count", value: "2" },
                              //   !e.target.checked
                              // ) :
                              setTraits(
                                { trait_type: attr.trait_type, value: attr.value },
                                !e.target.checked
                              );
                            }}
                            value={attr.value}
                          />
                        </Box>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default Sidebar;
