import React, { Component } from "react";
import {
  Box,
  Container,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default class MoonIslandLabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      played: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    }
  }

  render() {
    return (
      <>
        <Box
          component="section"
          className="utility"
          id="moonIslandLabs__"
          sx={{ position: "relative" }}
        >
          <Container sx={{ px: { xs: 2, sm: 2, md: 2 } }}>
            <Box justifyContent={"center"} display={"flex"}>
              <img
                className="windowMoonImg"
                src={require("../../../assets/img/web/v5/windowmoon.png")}
                alt="pandaFox"
              />
            </Box>
          </Container>

          <Box
            sx={{
              px: { xs: 0, sm: 0, md: 0 },
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgba(99,118,91,0.8)",
              width: { md: "100%", xs: "auto" },
              height: "100%",
            }}
          >
            {this.state.played ? (
              <Box
                justifyContent={"center"}
                display={"flex"}
                sx={{ mb: { xs: 5, md: 15 }, mt: { xs: 5, md: 5 } }}
              >
                <iframe
                  title="MoonIslandLabs"
                  width={this.state.isMobile ? window.innerWidth * 0.86 : "1200"}
                  height={this.state.isMobile ? window.innerWidth * 0.55 : 628}
                  src="https://www.youtube.com/embed/0G5FnM2p9PI?rel=0&autoplay=1"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              </Box>
            ) : (
              <>
                <Box
                  justifyContent={"center"}
                  display={"flex"}
                  sx={{ mb: { xs: 5, md: 15 }, mt: { xs: 5, md: 15 } }}
                >
                  <img
                    onClick={() => this.setState({ played: true })}
                    className="playimg"
                    src={require("../../../assets/img/web/v5/play.png")}
                    alt="play"
                  />
                </Box>
                <Box justifyContent={"center"} display={"flex"}>
                  <img
                    className="moonIslandLabsImg"
                    src={require("../../../assets/img/web/v5/moonislandlabs.png")}
                    alt="moonIslandLabsImg"
                  />
                </Box>
                <Box className="desc" sx={{ mt: 4, color: "white" }}>
                  <span color="white" style={{ color: "white", fontSize: 20 }}>
                    A central resource for creators of all mediums in Web 3
                  </span>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </>
    );
  }
}
