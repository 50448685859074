export const statusName = [
  'Shutdown',
  'Reserved Mint',
  'Presale Mint',
  'Public Mint',
  'Close'
]

export const statusNameMint = [
  ' - ( Pending )',
  'Reserved Sale',
  'PreSale',
  'Public Sale',
  'Finished'
]

export const networkName ={
  '1': 'Ethereum Mainnet',
  '2': 'Expanse Network',
  '3': 'Ropsten',
  '4': 'Rinkeby',
  '5': 'Görli',
  '42': 'Kovan',
  'null': '-',

}
