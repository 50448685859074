import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Divider,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  NativeSelect,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Pagination,
  TextField,
} from "@mui/material";
import {
  getItemTypes,
  getProducts,
  getSaleTypes,
  getTypes,
  setItemTypes,
  setSalesTypes,
  setSortBy,
  setType,
} from "./../../../redux/actions/shopAction";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { green, grey } from "@mui/material/colors";
import { ArrowDropDown, ArrowLeft, ArrowRight } from "@mui/icons-material";
import clan from "../../../data/clan";
import CardItem from "./CardItem";
import { IMG_DISCORD_IJO, IMG_FUZZ_SHOP, IMG_RARITY } from "../../../data/images";
import BtnOrangeShadow from "../../../components/buttons/BtnOrangeShadow";
import CheckBox from "../../../components/forms/Checkbox";
import RadioGroupCustom from "../../../components/forms/RadioGroupCustom";
import {
  DISCORD_CLIENT_ID,
  DISCORD_REDIRECT_URI,
  LOCAL_STORAGE_DISCORD_CODE,
} from "../../../config";
import {
  getMeFromCms,
  getToken,
  restoreDiscordUser,
  setDiscordCode,
} from "../../../redux/actions/discordAction";
import { restoreUserWalletAddress, setConfigStaking } from "../../../redux/actions/setupAction";
import BtnDropDown from "../../../components/buttons/BtnDropDown";
import "./RarityComp.css";
import { AccordionDetails } from "../../Home/partial/Faq";
import { styled } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { GET_META } from "../../../queries";
import Sidebar from "./Sidebar";
import TokenList from "./TokenList";

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRight sx={{ fontSize: "1.5rem", color: "#5a7152" }} />}
    {...props}
  />
))(({ theme }) => ({
  color: "#fff",
  padding: 0,
  fontSize: 30,
}));

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  background: "transparent",
  border: "0px solid rgba(0, 0, 0, .125)",
}));
export class RarityComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeSelected: [],
      id: null,
      traits: [],
    };
  }

  loadingBox = () => (
    <Box className="loadingBox">
      <CircularProgress sx={{ color: "#516946" }} />
    </Box>
  );

  purchase = (item) => {
    this.props.passThis.purchase(item);
  };

  componentDidMount() {}

  render() {
    return (
      <Box
        component="section"
        backgroundColor="#000000"
        display="flex"
        padding="8% 0"
        id="fuzzShop__"
      >
    {/* // <Box component="section" className="introduceStack" id="fuzzShop__"> */}
        <Container>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={{ xs: 5, md: 5 }}
            columns={12}
            justifyContent="center"
          >
            <h1 style={{ color: "#ffffff", textAlign: "center", paddingBottom: "3rem", margin: 0 }}>
              Rarity
            </h1>
          </Grid>
          <Grid container rowSpacing={6} columnSpacing={{ xs: 5, md: 5 }} columns={12}>
            <Sidebar
              traits={this.state.traits}
              setTraits={(traitType, isDelete) => {
                const traits = [...this.state.traits];
                if (isDelete) {
                  for (let i = 0; i < traits.length; i++) {
                    console.log(traits[i]);
                    if (
                      traits[i].trait_type === traitType.trait_type &&
                      traits[i].value === traitType.value
                    ) {
                      traits.splice(i, 1);
                    }
                  }
                } else {
                  traits.push(traitType);
                }
                console.log("traits", traits);
                this.setState({ traits });
              }}
              onChangeId={(id) => {
                this.setState({ id });
              }}
              id={this.state.id}
            />

            <TokenList
              searchId={this.state.id}
              traits={this.state.traits}
              setTraits={(traitType, isDelete) => {
                const traits = [...this.state.traits];
                if (isDelete) {
                  for (let i = 0; i < traits.length; i++) {
                    console.log(traits[i]);
                    if (
                      traits[i].trait_type === traitType.trait_type &&
                      traits[i].value === traitType.value
                    ) {
                      traits.splice(i, 1);
                    }
                  }
                } else {
                  traits.push(traitType);
                }
                console.log("traits", traits);
                this.setState({ traits });
              }}
              onDetail={(item) => {
                this.purchase(item);
              }}
              clearAll={()=> {
                const traits = [...this.state.traits];
                traits.forEach(x =>  document.getElementById(`${x.value}-${x.trait_type}`).click())
                traits.splice(0, traits.length)
                // for(let x in traits){
                //   // document.getElementById(`${traits[x].value}-${traits[x].trait_type}`).click()
                //   console.log(traits[x]);
                // }
                // setTimeout(()=> {
                  this.setState({ traits });
                // }, 200)
              }
            }
            />
          </Grid>
        </Container>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  shop: state.shop,
  discord: state.discord,
  wallet: state.wallet,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RarityComp);
