import { Box, Button, Container, Link, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ModalTeam from "./ModalTeam";

const FuzzClan2 = () => {
  const [open, setOpen] = useState(true);
  
  const handleOpenModal = () => {
    
    setOpen(false);
    if(window.innerWidth < 980){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'
    }
    document.documentElement.scrollTop = 0;


  }

  const handleCloseModal = () => {
    setOpen(true);
    document.body.style.overflow = 'auto';

  }

  return (
    <Box
      height="50%"
      borderBottom="solid 2px"
      borderColor="#000000"
      backgroundColor="green"
      className="container-fuzzclan"
    >
      <Box
        width="100%"
        height="100%"
        sx={{ display: "flex" }}
        justifyContent="center"
        alignItems="center"
        bgcolor="#7bb9f6"
        position="relative"
        zIndex={2}
      >
        <img
          style={{ height: "75%", width: "auto" }}
          src={require("../../../assets/img/ill_team.png")}
          alt="illustration"
        />
        <Box
          onClick={handleOpenModal}
          class="overlay-fuzzclan"
          style={{ cursor: "pointer" }}
        >
          <div class="text" style={{ fontWeight: "bold", zIndex: 2 }}>
            Team
          </div>
        </Box>
      </Box>
      <Box className={`content-hide ${open ? "hide-content" : ""}`}>
        <ModalTeam />
        <Box className="close">
          <Button onClick={handleCloseModal}>
            <CloseIcon sx={{ color: "white", fontSize: 40 }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FuzzClan2;