import React from 'react'
import { Box, Container, IconButton } from '@mui/material';
import { Loading } from '../../../components'

const IntroduceEnableContract = ({ onEnableContract, loading }) => {
  if(loading){
    return <Loading />
  }
  return(
    <Box component="section" className='introduceStack eContract' id="introduce__">
      <Container>
        <Box className="introduceStackOut" >
          <Box className="btn_enable">
            <Box className="ic_btn">
              <img src={require('../../../assets/img/web/ic-flag-orange.png')} alt="button unstack" />
            </Box>
            <IconButton component="span" onClick={() => onEnableContract(true)} sx={{ width: 230 }}>
              <img src={require('../../../assets/img/web/btn-enable-contract.png')} alt="button unstack" />
            </IconButton>
            <Box className="ic_btn">
              <img src={require('../../../assets/img/web/ic-flag-black.png')} alt="button unstack" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default IntroduceEnableContract
