import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Container, Button, IconButton } from '@mui/material';
import { LoadingModal } from '../../../components';
import { setChangeStatusContract, setStaking, setUnStaking, setWithdrawRewards } from '../../../redux/actions/setupAction'
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import IntroduceEnableContract from './IntroduceEnableContract';
import CircularProgress from '@mui/material/CircularProgress';

export class IntroduceStacking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _unStakeGrid: true,
      _unStakeActive: [],
      _stakedGrid: true,
      _stakeActive: [],
      _loadingSubmit: false,
      _submitStatus: {
        open: false,
        loading: true,
        wording: '',
        status: null
      }
    }
  }

  renderEmpty = () => (
    new Array(6).fill().map((_, i) => (
      <Box component="li" key={i+1} className="default">
        <Box className="img">
          <img src={require(`../../../assets/img/web/img-default.png`)} alt="img default"/>
        </Box>
      </Box>
    ))
  )

  loadingBox = () => (
    <Box className="loadingBox">
      <CircularProgress sx={{ color: '#516946' }} />
    </Box>
  )

  render() {
    const { show, configStaking, listUnstake, listStake } = this.props;
    const { _unStakeGrid, _unStakeActive, _stakedGrid, _stakeActive, _loadingSubmit, _submitStatus } = this.state;
    if(show){
      if(!configStaking.isContractEnable){
        return <IntroduceEnableContract 
                onEnableContract={(e) => this.onChangeStatusContract(e)} 
                loading={_loadingSubmit} 
              />
      }else{
        return(
          <Box component="section" className='introduceStack' id="introduce__">
            <LoadingModal 
              passData={_submitStatus}
              handleClose={() =>  this.setState({ 
                _submitStatus: { open: false, loading: false, wording: '' }
              })}
            />
            <Container>
              <Box className="info-btn">
                <Box component="ul" className="info">
                  <Box component="li" className="listInfo">Balance: {configStaking.balance || 0 }</Box>
                  <Box component="li" className="listInfo">$FUZZ Earned: {configStaking.fuzzEarned || 0 }</Box>
                  <Box component="li" className="listInfo">$FUZZ/Day: {configStaking.fuzzPerDay || 0 }</Box>
                </Box>
                <Box>
                  <Button 
                    variant="contained"
                    size="small"
                    color="light"
                    disabled={listStake.data.length < 1 || listStake.loading}
                    onClick={() => this.onWithdraw(false)}
                  >
                    Withdraw
                  </Button>
                </Box>
              </Box>
              
              <Box className="introduceStackOut">
                <Box className='introduceStackIns'>
                  <Box className="stack-ins">
                    <Box className="ttl_btn">
                      <Box className="title">Unstake Fuzzies</Box>
                      <Box>
                        <IconButton component="span" className={`btn_grid ${_unStakeGrid ? 'active' : ''}`} onClick={() => this.setState({ _unStakeGrid: true})}>
                          <GridViewIcon />
                        </IconButton>
                        <IconButton component="span" className={`btn_grid ${_unStakeGrid ? '' : 'active'}`} onClick={() => this.setState({ _unStakeGrid: false})}>
                          <ViewListIcon />
                        </IconButton>
                      </Box>
                    </Box>

                    {
                      <Box component="ul" className={`list-stack ${_unStakeGrid ? '' : '_list'}`}>
                        {listUnstake.data.length > 0 ? listUnstake.data.map((res, i) => {
                          const name = res?.metadata ? res?.metadata?.name : `Fuzz-${res?.token_id}`;
                          const img = res?.metadata ? res?.metadata?.image : '-';
                          return(
                            <Box component="li" key={i} className={_unStakeActive.includes(res.token_id) ? 'active' : null}>
                              <Button onClick={() => this.onChooseItem(res, '_unStakeActive')}>
                                <Box className="_id">{name}</Box>
                                <Box className="img">
                                  <img 
                                    src={
                                      res?.metadata ? `https://fuzzyfighters.mypinata.cloud/ipfs/${img.replace("ipfs://", "")}?img-width=512` : require('../../../assets/img/web/img-default.png')
                                    } alt="img list stack" />
                                </Box>
                              </Button>
                            </Box>
                          )
                        }) : this.renderEmpty()}
                      </Box>
                    }

                    <Box className="btn_submit">
                      <IconButton 
                        component="span" 
                        onClick={this.onStaking}
                        disabled={_unStakeActive.length < 1 || listUnstake.loading}
                      >
                        <img src={require('../../../assets/img/web/btn-staked.png')} alt="button stack"/>
                      </IconButton>
                    </Box>
                  </Box>


                  <Box className="stack-ins">
                    <Box className="ttl_btn">
                      <Box className="title">Staked Fuzzies</Box>
                      <Box>
                        <IconButton component="span" className={`btn_grid ${_stakedGrid ? 'active' : ''}`} onClick={() => this.setState({ _stakedGrid: true})}>
                          <GridViewIcon />
                        </IconButton>
                        <IconButton component="span" className={`btn_grid ${!_stakedGrid ? 'active' : ''}`} onClick={() => this.setState({ _stakedGrid: false})}>
                          <ViewListIcon />
                        </IconButton>
                      </Box>
                    </Box>

                    {
                      <Box component="ul" className={`list-stack ${_stakedGrid ? '' : '_list'}`}>
                        {listStake.data.length > 0 ? listStake.data.map((res, i) => {
                          const name = res?.metadata ? res?.metadata?.name : `Fuzz-${res?.token_id}`;
                          const img = res?.metadata ? res?.metadata?.image : '-';
                          return(
                            <Box component="li" key={i} className={_stakeActive.includes(res.token_id) ? 'active' : null}>
                              <Button onClick={() => this.onChooseItem(res, '_stakeActive')}>
                                <Box className="_id">{name}</Box>
                                <Box className="img">
                                  <img 
                                    src={
                                      res?.metadata ? `https://fuzzyfighters.mypinata.cloud/ipfs/${img.replace("ipfs://", "")}?img-width=512` : require('../../../assets/img/web/img-default.png')
                                    } alt="img list stack" />
                                </Box>
                              </Button>
                            </Box>
                          )
                        }) : this.renderEmpty()}
                      </Box>
                    }
                    <Box className="btn_submit">
                      <IconButton 
                        component="span" 
                        onClick={this.onUnStaking}
                        disabled={_stakeActive.length < 1 || listStake.loading}
                      >
                        <img src={require('../../../assets/img/web/btn-unstake.png')} alt="button unstack" />
                      </IconButton>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Container>
          </Box>
        )
      }
    }
    return null
  }

  onChooseItem = (data, stateName) => {
    const token_id = data.token_id;
    var nData = this.state[stateName];
    !nData.includes(token_id) ? nData.push(token_id) : nData.splice(nData.indexOf(token_id), 1);
    return this.setState({
      [stateName]: nData,
    })
  }

  onStaking = async () => {
    const { actionsetStaking } = this.props;
    const { _unStakeActive } = this.state;
    return this.setState({
      _submitStatus: { open: true, loading: true, wording: 'Stake in Progress', status: null }
    }, () => {
      return actionsetStaking(_unStakeActive, () => {
        return this.setState({ 
          _unStakeActive: [],
          _submitStatus: { open: true, loading: false, status: 'success', wording: 'Stake Successful' }
        })
      }, () => {
        return this.setState({ 
          _unStakeActive: [],
          _submitStatus: { open: true, loading: false, status: 'failed', wording: 'Stake Failed' }
        })
      })
    })
  }
  
  onUnStaking = async () => {
    const { actionsetUnStaking } = this.props;
    const { _stakeActive } = this.state;
    return this.setState({
      _submitStatus: { open: true, loading: true, wording: 'Unstake in Progress', status: null }
    }, () => {
      return actionsetUnStaking(_stakeActive, () => {
        return this.setState({ 
          _stakeActive: [],
          _submitStatus: { open: true, loading: false, status: 'success', wording: 'Unstake Successful' }
        })
      }, () => {
        return this.setState({ 
          _stakeActive: [],
          _submitStatus: { open: true, loading: false, status: 'failed',  wording: 'Unstake Failed' }
        })
      })
    })
  }

  onWithdraw = () => {
    const { actionsetWithdrawRewards } = this.props;
    return actionsetWithdrawRewards()
  }

  onChangeStatusContract = (status) => {
    const { actionsetChangeStatusContract } = this.props;
    return this.setState({ _loadingSubmit: true }, () => {
      return actionsetChangeStatusContract(status).then(_ => {
        return this.setState({ _loadingSubmit: false });
      }).catch(_ => {
        return this.setState({ _loadingSubmit: false })
      })
    })
  }
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  listUnstake: state.stake.unstake,
  listStake: state.stake.stake,
  wallet: state.wallet
})

const mapDispatchToProps = {
  actionsetChangeStatusContract: setChangeStatusContract,
  actionsetStaking: setStaking,
  actionsetUnStaking: setUnStaking,
  actionsetWithdrawRewards: setWithdrawRewards
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroduceStacking)
