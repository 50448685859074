import React, { Component } from 'react';
import { Box } from '@mui/material';

export default class About extends Component {

  isHome = () => {
    const about = document.getElementById('about__')
    if (about) return true
    return false
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.isHome()) {
        if (window.location.hash) {
          console.log(this.props)
          // this.props.passThis.props.history.push(window.location.hash)
          window.location.href = window.location.hash
          // alert(1)
        }
      }
    }, 1000)
  }

  render() {
    return (
      <Box component="section" className='about' id="about__">
        <Box component="ul" className='listTeam left'>
          {new Array(9).fill(1).map((_, i) => {
            var fixNum = i >= 5 ? i-4 : i+1;
            return(
              <Box component="li" key={i}>
                <img src={require(`../../../assets/img/web/about/fox/FF_FOX-${fixNum}.png`)} alt={`about-${fixNum}`} />
              </Box>
            )
          })}
        </Box>

        <Box className='about_text'>
          <Box component="span">The collection will start with a genesis collection of 1000 fighters consisting of Fuzzy Foxes and Fuzzy Pandas</Box>
        </Box>

        <Box component="ul" className='listTeam right'>
          {new Array(9).fill(1).map((_, i) => {
            var fixNum = i >= 5 ? i-4 : i+1;
            return(
              <Box component="li"  key={i}>
                  <img src={require(`../../../assets/img/web/about/panda/FF_PANDA-${fixNum}.png`)} alt={`panda-${fixNum}`}/>
              </Box>
            )
          })}
        </Box>
      </Box>
    );
  }
}
