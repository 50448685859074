import { Grid } from "@mui/material";
import React from "react";
import Badge from "../../../components/Badge";

const BadgeTypes = ({ types }) => {
  return (
    <Grid container>
      {types.data?.map?.((itemType) => {
        const typeName = itemType.attributes.name;
        if (typeName === "Auction")
          return <Badge label={"Auction"} key={itemType.name?.toString()} color="warning" />;
        else if (typeName === "Whitelist")
          return <Badge label={"Whitelist"} color="success" key={itemType.name?.toString()} />;
        else if (typeName === "FCFS")
          return <Badge label={"FCFS"} color="info" key={itemType.name?.toString()} />;
        return <Badge label={typeName} color="info" key={itemType.name?.toString()} />;
      })}
    </Grid>
  );
};

export default BadgeTypes;
