import { ethers } from 'ethers';
import ERC20Abi from '../../assets/abierc20.json';
import ERC721Abi from '../../assets/abi721.json';
import StakeAbi from '../../assets/abistake.json';
import StakeAbiV2 from '../../assets/abistakev2.json';
import Web3 from "web3";
import  axios  from 'axios';
window.Buffer = window.Buffer || require('buffer').Buffer;
const stakingAddress = process.env.REACT_APP_CONTRACT_STAKING_ADDRESS;
const nftAddress = process.env.REACT_APP_CONTRACT_NFT_ADDRESS;
const tokenAddress = process.env.REACT_APP_CONTRACT_TOKEN_ADDRESS;
const revenueAddress = process.env.REACT_APP_REVENUE_ADDRESS;
let stakingContract;
let nftContract;
let tokenContract;

export const REACT_APP_CONTRACT_STAKING_ADDRESS = process.env.REACT_APP_CONTRACT_STAKING_ADDRESS;
export const REACT_APP_CONTRACT_STAKING_ADDRESS_OLD = process.env.REACT_APP_CONTRACT_STAKING_ADDRESS_OLD;

export const connectContract =  async web3Modal => {
  try {
    const provider = await web3Modal.connect();
    const getWeb3s = await getWeb3(provider);
    const instance = provider ? new ethers.providers.Web3Provider(provider) : null;
    const signer = instance ? instance.getSigner() : null;
    let stakeAddress = localStorage.getItem('stakingAddress') || stakingAddress;
    let stakeAddressVersion = localStorage.getItem('stakingAddressVersion') || 'new';
    stakingContract = new ethers.Contract(stakeAddress, stakeAddressVersion === 'new' ? StakeAbiV2 : StakeAbi, signer);
    tokenContract = new ethers.Contract(tokenAddress, ERC20Abi, signer);
    nftContract = new ethers.Contract(nftAddress, ERC721Abi, signer);
    return Promise.resolve(getWeb3s.eth)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getWeb3 = async (_provider) => {
  const web3 = new Web3(_provider);
  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });
  return Promise.resolve(web3);
}

// getListUnStake
export const getListStake =  async (address) => {
  return new Promise((resolve, reject) => {
    return stakingContract.depositsOf(address).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

export const getListUnStake = async userAddress => {
  const chain = process.env.REACT_APP_NETWORK_ID === '1' ? 'eth' : 'goerli';
  const url = `${process.env.REACT_APP_STAKE_URL}${userAddress}/nft/${process.env.REACT_APP_CONTRACT_NFT_ADDRESS}?chain=${chain}&format=decimal`;
  return new Promise((resolve, reject) => {
    return axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-API-Key": "zU5gsPeUeKSQrMMn408e9C5Fsk6VBekHRHMcqhQKTxvfnqda8oLIyrGBgu0LEaMG"
      }
    }).then(res=>{
      if(res.status === 200){
        console.log(888, res.data);
        return resolve(res.data)
      }else{
        return reject()
      }
    }).catch(_ =>{
      return reject()
    });
  });
}
export const getMetaData = async token_id => {
  const url = `https://fuzzyfighters.mypinata.cloud/ipfs/QmbEPHA3UqpvqbSwyTqJkoeYNVuvF93S7x2wViWeVVA51n/${token_id}`;
  return new Promise((resolve, reject) => {
    return axios.get(url, {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      }
    }).then(res=>{
      if(res.status === 200){
        return resolve({ data: res.data })
      }else{
        return reject()
      }
    }).catch(_ =>{
      return reject()
    });
  });
}

export const decimals = async () =>{
  return new Promise((resolve, reject) => {
    return tokenContract.balanceOf().then(res => {
      return resolve(parseInt(res))
    }).catch(err => {
      return reject(err)
    })
  })
}

export const isContractEnable = async (userAddress) =>{
  return new Promise((resolve, reject) => {
    let stakeAddress = localStorage.getItem('stakingAddress') || stakingAddress;
    return nftContract.isApprovedForAll(userAddress, stakeAddress).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

export const enableContract = async (approved) =>{
  return new Promise((resolve, reject) => {
    let stakeAddress = localStorage.getItem('stakingAddress') || stakingAddress;
    return nftContract.setApprovalForAll(stakeAddress, approved).then(async res => {
      console.log('setApprovalForAll', res);
      await res?.wait?.();
      return resolve(true);
    }).catch(err => {
      return reject(err)
    })
  })
}

export const submitStake =  async (nfts) => {
  return new Promise((resolve, reject) => {
    return stakingContract.stake(nfts).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

export const submitUnstake =  async (nfts) => {
  return new Promise((resolve, reject) => {
    return stakingContract.unstake(nfts).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

// submit withdraw
export const withdrawRewards =  async (nfts) => {
  return new Promise((resolve, reject) => {
    return stakingContract.withdrawRewards(nfts).then(async res => {
      await res?.wait?.();
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

// get balance
export const balanceOf = async (userAddress) =>{
  return new Promise((resolve, reject) => {
    return tokenContract.balanceOf(userAddress).then(res => {
      return resolve(parseInt(res))
    }).catch(err => {
      return reject(err)
    })
  })
}

// get fuzz earned
export const calculateRewards =  async (address, nfts) => {
  return new Promise((resolve, reject) => {
    return stakingContract.calculateRewards(address, nfts).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

// get fuzz day
export const fuzzPerDay =  async (nfts) => {
  return new Promise((resolve, reject) => {
    return stakingContract.rewardPerDay(nfts).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}

// transfer fuzz token
export const fuzzTokenTransfer = (amount) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    return tokenContract.transfer(revenueAddress, ethers.utils.parseUnits(amount?.toString(), 18)).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err)
    })
  })
}