import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Divider,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  NativeSelect,
} from "@mui/material";
import {
  getItemTypes,
  getProducts,
  getSaleTypes,
  getTypes,
  setItemTypes,
  setSalesTypes,
  setSortBy,
  setType,
} from "./../../../redux/actions/shopAction";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";
import { ArrowDropDown, ArrowLeft, ArrowRight } from "@mui/icons-material";
import clan from "../../../data/clan";
import CardItem from "./CardItem";
import { IMG_DISCORD_IJO, IMG_FUZZ_SHOP } from "../../../data/images";
import BtnOrangeShadow from "../../../components/buttons/BtnOrangeShadow";
import CheckBox from "../../../components/forms/Checkbox";
import RadioGroupCustom from "../../../components/forms/RadioGroupCustom";
import {
  DISCORD_CLIENT_ID,
  DISCORD_REDIRECT_URI,
  LOCAL_STORAGE_DISCORD_CODE,
} from "../../../config";
import {
  getMeFromCms,
  getToken,
  restoreDiscordUser,
  setDiscordCode,
} from "../../../redux/actions/discordAction";
import {
  restoreUserWalletAddress,
  setConfigStaking,
  setConnectWallet,
  setDisconnectWallet,
} from "../../../redux/actions/setupAction";
import BtnIjo from "../../../components/buttons/BtnIjo";
import BtnDropDown from "../../../components/buttons/BtnDropDown";

export class MustConnectWalletDiscord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _sortBy: null,
      typeSelected: [],
    };
  }

  loadingBox = () => (
    <Box className="loadingBox">
      <CircularProgress sx={{ color: "#516946" }} />
    </Box>
  );

  disconnectWallet = async () => {
    const { actionsetDisconnectWallet, passThis } = this.props;
    return passThis.setState({ _loading: true }, () => {
      return actionsetDisconnectWallet().then((_) => {
        return passThis.setState({
          _loading: false,
        });
      });
    });
  };

  connectWallet = () => {
    const { actionSetConnectWallet, passThis } = this.props;
    return passThis.setState({ _loading: true }, () => {
      return actionSetConnectWallet({ reconnect: false, wallet: null })
        .then((_) => {
          return passThis.setState({ _loading: false });
        })
        .catch((_) => {
          return passThis.setState({ _loading: false });
        });
    });
  };

  connectToDiscord = () => {
    if (this.props.discord.code) {
      window.localStorage.removeItem(LOCAL_STORAGE_DISCORD_CODE);
      this.props.actionSetDiscordCode(null);
    } else {
      window.location.href =
        "https://discord.com/api/oauth2/authorize?client_id=" +
        DISCORD_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(DISCORD_REDIRECT_URI) +
        "&response_type=code&scope=identify";
    }
  };
  componentDidMount() {}

  render() {
    const { configStaking, wallet, discord } = this.props;
    const userAddress = wallet?.address || null;
    const {} = this.state;

    if (userAddress && discord?.code) {
      return null;
    }

    return (
      <Box
        component="section"
        className="introduceStack"
        id="fuzzShopConnect__"
        style={{ zIndex: 2, position: "absolute", opacity: 0.95, width: "100%" }}
      >
        <Container>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={{ xs: 5, md: 5 }}
            columns={12}
            justifyContent="center"
            alignItems={"center"}
            style={{ minHeight: "100vh" }}
          >
            <Grid item>
              <div style={{color: "#CD7555", fontWeight: "bold", textAlign: "center", fontSize: "3rem"}}>FUZZ SHOP</div>
              {/* <Typography color="#839f89" variant="h1" textAlign="center">
                FUZZ SHOP
              </Typography> */}
              {/* <img
                src={IMG_FUZZ_SHOP}
                alt="FUZZ SHOP"
                style={{ marginBottom: 10, maxWidth: "80vh" }}
              /> */}
              <Box>
                <Typography color="#CD7555" variant="subtitle1" textAlign="center">
                  {/* You need to connect your wallet and discord first */}
                  You need to connect both your wallet and discord before you can enter
                </Typography>
              </Box>
              <Grid
                container
                rowSpacing={6}
                columnSpacing={{ xs: 5, md: 5 }}
                columns={12}
                justifyContent="center"
                style={{ marginTop: 2 }}
              >
                <Grid item>
                  {userAddress ? (
                    <BtnDropDown
                      style={{ marginRight: 5 }}
                      label={userAddress?.slice(0, 10) + "..."}
                      items={[
                        {
                          label: "Logout",
                          onClick: () => {
                            this.disconnectWallet();
                          },
                        },
                      ]}
                    />
                  ) : (
                    // <BtnIjo
                    //   outlined
                    //   label={userAddress?.slice(0, 10) + "..."}
                    //   endIcon={<ArrowDropDown />}
                    // />
                    <BtnIjo onPress={this.connectWallet} label={"CONNECT WALLET"} />
                  )}
                </Grid>
                <Grid item>
                  <BtnIjo label={"CONNECT DISCORD"} onPress={this.connectToDiscord} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  shop: state.shop,
  discord: state.discord,
  wallet: state.wallet,
});

const mapDispatchToProps = {
  actionGetProducts: getProducts,
  actionGetItemTypes: getItemTypes,
  actionGetSaleTypes: getSaleTypes,
  actionGetTypes: getTypes,
  actionSetSaleTypes: setSalesTypes,
  actionSetItemTypes: setItemTypes,
  actionSetType: setType,
  actionSortBy: setSortBy,
  actionSetDiscordCode: setDiscordCode,
  actionGetDiscordToken: getToken,
  actionRestoreUserWalletAddress: restoreUserWalletAddress,
  actionSetConfigStaking: setConfigStaking,
  actionRestoreDiscordUser: restoreDiscordUser,
  actionGetDiscordMe: getMeFromCms,
  actionSetConnectWallet: setConnectWallet,
  actionsetDisconnectWallet: setDisconnectWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(MustConnectWalletDiscord);
