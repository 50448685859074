import React, { Component } from "react";
import { Introduce, IntroduceStacking, About, Roadmap, Utility, FuzzClan, Faq } from "./partial";
import { Main, Loading } from "../../components";
import { connect } from "react-redux";
import { setInit, setDisconnectWallet } from "../../redux/actions/setupAction";
import { Box } from "@mui/material";
import Partnership from "./partial/Partnership";
import StakingFuzz from "./partial/StakingFuzz";
import MoonIslandLabs from "./partial/MoonIslandLabs";
import About2 from "./partial/About2";
import Roadmap2 from "./partial/Roadmap2";
import Utility2 from "./partial/Utility2";
import FuzzClan2 from "./partial/FuzzClan2";
import Rarity2 from "./partial/Rarity2";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
    };
  }

  componentDidMount() {
    const { actionsetInit } = this.props;
    return actionsetInit()
      .then((_) => {
        return this.setState({ _loading: false });
      })
      .catch((_) => {
        return this.setState({ _loading: false });
      });
  }

  disconnect = async (e) => {
    const { actionsetDisconnectWallet } = this.props;
    if (e.length === 0) {
      return this.setState({ _loading: true }, () => {
        return actionsetDisconnectWallet()
          .then((_) => {
            return this.setState({ _loading: false });
          })
          .catch((_) => {
            return this.setState({ _loading: false });
          });
      });
    }
  };

  render() {
    const { _loading } = this.state;
    const { wallet } = this.props;
    if (_loading) {
      return <Loading />;
    }
    return (
      <Main passThis={this} passData={this.state}>
        <Box className="homepage">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100vw",
              height: "100vh",
            }}
          >
            <About2 />
            <Box
              sx={{ width: { xs: "100%", md: "50%" } }}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Roadmap2 />
              {/* <Box width="100%" sx={{height: {xs: "100%", md: "50%"}}} borderBottom="solid 2px" borderColor="#000000" backgroundColor="green">roadmap</Box> */}
              <Box
                width="100%"
                sx={{
                  height: { xs: "100%", md: "50%" },
                  flexDirection: { xs: "column", md: "row" },
                }}
                display="flex"
              >
                {/* <Box sx={{width: {xs: "100%", md: "50%"}}} borderRight="solid 2px" borderColor="#000000" backgroundColor="red">Utility</Box> */}
                <Utility2 />
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ width: { xs: "100%", md: "50%" } }}
                >
                  {/* <Box height="50%" borderBottom="solid 2px" borderColor="#000000" backgroundColor="green">Clan</Box> */}
                  <FuzzClan2 />
                  {/* <Box height="50%" backgroundColor="red">Rarity</Box> */}
                  <Rarity2 />
                </Box>
              </Box>
            </Box>
            {/* <Box width="50%" height="100%" className="container-aboutus">
            <Box
              width="100%"
              height="100%"
              sx={{ display: { xs: "block", md: "flex" } }} 
              justifyContent="center"
              alignItems="center"
              bgcolor="red"
              position="relative"
              border="solid 2px #000000"
              zIndex={2}
            >
              <img
                style={{ height: "75%", width: "auto" }}
                src={require("../../assets/img/ill_aboutus.png")}
                alt="illustration"
              />
              <div
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  top: "10%",
                  right: "5%",
                  width: "25%",
                }}
              >
                <h2>About Us</h2>
                <p style={{ fontSize: "12px" }}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make a type specimen
                  book.
                </p>
              </div>
              <div class="overlay-aboutus" style={{cursor: "pointer"}}>
                <div class="text" style={{fontWeight:"bold"}}>About Us</div>
              </div>
            </Box>
          </Box> */}
          </Box>
        </Box>
      </Main>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
});

const mapDispatchToProps = {
  actionsetInit: setInit,
  actionsetDisconnectWallet: setDisconnectWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
