import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Container, Button } from "@mui/material";
import { Main, Loading } from "../../components";
import {
  setInit,
  setConnectWallet,
  setChangeStatusContract,
} from "../../redux/actions/setupAction";
import EnableContract from "./EnableContract";
import Stacking from "./Stacking";
import {
  REACT_APP_CONTRACT_STAKING_ADDRESS,
  REACT_APP_CONTRACT_STAKING_ADDRESS_OLD,
} from "../../redux/actions/contractAction";

export class Stake extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _loadingSubmit: false,
    };
  }
  componentDidMount() {
    const isStakingOld = window.location.href.includes("stakev1");
    if (isStakingOld) {
      window.localStorage.setItem("stakingAddress", REACT_APP_CONTRACT_STAKING_ADDRESS_OLD);
      window.localStorage.setItem("stakingAddressVersion", "old");
    } else {
      window.localStorage.setItem("stakingAddress", REACT_APP_CONTRACT_STAKING_ADDRESS);
      window.localStorage.setItem("stakingAddressVersion", "new");
    }

    setTimeout(() => {
      const { actionsetInit } = this.props;
      return actionsetInit()
        .then((_) => {
          return this.setState({ _loading: false });
        })
        .catch((_) => {
          return this.setState({ _loading: false });
        });
    }, 1000);
  }

  render() {
    const { wallet, configStaking } = this.props;
    const { _loading, _loadingSubmit } = this.state;
    if (_loading) {
      return <Loading />;
    } else {
      if (wallet.isConnect) {
        if (!configStaking.isContractEnable) {
          return (
            <EnableContract
              onEnableContract={(e) => this.onChangeStatusContract(e)}
              loading={_loadingSubmit}
            />
          );
        } else {
          return <Stacking passThis={this} />;
        }
      } else {
        return (
          <Main passThis={this} passData={this.state}>
            <Box component="section" className="introduceStack eContract" id="introduce__">
              {/* <Box component="section" className='introduceStack eContract' id="introduce__"> */}
              <Container>
                <Box className="introduceStackOut" borderRadius="0rem">
                  <Box
                    width="100%"
                    height="100%"
                    sx={{ display: { sm: "block", md: "flex" } }}
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    marginBottom="4%"
                  >
                    <img
                      style={{ height: "auto", width: "20%" }}
                      src={require("../../assets/img/web/introduce/ill_utility_new.png")}
                      alt="illustration"
                    />
                  </Box>

                  <Box className="btn_enable">
                    <Box
                      component="span"
                      onClick={() => this.connectWallet()}
                      sx={{
                        p: ".5rem 1rem",
                        background: "#BEBAB1",
                        width: "20%",
                        height:"20%",
                        border: "2px solid #000",
                        borderBottom: "5px solid #000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div style={{ fontWeight: "bold", color: "#000000" }}>Connect Wallet</div>
                      {/* <img src={require('../../assets/img/web/btn-enable-connect.png')} alt="button connect" /> */}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Main>
        );
      }
    }
  }

  connectWallet = () => {
    const { actionsetConnectWallet } = this.props;
    return this.setState({ _loading: true }, () => {
      return actionsetConnectWallet({ reconnect: false, wallet: null })
        .then((_) => {
          return this.setState({ _loading: false });
        })
        .catch((_) => {
          return this.setState({ _loading: false });
        });
    });
  };

  onChangeStatusContract = (status) => {
    const { actionsetChangeStatusContract } = this.props;
    return this.setState({ _loadingSubmit: true }, () => {
      return actionsetChangeStatusContract(status)
        .then((_) => {
          return this.setState({ _loadingSubmit: false });
        })
        .catch((_) => {
          return this.setState({ _loadingSubmit: false });
        });
    });
  };
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  listUnstake: state.stake.unstake,
  listStake: state.stake.stake,
  wallet: state.wallet,
});

const mapDispatchToProps = {
  actionsetChangeStatusContract: setChangeStatusContract,
  actionsetConnectWallet: setConnectWallet,
  actionsetInit: setInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stake);
