import { Box, Link } from "@mui/material";
import React from "react";

const Rarity2 = () => {
  return (
    <Box height="49%" borderColor="#000000" className="container-rarity bg-rarity">
      <Box
        width="100%"
        height="100%"
        sx={{ display: "flex" }}
        justifyContent="center"
        alignItems="center"
        position="relative"
        zIndex={2}
      >
        <img
          style={{ height: "75%", width: "auto" }}
          src={require("../../../assets/img/ill_rarity.png")}
          alt="illustration"
        />
        <Link href="/rarity">
          <Box class="overlay-rarity" style={{ cursor: "pointer" }}>
            <div class="text" style={{ fontWeight: "bold", zIndex: 2 }}>
              Rarity
            </div>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Rarity2;
