const initialState = {
  data: [],
  loading: true,
}

const unstakeReducer = (state = initialState, action) => {
  switch (action.type){
    case 'SET_LIST_UNSTAKE':
      return{ 
        ...state, 
        // data: [],
        loading: true,
      }
    case 'SET_LIST_UNSTAKE_SUCCESS':
      return{ 
        ...state, 
        data: action.payload.data,
        loading: false,
      }
    case 'SET_LIST_UNSTAKE_FAILED':
      return{ 
        ...state, 
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

export default unstakeReducer;