const initialState = {
  loading: false,
  name: null,
  id: null,
  connect: true
}

const mintReducer = (state = initialState, action) => {
  switch (action.type){
    case 'SET_NETWORK':
      return{ 
        ...state, 
        loading: true,
      }
    case 'SET_NETWORK_SUCCESS':
      return{ 
        ...state, 
        name: action.payload.name,
        id: action.payload.id,
        connect: true,
        loading: false
      }
    case 'SET_NETWORK_FAILED':
      return{ 
        ...state, 
        name: action.payload.name,
        id: action.payload.id,
        connect: false,
        loading: false
      }
    default:
      return state
  }
}

export default mintReducer;