import React, { Component } from "react";
import { Box, Container } from "@mui/material";

export default class Roadmap extends Component {
  render() {
    return (
      <Box component="section" className="roadmap" id="roadmap__">
        <Container sx={{ px: { xs: 2, sm: 2, md: 2 } }}>
          <Box className="roadmapIns">
            <Box className="listPhase left">
              <Box className="phase left phase1">
                <Box className="img_mobile" sx={{ display: { xs: "block", md: "none" } }}>
                  <img src={require("../../../assets/img/web/phase/island-1.png")} alt="roadmap" />
                </Box>
                <Box className="phaseIns">
                  <Box className="ttl">PHASE 1</Box>
                  <Box className="desc">
                    <Box component="p">Genesis Collection:</Box>
                    <Box component="p">
                      <Box component="span">A collection of 1000 Fuzzy Fighters</Box>
                      <Box component="span">battling it out for survival on the blockchain</Box>
                    </Box>
                    <Box component="p">Staking for $FUZZ token </Box>
                    <Box component="p">Treasury DAO</Box>
                    <Box component="p">
                      <Box component="span">Charity donation to animal conservation</Box>
                      <Box component="span">and nature preservation programs</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="phase left phase2">
                <Box className="phaseIns" sx={{ display: { xs: "none", md: "block" } }}>
                  <Box className="ttl">PHASE 3 TO 5</Box>
                  <Box className="desc">To be revealed.</Box>
                </Box>

                <Box className="phaseIns" sx={{ display: { xs: "block", md: "none" } }}>
                  <Box className="ttl">PHASE 2</Box>
                  <Box className="desc">
                    {/* V2 Collection:
                    <br />
                    A collection of mutated Fuzzy Fighters
                    <br />
                    have emerged post-battle
                    <br />
                    <br />
                    Brand Partnerships
                    <br />
                    <br />
                    Creator Space
                    <br /> */}
                    V2 Collection
                    <br />
                    <br />
                    Brand Partnerships
                    <br />
                    <br />
                    Moon Island Labs (Creator Space)
                    <br />
                  </Box>
                </Box>
                <Box className="img_mobile" sx={{ display: { xs: "block", md: "none" } }}>
                  <img src={require("../../../assets/img/web/phase/island-2.png")} alt="roadmap" />
                </Box>
              </Box>
            </Box>

            <Box className="img">
              <img src={require("../../../assets/img/web/phase/island-full.png")} alt="roadmap" />
            </Box>

            <Box className="listPhase right">
              <Box className="phase right phase3">
                <Box className="phaseIns" sx={{ display: { xs: "none", md: "block" } }}>
                  <Box className="ttl">PHASE 2</Box>
                  <Box className="desc">
                    {/* V2 Collection:
                    <br />
                    A collection of mutated Fuzzy Fighters 
                    <br />
                    have emerged post-battle
                    <br /><br />
                    Brand Partnerships 
                    <br /><br />
                    Creator Space
                    <br /> */}
                    V2 Collection
                    <br />
                    <br />
                    Brand Partnerships
                    <br />
                    <br />
                    Moon Island Labs (Creator Space)
                    <br />
                  </Box>
                </Box>
                <Box className="phaseIns" sx={{ display: { xs: "block", md: "none" } }}>
                  <Box className="ttl">PHASE 3 TO 5</Box>
                  <Box className="desc">To be revealed.</Box>
                </Box>
                <Box className="img_mobile" sx={{ display: { xs: "block", md: "none" } }}>
                  <img src={require("../../../assets/img/web/phase/island-3.png")} alt="roadmap" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
}
