import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

function RadioGroupCustom({ label, defaultChecked, items = [], onChange }) {
  const sx = {
    color: grey[100],
    "&.Mui-checked": {
      color: grey[100],
    },
  };
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        {items.map((item) => (
          <FormControlLabel
            value={item.value}
            control={<Radio onChange={onChange} sx={sx} />}
            label={item.label}
            key={item.value?.toString()}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioGroupCustom;
