import axios from "axios";
import { STRAPI_BASE_URL, SHOP_BASE_URL } from "./../config";

export const getStrapi = async (uri, params = {}) =>
  await axios.get(STRAPI_BASE_URL + "/api/" + uri, { params });

export const postStrapi = async (uri, data, params = {}) =>
  await axios.post(STRAPI_BASE_URL + "/api/" + uri, { data }, { params });

export const getShopBE = async (uri, params ={}) => {
  await axios.get(SHOP_BASE_URL + uri, {params})
}

export const postShopBE = async (uri, data, params = {}) =>
  await axios.post(SHOP_BASE_URL + uri, data, { params });

export const putShopBE = async (uri, data, params = {}) =>
  await axios.put(SHOP_BASE_URL + uri, data, { params });

