const initialState = {
  loading: false,
  isConnect: false,
  address: null
}

export const SET_USER_WALLET_ADDRESS = 'SET_USER_WALLET_ADDRESS'

const walletReducer = (state = initialState, action) => {
  switch (action.type){
    case 'SET_WALLET':
      return{
        loading: true
      }
    case 'SET_WALLET_SUCCESS':
      return{ 
        ...state, 
        loading: false,
        isConnect: action.payload.isConnect,
        address: action.payload.address,
        isContractEnable: action.payload.isContractEnable || false
      }
    case 'SET_WALLET_FAILED':
      return{
        loading: true
      }
    case SET_USER_WALLET_ADDRESS:
      return {
        ...state,
        address: action.payload,
        isConnect: true,
      }
    default:
      return state
  }
}

export default walletReducer;