import React, { Component } from 'react';
import { 
  Box, 
  Container,
  Accordion as MuiAccordion, 
  AccordionSummary as MuiAccordionSummary, 
  AccordionDetails as MuiAccordionDetails, 
  Typography 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  background: 'transparent',
  borderBottom: '1px solid #414042',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem', color: '#414042' }} />}
    {...props}
  />
))(() => ({
  color: '#414042',
  padding: 0,
  fontSize: 30
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    color: '#414042'
}));

export default class Utility extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _utility:[
        {title: "How to get $FUZZ?", desc: "<ul> <li>Simply stake your NFT and accrue $FUZZ tokens on a daily basis</li> <li>Booster Tokens</li> <li>Owning an NFT entitles you regular $FUZZ distributions in accordance with the Community Treasury distribution plan (details to follow)</li> </ul>"},
        {title: "What are the utilities?", desc:"<ul> <li> NFT projects launched on Creator Space:<br /> o Burn for pre-sale access<br /> o Mint at a discounted price </li> <li>Fuzzy Fighters merchandise</li> <li>IRL lifestyle benefits with participating brands</li> <li>Cross-project benefits with future partners</li> <li>Phase III to Phase V utilities TBA</li> </ul>"},
        {title: "Find out more about its store of value!", desc: "Besides the practical applications of $FUZZ, we will back $FUZZ by our community treasury's cumulative gains. Hence, $FUZZ will have the benefit of this base layer of value. As the project advances, and our treasury grows, we would expect to see $FUZZ appreciate against inflationary fiat currencies over the long-term."},
        {title: "What are Booster Tokens?", desc: "A reward in the form of $FUZZ token given on a regular basis to our NFT holders. Details of distribution and reward system will be revealed at a later date"},
      ]
    }
  }
  
  render() {
    const { _utility } = this.state
    return (
      <>
        <Box component="section" className='utility' id="utility__">
          <Container sx={{ px: {xs: 2,  sm: 2, md: 2 } }} >
            <Box className='title'>
              <Box className="ic">
                <img src={require('../../../assets/img/web/ic-utility-left.png')} alt="utility left"/>  
              </Box>
              <Box className="txt">$FUZZ Token</Box>
              <Box className="ic">
                <img src={require('../../../assets/img/web/ic-utility-right.png')} alt="utility right" />
              </Box>
            </Box>
            <Box className="desc">
              $FUZZ Tokens will be the currency of our Fuzzy Fighter ecosystem
            </Box>
            <Box className='faqIns'>
              {_utility.map((res, i) => (
                <Accordion key={i}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{res.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{__html: res.desc}} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Container>
          
          <Box className="creator_space">
            <Container>
              <Box className='title'>
                <Box className="txt">Creator Space</Box>
              </Box>
              <Box className="desc">
                A community-driven launchpad for talented artists of all mediums to launch their very own NFT collections.  <br />
                Creator Space will also be a resource for artists to meet, create, and collaborate. <br />
                Proceeds from the Creator Space collections will fund development costs, the community treasury, and most importantly the artist.
              </Box>

              <Box className="content">
                <Box className="list">
                  <Box className="img">
                    <img src={require('../../../assets/img/web/utility/space-1.png')} alt="utility space #1"/>
                  </Box>
                </Box>
                <Box className="list">
                  <Box className="img">
                    <img src={require('../../../assets/img/web/utility/space-2.png')} alt="utility space #2"/>
                  </Box>
                </Box>
                <Box className="list">
                  <Box className="img">
                    <img src={require('../../../assets/img/web/utility/space-3.png')} alt="utility space #3"/>
                  </Box>
                  <Box className="txt">
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </>
    );
  }
}
