import { Box, Button, Container, Link, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ModalRoadmap from "./ModalRoadmap";

const Roadmap2 = () => {
  const [open, setOpen] = useState(true);

  const handleOpenModal = () =>{
    setOpen(false);
    if(window.innerWidth < 980){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'
    }
    document.documentElement.scrollTop = 0;

  } 

  const handleCloseModal = () => {
    setOpen(true);
    document.body.style.overflow = 'auto';
  }

  return (
    <Box
      className="container-roadmap bg-roadmap"
      width="100%"
      sx={{ height: { xs: "100%", md: "50%" } }}
      borderBottom="solid 2px"
      borderColor="#000000"
    >
      <Box
        width="100%"
        height="100%"
        sx={{ display: "flex" }}
        justifyContent="center"
        alignItems="center"
        position="relative"
        zIndex={2}
      >
        <img
          style={{ height: "auto", width: "70%" }}
          src={require("../../../assets/img/ill_roadmap.png")}
          alt="illustration"
        />
        <Box onClick={handleOpenModal} class="overlay-roadmap" style={{ cursor: "pointer" }}>
          <div class="text" style={{ fontWeight: "bold" }}>
            Roadmap
          </div>
        </Box>
      </Box>
      <Box className={`content-hide ${open ? "hide-content" : ""}`}><ModalRoadmap />
      <Box className="close">
        <Button onClick={handleCloseModal}>
              <CloseIcon sx={{ color: "white", fontSize: 40 }} />
            </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Roadmap2;