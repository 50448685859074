import {
  IMG_KEVIN_RUM,
  IMG_TINY_COZY,
  IMG_MAHENDRA,
  IMG_WIMO,
  IMG_JOHN,
  IMG_PLAYHOUSE,
  IMG_KAI,
  IMG_DAGELAN,
  IMG_CHAMPT,
  IMG_2,
} from "./images2";

const clan = [
  {
    name: "Kevin Rum",
    role: "Conceptor",
    twitter: "https://twitter.com/kevinrum",
    character: "kevinrum.png",
    img: IMG_KEVIN_RUM,
  },
  {
    name: "Tiny Cozy Cub",
    role: "Artist",
    twitter: "https://twitter.com/tinycozycub",
    character: "tinycozy.png",
    img: IMG_TINY_COZY,
  },
  {
    name: "Mahendra Nazar",
    role: "Artist",
    twitter: "https://twitter.com/being_micro?s=21&t=sfr48MaYKxcEtwjlFU8LGw",
    character: "mahendra.png",
    img: IMG_MAHENDRA,
  },
  {
    name: "Wimo",
    role: "Project Lead",
    twitter: "",
    character: "wimo.png",
    img: IMG_WIMO,
  },
  {
    name: "John Gamboa",
    role: "Strategist",
    character: "john.png",
    twitter: "",
    img: IMG_JOHN,
  },
  {
    name: "Playhouse",
    role: "Blockchain Advisor",
    twitter: "",
    character: "playhouse.png",
    img: IMG_PLAYHOUSE,
  },
  {
    name: "Kai",
    role: "Advisor",
    twitter: "https://twitter.com/pangxuekai",
    character: "kai.png",
    img: IMG_KAI,
  },
  {
    name: "Dagelan",
    role: "Advisor",
    twitter: "https://twitter.com/Dagelanco",
    character: "dagelan.png",
    img: IMG_DAGELAN,
  },
  {
    name: "Champ T",
    role: "Advisor",
    twitter: "https://twitter.com/champtgram",
    character: "campt.png",
    img: IMG_CHAMPT,
  },
  {
    name: "",
    role: "",
    twitter: "",
    character: "ill_team_new2.png",
    img: IMG_2,
  },
];

export default clan;
