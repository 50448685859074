import React from "react";
import "./BtnDropDown.css";

const BtnDropDown = ({
  borderColor = "#6a7f60",
  label,
  color = "#6a7f60",
  items = [],
  style = {},
}) => {
  return (
    <label className="dropdown-ijo" style={{ ...style }}>
      <div className="dd-button-ijo" style={{ borderColor, color }}>
        {label}
      </div>

      <input type="checkbox" className="dd-input-ijo" id="test" />

      <ul className="dd-menu-ijo">
        {items.map((item) => (
          <li key={item?.label}>
            <a
              onClick={(e) => {
                e.preventDefault();
                item.onClick();
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </label>
  );
};

export default BtnDropDown;
