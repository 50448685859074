import { Button } from "@mui/material";
import React from "react";

function Badge({ label, color }) {
  return (
    <Button
      sx={{ fontSize: 8, borderRadius: 0, marginRight: 1, backgroundColor: "transparent", color: "black", border: "solid 1px black" }}
      // sx={{backgroundColor: "transparent", color: "black", border: "solid 1px black"}}
      size="small"
      variant="outlined"
      // color={color}
    >
      {label}
    </Button>
  );
}

export default Badge;
