import { Checkbox, FormControlLabel } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

function CheckBox({ label, defaultChecked, onChange, value, sx, id }) {
  if (!sx)
    sx = {
      color: grey[100],
      "&.Mui-checked": {
        color: grey[100],
      },
    };
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          defaultChecked={defaultChecked}
          sx={sx}
          size="small"
          onChange={onChange}
          value={value}
        />
      }
      label={label}
    />
  );
}

export default CheckBox;
