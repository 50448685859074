import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Main } from "../../../components";

const ModalAbout = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box component="section" className="about2" id="about__">
      <Box sx={{ width: "100%", height: "90%" }}>
        <Box className="aboutIns">
          <Box className="imgAbout">
            <img src={require("../../../assets/img/new_ill_aboutus.png")} alt="illustration" />{" "}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "90%" }}>
        <Box className="about_text">
          <Box component="span" className="bold">
            About Us
          </Box>
          <Box component="span" className="bold2">
            Welcome to your WARM & FUZZY home
          </Box>
          <Box component="span" className="text2">
            We are a hub that strives to build value for the community. A place where degens,
            creators and collectors can come together to connect, vibe and reach their goals. This
            will be supported by our Alpha Hub, Creator Hub and future creative ventures.
          </Box>
          <Box component="span" className="text2">
            AstroFuzz is the first step in entering our web3 ecosystem, where we aim to make cool
            and creative things. We're not here to make big promises but with the help of our
            community we will always try our best to deliver value and a good experience for all.
          </Box>
        </Box>
      </Box>
      {/* <Box component="ul" className='listTeam left'>
          {new Array(9).fill(1).map((_, i) => {
            var fixNum = i >= 5 ? i-4 : i+1;
            return(
              <Box component="li" key={i}>
                <img src={require(`../../../assets/img/web/about/fox/FF_FOX-${fixNum}.png`)} alt={`about-${fixNum}`} />
              </Box>
            )
          })}
        </Box>

        <Box className='about_text'>
          <Box component="span">The collection will start with a genesis collection of 1000 fighters consisting of Fuzzy Foxes and Fuzzy Pandas</Box>
        </Box>

        <Box component="ul" className='listTeam right'>
          {new Array(9).fill(1).map((_, i) => {
            var fixNum = i >= 5 ? i-4 : i+1;
            return(
              <Box component="li"  key={i}>
                  <img src={require(`../../../assets/img/web/about/panda/FF_PANDA-${fixNum}.png`)} alt={`panda-${fixNum}`}/>
              </Box>
            )
          })}
        </Box> */}
    </Box>
  );
};

export default ModalAbout;
