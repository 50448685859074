import React, { Component } from 'react';
import { Box, Fade, Modal, Typography, IconButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';

export default class LoadingModal extends Component {
  render() {
    const { handleClose, passData: { open, loading, wording, status } } = this.props;
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #516946',
      boxShadow: 24,
      py: 8,
    };
    return (
      <Modal
          open={open}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style} className="loading_modal">
              {!loading &&
                <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose} className="ic_close" >
                    <CloseIcon sx={{color: '#000', fontSize: 20}}/>
                  </IconButton>
                </Box>
              }
              {loading ?
                <Box className="icon_ ic_loading">
                  <img src={require('../assets/img/web/ic-loading.png')} />
                </Box> : 
                status === 'success' ? 
                  <Box className="icon_">
                    <img src={require('../assets/img/web/ic-modal-success.png')} />
                  </Box> : 
                status === 'failed' ? 
                  <Box className="icon_">
                    <img src={require('../assets/img/web/ic-modal-failed.png')} />
                  </Box> : null
              }
              <Typography id="transition-modal-title" variant="h6" component="h2"  sx={{ textAlign: 'center', mt: 5 }} className="text">
                {wording || ''}
              </Typography>
            </Box>
          </Fade>
        </Modal>
    )
  }
}