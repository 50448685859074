const initialState = {
  data: [],
  loading: true,
}

const stakeReducer = (state = initialState, action) => {
  switch (action.type){
    case 'SET_LIST_STAKE':
      return{ 
        ...state, 
        // data: [],
        loading: true,
      }
    case 'SET_LIST_STAKE_SUCCESS':
      return{ 
        ...state, 
        data: action?.payload?.data || [],
        loading: false,
      }
    case 'SET_LIST_STAKE_FAILED':
      return{ 
        ...state, 
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

export default stakeReducer;