import { Box, Button, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import About from "./About";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ModalAbout from "./ModalAbout";

const About2 = () => {
  const [open, setOpen] = useState(true);

  const handleOpenModal = () => {
    
    setOpen(false);
    if(window.innerWidth < 980){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'
    }
    document.documentElement.scrollTop = 0;


  }

  const handleCloseModal = () => {
    setOpen(true);
    document.body.style.overflow = 'auto';

  }
  return (
    <Box
      sx={{ width: { xs: "100%", md: "50%" } }}
      minHeight="100%"
      className="container-aboutus"
      borderRight="solid 2px"
      borderColor="#000000"
    >
      <Box
        width="100%"
        height="100%"
        sx={{ display: { sm: "block", md: "flex" } }}
        justifyContent="center"
        alignItems="center"
        position="relative"
        zIndex={2}
        className="bg-about"
      >
        <img
          className="img-about"
          src={require("../../../assets/img/new_ill_aboutus.png")}
          alt="illustration"
        />
        <div
          style={{
            color: "#ffffff",
            position: "absolute",
            top: "5%",
            right: "20%",
            width: "60%",
            textAlign:"center"
          }}
        >
          <p style={{ fontSize: "18px", fontWeight:"bold" }}>
            A hub for degens, creators & collectors 
          </p>
          <p style={{ fontSize: "15px" }}>
             Taking you closer to the future !fly
          </p>
        </div>
        <Box
          onClick= {handleOpenModal}
          class="overlay-aboutus"
          style={{ cursor: "pointer" }}
        >
          <div class="text" style={{ fontWeight: "bold", zIndex: 2 }}>
            About Us
          </div>{" "}
        </Box>
      </Box>
      <Box className={`content-hide ${open ? "hide-content" : ""}`}>
        <ModalAbout />
        <Box className="close">
          <Button onClick= {handleCloseModal}>
            <CloseIcon sx={{ color: "white", fontSize: 40 }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default About2;
