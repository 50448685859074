import { Backdrop, Box, CircularProgress, Divider, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { Loading } from "../../../components";
import BtnIjo from "../../../components/buttons/BtnIjo";
import { strapiImg } from "../../../helper/strapi";
import useBalance from "../../../hooks/useBalance";
import useConfigStaking from "../../../hooks/useConfigStaking";
import BadgeTypes from "./BadgeTypes";
import Countdown from "./Countdown";
import SocialMediaIcon from "./SocialMediaIcon";

const BoxContainer = ({ children, sm = 4, display = "flex" }) => {
  return (
    <Grid sm={sm} justifyContent="center" display={display}>
      {children}
    </Grid>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#BEBAB1",
  p: 4,
};

function ModalPurchase({
  open,
  onClose,
  item,
  onPurchase,
  purchasing,
  purchaseMsg,
  onTicketChange,
  shop
}) {
  const [isPurchase, setIsPurchase] = React.useState(false);
  const purchase = () => setIsPurchase(true);
  const balance = useBalance();

  const isInsufficient = shop?.fuzzBalance < item?.attributes.price;

  // useEffect(() => {
  //   if (open) {
  //     setIsPurchase(false)
  //   }
  // },[open])

  const isRaffle =
    item?.attributes.sale_types?.data?.filter((item) => item.attributes.name === "Raffles").length >
    0;

  let box = (
    <Grid
      sm={
        (isPurchase === false && purchaseMsg !== "") ||
        isInsufficient ||
        item?.orders.length > 0 ||
        isRaffle
          ? 6
          : 4
      }
      justifyContent="center"
      display={isPurchase === false || purchasing ? "flex" : undefined}
    >
      {item?.orders.length > 0 ? (
        <Typography sx={{}} variant="h6" align="center" color="#000000" fontWeight={"bold"} mb={1}>
          Purchase Successfully
        </Typography>
      ) : isInsufficient ? (
        <Typography sx={{}} variant="h6" align="center" color="#000000" fontWeight={"bold"} mb={1}>
          Insufficient Balance
        </Typography>
      ) : isPurchase === false ? (
        purchaseMsg !== "" ? (
          <Typography
            sx={{}}
            variant="h6"
            align="center"
            color="#000000"
            fontWeight={"bold"}
            mb={1}
          >
            {purchaseMsg}
          </Typography>
        ) : isRaffle ? (
          <Box>
            <Typography
              sx={{}}
              variant="subtitle2"
              align="center"
              color="#000000"
              fontWeight={"bold"}
              mb={1}
            >
              How many tickets do you want to buy:
            </Typography>
            <div style={{ textAlign: "center" }}>
              <input
                type={"number"}
                min={0}
                style={{ padding: 5, marginBottom: 5 }}
                onChange={onTicketChange}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <BtnIjo label="Purchase" onPress={purchase} />
            </div>
          </Box>
        ) : (
          <BtnIjo label="Purchase" onPress={purchase} />
        )
      ) : purchasing ? (
        <CircularProgress sx={{ color: "#000000" }} />
      ) : (
        <>
          <Typography
            sx={{}}
            variant="subtitle2"
            align="center"
            color="#000000"
            fontWeight={"bold"}
            mb={1}
          >
            Are you sure
          </Typography>

          <Grid container columns={12} columnSpacing={1} justifyContent={"center"}>
            <Grid sm={4}>
              <BtnIjo label={"No"} outlined onPress={() => setIsPurchase(false)} />
            </Grid>
            <Grid sm={4}>
              <BtnIjo label={"Yes"} onPress={() => onPurchase(() => setIsPurchase(false))} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
  if (purchasing) {
    box = (
      <BoxContainer>
        <CircularProgress sx={{ color: "#000000" }} />
      </BoxContainer>
    );
  } else if (purchaseMsg !== "") {
    box = (
      <BoxContainer sm={6}>
        <Typography sx={{}} variant="h6" align="center" color="#000000" fontWeight={"bold"} mb={1}>
          {purchaseMsg}
        </Typography>
      </BoxContainer>
    );
  } else if (item?.orders.length > 0) {
    box = (
      <BoxContainer sm={6}>
        <Typography sx={{}} variant="h6" align="center" color="#000000" fontWeight={"bold"} mb={1}>
          Purchase Successfully
        </Typography>
      </BoxContainer>
    );
  } else if (isInsufficient) {
    box = (
      <BoxContainer sm={6}>
        <Typography sx={{}} variant="h6" align="center" color="#000000" fontWeight={"bold"} mb={1}>
          Insufficient Balance
        </Typography>
      </BoxContainer>
    );
  } else if (isPurchase) {
    box = (
      <BoxContainer display={"block"}>
        <Typography
          sx={{}}
          variant="subtitle2"
          align="center"
          color="#000000"
          fontWeight={"bold"}
          mb={1}
        >
          Are you sure
        </Typography>

        <Grid container columns={12} columnSpacing={1} justifyContent={"center"}>
          <Grid sm={4}>
            <BtnIjo label={"No"} outlined onPress={() => setIsPurchase(false)} />
          </Grid>
          <Grid sm={4}>
            <BtnIjo label={"Yes"} onPress={() => onPurchase(() => setIsPurchase(false))} />
          </Grid>
        </Grid>
      </BoxContainer>
    );
  } else if (isRaffle) {
    box = (
      <BoxContainer sm={6}>
        <Box>
          <Typography
            sx={{}}
            variant="subtitle2"
            align="center"
            color="#000000"
            fontWeight={"bold"}
            mb={1}
          >
            How many tickets do you want to buy:
          </Typography>
          <div style={{ textAlign: "center" }}>
            <input
              type={"number"}
              min={0}
              style={{ padding: 5, marginBottom: 5 }}
              onChange={onTicketChange}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <BtnIjo label="Purchase" onPress={purchase} />
          </div>
        </Box>
      </BoxContainer>
    );
  } else {
    box = (
      <BoxContainer>
        <BtnIjo label="Purchase" onPress={purchase} />
      </BoxContainer>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
    >
      <Box sx={{ ...style }}>
        <Grid container columns={12} columnSpacing={2}>
          <Grid item sm={4}>
            <ReactImageFallback
              src={item?.attributes.image_url}
              // src={item?.attributes.image_url || strapiImg(item?.attributes?.image.data.attributes.url)}
              fallbackImage={"https://picsum.photos/300?random=" + item?.id}
              initialImage="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
              alt={item?.attributes.title}
              className="my-image"
            />
          </Grid>
          <Grid item sm={8} color="#000000">
            <Typography id="itemName" variant="h6" fontWeight={"bold"}>
              {item?.attributes?.title}
            </Typography>
            <Typography id="itemSupply" sx={{ mt: 1 }} variant="subtitle2">
              {item?.attributes?.supply} Spot
            </Typography>
            <Typography id="itemSupply" sx={{ mt: 3 }} variant="subtitle2">
              {item?.attributes?.short_description}
            </Typography>

            <Grid
              container
              columns={12}
              columnSpacing={2}
              justifyContent={"space-between"}
              sx={{ mt: 3 }}
            >
              <Grid item sm={9}>
                {item !== null ? (
                  <BadgeTypes
                    types={{
                      ...item.attributes?.item_types,
                      data: [
                        ...item.attributes?.item_types.data,
                        ...item.attributes?.sale_types.data,
                      ],
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item sm={3}>
                <SocialMediaIcon item={item} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider color="#000000" sx={{ my: 2 }} />

        <Grid container columns={12} color="#000000" columnSpacing={2} justifyContent={"space-between"}>
          <Grid sm={4} pl={2}>
            {isRaffle ? (
              <Countdown date={item?.attributes.end_date} color="black" inModalPurchase showEnded />
            ) : null}
          </Grid>
          <Grid sm={4}>
            <Typography id="itemPriceLabel" sx={{}} variant="subtitle2" align="center">
              Price
            </Typography>
            <Typography id="itemPrice" sx={{}} variant="h6" align="center" fontWeight={"bold"}>
              {item?.attributes?.price} $FUZZ
            </Typography>
          </Grid>
          <Grid sm={4}>
            <Typography id="itemPriceLabel" sx={{}} variant="subtitle2" align="center">
              Your Balance
            </Typography>
            <Typography id="itemPrice" sx={{}} variant="h6" align="center">
              {shop?.fuzzBalance} $FUZZ
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ borderWidth: 2, borderColor: "#000000", borderStyle: "solid" }} mt={2}>
          <Grid container columns={12} columnSpacing={2} justifyContent={"center"} py={5}>
            {box}
          </Grid>
        </Box>

        {isRaffle ? (
          <div style={{ marginTop: 5, fontSize: 12 }}>
            Ticket sold: {item?.ticketSold} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your tickets:{" "}
            {item?.yourTicket}
          </div>
        ) : null}
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  discord: state.discord,
  shop: state.shop,
});

export default connect(mapStateToProps)(ModalPurchase);