import { Box, Container, Grid, Typography, Divider, Stack, getOffsetLeft } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Main } from "../../components";
import { setStaking, setUnStaking, setWithdrawRewards } from "../../redux/actions/setupAction";
import FormGroup from "@mui/material/FormGroup";
import CheckBox from "../../components/forms/Checkbox";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getDailyReward, getFuzzBalance } from "../../redux/actions/shopAction";
import { styled } from "@mui/material/styles";
import axios from "axios";

const ProfileToggle2 = (props) => {
  const [ID, setID] = useState(0);
  const _stakedGrid = true;

  const [value, setValue] = useState("");
  const [oldData, setOldData] = useState([]);

  const [isToggleOn, setIsToggleOn] = useState(false);

  const listStake = props.listStake.data;
  const listUnstake = props.listUnstake.data;

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      maxWidth: 200,
    },
  }));

  const mergedStake = [...listUnstake, ...listStake];

  const [getImg, setGetImg] = useState("");
  const [finalData, setFinalData] = useState([]);

  const handleFilter = (id) => {
    setID(id);
    setValue(id);
    console.log(id);
  };

  useEffect(() => {
    mergedStake.length > 0 && handleFilter(mergedStake[0].token_id);
    let oldDataTemp = [];
    const getMetaDataOld = async (token_id) => {
      const url = `https://fuzzyfighters.mypinata.cloud/ipfs/QmVsaaHS561T69usB5JBsZxHAUwKyL5vdS9tnpczStBbTp/${token_id}`;
      return axios
        .get(url, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            return { data: res.data };
          } else {
            return { data: [], error: true };
          }
        })
        .catch((_) => {
          return { data: [], error: true };
        });
    };
    var getListMeta =
      mergedStake.length > 0
        ? mergedStake.map(async (resTkn, i) => {
            await getMetaDataOld(resTkn.token_id, i)
              .then((resMeta) => {
                const nMeta = { ...resTkn, metadata: resMeta.data };
                nMeta.length === mergedStake.length && setOldData(oldDataTemp);
                oldDataTemp.push(nMeta);
                return oldDataTemp.length === mergedStake.length && setOldData(oldDataTemp);
              })
              .catch((_) => {
                const nMeta = { ...resTkn, metadata: null };
                console.log(213121, nMeta);
                return oldDataTemp.push(nMeta);
              });
          })
        : [];

    oldDataTemp.length > 0 && setOldData(oldDataTemp);
  }, []);

  const handleChange = (event, id, data, final) => {
    setIsToggleOn(!isToggleOn);
    console.log(isToggleOn);

    data =
      isToggleOn === false && value === ID
        ? mergedStake
            .filter((x) => x?.token_id === ID)
            .map((item) => {
              return item?.metadata?.image;
            })
        : oldData
            .filter((x) => x?.token_id === ID)
            .map((item) => {
              return item?.metadata?.image;
            });
    setGetImg(data);

    if (isToggleOn === true) {
      const data1 = mergedStake
        .filter((x) => x?.token_id === ID)
        .map((y) => {
          const newImg = mergedStake.find((el) => el?.token_id === ID);

          return {
            ...y?.metadata,
            image: newImg?.metadata?.image,
          };
        });
      setFinalData(data1);
    } else {
      final = mergedStake
        .filter((x) => x?.token_id === ID)
        .map((item) =>
          item.token_id === ID ? { ...item?.metadata, image: item?.metadata?.image } : item.image
        );
      setFinalData(final);
    }
    console.log(finalData);
  };

  const getImgFuzzy =
    oldData.length > 0 &&
    oldData
      .filter((x) => x.token_id === ID)
      .map((item) => {
        const img = item?.metadata ? item?.metadata?.image : "-";
        return (
          <img
            src={
              item?.metadata?.image
                ? `https://fuzzyfighters.mypinata.cloud/ipfs/${img.replace("ipfs://", "")}`
                : require("../../assets/img/web/img-default.png")
            }
            alt=""
          />
        );
      });

  const getImgAstro = mergedStake
    .filter((x) => x.token_id === ID)
    .map((item) => {
      const img = item?.metadata ? item?.metadata?.image : "-";
      return (
        <img
          src={
            item?.metadata?.image
              ? `https://fuzzyfighters.mypinata.cloud/ipfs/${img.replace("ipfs://", "")}`
              : require("../../assets/img/web/img-default.png")
          }
          alt=""
        />
      );
    });

  return (
    <Main passthis={this}>
      <Box
        component="section"
        backgroundColor="#000000"
        display="flex"
        padding="8% 0"
        id="fuzzShop__"
      >
        <Container>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={{ xs: 5, md: 5 }}
            columns={12}
            justifyContent="center"
          >
            <Typography
              color={"white"}
              py={1}
              fontSize={30}
              fontWeight="bold"
              paddingBottom={5}
              paddingTop={2}
            >
              Profile
            </Typography>
          </Grid>

          <Grid container rowSpacing={6} columnSpacing={{ xs: 5, md: 5 }} columns={12}>
            <Grid item xs={4} md={3}>
              <Box pb={1} style={{ backgroundColor: "#BEBAB1" }}>
                <Box style={{ backgroundColor: "#CD7555" }}>
                  <Typography
                    color={"black"}
                    fontWeight="bold"
                    textAlign="center"
                    py={1}
                    fontSize={18}
                  >
                    Your Fuzzy NFT
                  </Typography>
                </Box>

                <Divider style={{ color: "#BEBAB1", backgroundColor: "#BEBAB1" }} />
                <Box className="default" mx={2} mt={1}>
                  <Box mb={2}>
                    <FormGroup style={{ color: "black" }}>
                      <Box component="ul" className={`list-stack ${_stakedGrid ? "" : "_list"}`}>
                        {mergedStake.length > 0 ? (
                          mergedStake.map((x, index) => {
                            const name = x?.metadata ? x?.metadata?.name : `Fuzz-${x?.token_id}`;
                            return (
                              <Box component="li" key={index}>
                                <RadioGroup
                                  key={index}
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={value}
                                  onChange={() => handleFilter(x.token_id)}
                                >
                                  <FormControlLabel
                                    key={index}
                                    value={x.token_id}
                                    control={
                                      <Radio
                                        sx={{
                                          color: "#000000",
                                          "&.Mui-checked": {
                                            color: "#000000",
                                          },
                                        }}
                                      />
                                    }
                                    label={name}
                                  />
                                </RadioGroup>
                              </Box>
                            );
                          })
                        ) : (
                          <Typography
                            color={"black"}
                            fontWeight="bold"
                            textAlign="center"
                            py={1}
                            fontSize={15}
                          >
                            Empty
                          </Typography>
                        )}
                      </Box>
                    </FormGroup>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box style={{ backgroundColor: "#BEBAB1" }}>
                <Box style={{ backgroundColor: "#CD7555" }}>
                  {mergedStake
                    .filter((x) => x.token_id === ID)
                    .map((item) => {
                      const name = item?.metadata ? item?.metadata?.name : `Fuzz-${item?.token_id}`;

                      return (
                        <Box>
                          <Typography
                            key={item.token_id}
                            color={"black"}
                            fontWeight="bold"
                            textAlign="center"
                            py={1}
                            fontSize={18}
                          >
                            {name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>

                <Divider style={{ color: "#BEBAB1", backgroundColor: "#BEBAB1" }} />
                <Box>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    {isToggleOn ? (
                      <Box>
                        <div
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            textAlign: "center",
                            margin: "1rem 0",
                          }}
                        >
                          Season 2: Astro Fuzz
                        </div>

                        <Box
                          mb={8}
                          paddingLeft={2}
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "center",
                          }}
                        >
                          {mergedStake
                            .filter((x) => x.token_id === ID)
                            .map((item) => {
                              return (
                                <Box component="span" sx={{ p: 1 }} key={item.token_id}>
                                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                                    <div
                                      style={{
                                        color: "#000",
                                        fontWeight: "bold",
                                        width: "120px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Season 1: Fuzzy Fighers
                                    </div>

                                    <ArrowBackIosOutlinedIcon
                                      id={item.token_id}
                                      key={item.token_id}
                                      value={item.token_id}
                                      onClick={(e) => handleChange(e, item)}
                                      inputProps={{ "aria-label": "controlled" }}
                                      style={{ cursor: "pointer" }}
                                      sx={{
                                        color: "#000",
                                        fontSize: 40,
                                        paddingTop: "120px",
                                        paddingRight: "30px",
                                      }}
                                    />
                                  </div>
                                </Box>
                              );
                            })}
                          <Box
                            sx={{
                              width: 300,
                              height: 300,
                            }}
                          >
                            {getImgAstro}
                          </Box>
                          <Button disabled sx={{ width: "206px" }}>
                            <ArrowForwardIosOutlinedIcon
                              sx={{ color: "##92999e", fontSize: 40, paddingLeft: "30px" }}
                            />
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <div
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            textAlign: "center",
                            margin: "1rem 0",
                          }}
                        >
                          Season 1: Fuzzy Fighters
                        </div>

                        <Box
                          mb={8}
                          paddingLeft={2}
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "center",
                          }}
                        >
                          <Button disabled sx={{ width: "206px" }}>
                            <ArrowBackIosOutlinedIcon
                              sx={{ color: "#92999e", fontSize: 40, paddingRight: "30px" }}
                            />
                          </Button>
                          <Box
                            sx={{
                              width: 300,
                              height: 300,
                            }}
                          >
                            {oldData.length > 0 && getImgFuzzy}
                          </Box>
                          {mergedStake
                            .filter((x) => x.token_id === ID)
                            .map((item) => {
                              return (
                                <Box component="span" sx={{ p: 1 }} key={item.token_id}>
                                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                                    <ArrowForwardIosOutlinedIcon
                                      id={item.token_id}
                                      key={item.token_id}
                                      value={item.token_id}
                                      onClick={(e) => handleChange(e, item)}
                                      inputProps={{ "aria-label": "controlled" }}
                                      style={{ cursor: "pointer" }}
                                      sx={{
                                        color: "#000",
                                        fontSize: 40,
                                        paddingTop: "120px",
                                        paddingLeft: "30px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        color: "#000",
                                        fontWeight: "bold",
                                        width: "120px",
                                      }}
                                    >
                                      <p>Season 2: AstroFuzz</p>
                                    </div>
                                  </div>
                                </Box>
                              );
                            })}{" "}
                        </Box>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

// const mapStateToProps = (state) => ({
//   listStake: state.stake.stake,
//   wallet: state.wallet,
// });

// const mapDispatchToProps = {
//   actionsetStaking: setStaking,
// };

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  listUnstake: state.stake.unstake,
  listStake: state.stake.stake,
  wallet: state.wallet,
  shop: state.shop,
});

const mapDispatchToProps = {
  actionsetStaking: setStaking,
  actionsetUnStaking: setUnStaking,
  actionsetWithdrawRewards: setWithdrawRewards,
  actionsetDailyReward: getDailyReward,
  actionsetFuzzBalance: getFuzzBalance,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileToggle2);
