import { combineReducers } from "redux";
// import transaction from './transactionReducer';
import wallet from "./walletReducer";
// import mint from './mintReducer';
import network from "./networkReducer";
import alert from "./alertReducer";
import unstake from "./unstakeReducer";
import configStaking from "./configStakingReducer";
import stake from "./stakeReducer";
import shopReducer from "./shopReducer";
import discordReducer from "./discordReducer";

const appReducer = combineReducers({
  // transaction,
  wallet,
  // mint,
  network,
  alert,
  stake: combineReducers({
    config: configStaking,
    unstake: unstake,
    stake: stake,
  }),
  shop: shopReducer,
  discord: discordReducer,
});

const rootReducer = (state, action) => {
  // console.log(333, action.type);
  if (action.type === "LOGOUT_SUCCESS") {
    return appReducer({shop: state.shop}, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
