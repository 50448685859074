import { LanguageOutlined, Twitter } from "@mui/icons-material";
import { Avatar, Grid } from "@mui/material";
import React from "react";
import { IMG_DISCORD } from "../../../data/images";
import ButtonBase from "@mui/material/ButtonBase";

const SocialMediaIcon = ({ item }) => {
  const to = (link) => {
    if (link) window.open(link, true);
  };

  return (
    <Grid justifyContent={"space-between"} container columnGap={0.2}>
      <ButtonBase onClick={() => to(item?.attributes.twitter)}>
        <Avatar sx={{ bgcolor: "#CD7555", width: 20, height: 20, cursor: "pointer" }}>
          <Twitter sx={{ color: "white", fontSize: 12 }} />
        </Avatar>
      </ButtonBase>
      <ButtonBase onClick={() => to(item?.attributes.discord)}>
        <Avatar sx={{ bgcolor: "#CD7555", width: 20, height: 20, cursor: "pointer" }}>
          <img src={IMG_DISCORD} alt="discord" style={{ width: 13, height: 13 }} />
        </Avatar>
      </ButtonBase>
      <ButtonBase onClick={() => to(item?.attributes.web)}>
        <Avatar
          sx={{
            bgcolor: "#CD7555",
            width: 20,
            height: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <LanguageOutlined sx={{ color: "white", fontSize: 12 }} />
        </Avatar>
      </ButtonBase>
    </Grid>
  );
};

export default SocialMediaIcon;
