import React, {Component} from 'react'
import { Loading, Main } from '../../components';
import { Box, Typography, Container, Button } from '@mui/material';
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {  setChangeStatusContract, setConnectWallet, setInit } from '../../redux/actions/setupAction'
import ProfileToggle2 from './ProfileToggle2';
import EnableContract from '../Stake/EnableContract';
import { REACT_APP_CONTRACT_STAKING_ADDRESS, REACT_APP_CONTRACT_STAKING_ADDRESS_OLD } from '../../redux/actions/contractAction';


export class Profile extends Component{
  constructor(props) {
    super(props);
    this.state = {
      _loading: true,
      _loadingSubmit: false,
    };
  }
  componentDidMount() {
    const isStakingOld = window.location.href.includes("stakev1");
    if (isStakingOld) {
      window.localStorage.setItem("stakingAddress", REACT_APP_CONTRACT_STAKING_ADDRESS_OLD);
      window.localStorage.setItem("stakingAddressVersion", "old");
    } else {
      window.localStorage.setItem("stakingAddress", REACT_APP_CONTRACT_STAKING_ADDRESS);
      window.localStorage.setItem("stakingAddressVersion", "new");
    }

    setTimeout(() => {
      const { actionsetInit } = this.props;
      return actionsetInit()
        .then((_) => {
          return this.setState({ _loading: false });
        })
        .catch((_) => {
          return this.setState({ _loading: false });
        });
    }, 1000);
  }

  render() {
    const { wallet, configStaking } = this.props;
    const { _loading, _loadingSubmit } = this.state;
    if (_loading) {
      return <Loading />;
    } else {
      if (wallet.isConnect) {
        return <ProfileToggle2 passThis={this} />;
        
      } else {
        return (
          <Main passThis={this} passData={this.state}>
            <Box component="section" className="introduceStack eContract" id="introduce__">
              {/* <Box component="section" className='introduceStack eContract' id="introduce__"> */}
              <Container>
                <Box className="introduceStackOut">
                

                  <Box className="btn_enable">
                    <Box
                      component="span"
                      onClick={() => this.connectWallet()}
                      sx={{
                        p: ".5rem 1rem",
                        background: "#BEBAB1",
                        width: "20%",
                        height:"20%",
                        border: "2px solid #000",
                        borderBottom: "5px solid #000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: ".5rem",
                        cursor: "pointer",
                      }}
                    >
                      <div style={{ fontWeight: "bold", color: "#000000" }}>Connect Wallet</div>
                      {/* <img src={require('../../assets/img/web/btn-enable-connect.png')} alt="button connect" /> */}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Main>
        );
      }
    }
  }

  connectWallet = () => {
    const { actionsetConnectWallet } = this.props;
    return this.setState({ _loading: true }, () => {
      return actionsetConnectWallet({ reconnect: false, wallet: null })
        .then((_) => {
          return this.setState({ _loading: false });
        })
        .catch((_) => {
          return this.setState({ _loading: false });
        });
    });
  };

  onChangeStatusContract = (status) => {
    const { actionsetChangeStatusContract } = this.props;
    return this.setState({ _loadingSubmit: true }, () => {
      return actionsetChangeStatusContract(status)
        .then((_) => {
          return this.setState({ _loadingSubmit: false });
        })
        .catch((_) => {
          return this.setState({ _loadingSubmit: false });
        });
    });
  };
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  listUnstake: state.stake.unstake,
  listStake: state.stake.stake,
  wallet: state.wallet,
});

const mapDispatchToProps = {
  actionsetChangeStatusContract: setChangeStatusContract,
  actionsetConnectWallet: setConnectWallet,
  actionsetInit: setInit,
};

  export default connect(mapStateToProps, mapDispatchToProps)(Profile);
