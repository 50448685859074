import React, { Component } from "react";
import {
  Box,
  Container,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default class StakingFuzz extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Box component="section" className="utility bg-garis-x" id="utility__">
          <Container sx={{ px: { xs: 2, sm: 2, md: 2 } }}>
            <Box className="title">
              <Box className="ic">
                <img
                  src={require("../../../assets/img/web/ic-utility-left.png")}
                  alt="utility left"
                />
              </Box>
              <Box className="txt">Staking for $FUZZ Token</Box>
              <Box className="ic">
                <img
                  src={require("../../../assets/img/web/ic-utility-right.png")}
                  alt="utility right"
                />
              </Box>
            </Box>
            <Box justifyContent={"center"} display={"flex"}>
              <img
                className="foxpandaimg"
                src={require("../../../assets/img/web/v5/foxpanda.png")}
                alt="pandaFox"
              />
            </Box>
            <Box className="desc" sx={{ mt: 6 }}>
              $FUZZ Tokens will be the currency of our Fuzzy Fighter ecosystem
            </Box>
            <Box justifyContent={"center"} display={"flex"} sx={{ pb: 8 }}>
              <Button
                target={"_blank"}
                href="https://medium.com/@fuzzyfighters/fuzzy-fighters-staking-explained-efea81870b66"
                sx={{
                  backgroundColor: "#414141",
                  color: "white",
                  borderRadius: 0,
                  textTransform: "none",
                  padding: "10px 20px",
                }}
              >
                Read all about it here
              </Button>
            </Box>
          </Container>

          {/* <Box className="creator_space">
            <Container>
              <Box justifyContent={"center"} display={"flex"}>
                <img
                  className="foxpandaimg"
                  src={require("../../../assets/img/web/v5/foxpanda.png")}
                  alt="pandaFox"
                />
              </Box>
            </Container>
          </Box> */}
        </Box>
      </>
    );
  }
}
