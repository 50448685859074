import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Divider,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  NativeSelect,
  styled,
  TextField,
} from "@mui/material";
import {
  getItemTypes,
  getProducts,
  getSaleTypes,
  getTypes,
  setItemTypes,
  setSalesTypes,
  setSortBy,
  setType,
  getFuzzBalance,
  createUserBalance,
  updateBalance,
} from "./../../../redux/actions/shopAction";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";
import { ArrowDropDown, ArrowLeft, ArrowRight, Image } from "@mui/icons-material";
import clan from "../../../data/clan";
import CardItem from "./CardItem";
import { IMG_DISCORD, IMG_FUZZ_SHOP } from "../../../data/images";
import BtnOrangeShadow from "../../../components/buttons/BtnOrangeShadow";
import CheckBox from "../../../components/forms/Checkbox";
import RadioGroupCustom from "../../../components/forms/RadioGroupCustom";
import {
  DISCORD_CLIENT_ID,
  DISCORD_REDIRECT_URI,
  LOCAL_STORAGE_DISCORD_CODE,
} from "../../../config";
import {
  getMeFromCms,
  getToken,
  restoreDiscordUser,
  setDiscordCode,
} from "../../../redux/actions/discordAction";
import { restoreUserWalletAddress, setConfigStaking } from "../../../redux/actions/setupAction";
import BtnDropDown from "../../../components/buttons/BtnDropDown";
import "./Shopping.css";
import ModalToken from "./ModalToken";
import axios from "axios";
import { Alchemy, Network } from "alchemy-sdk";
import { ethers } from "ethers";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

function HeaderFilter({ title }) {
  return (
    <>
      <Grid justifyContent={"space-between"} container alignItems={"center"}>
        <Grid item>
          <Typography color={"#000000"} textAlign="left" py={1}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <ArrowDropDown sx={{ color: "#000000" }} />
        </Grid>
      </Grid>
      <Divider style={{ color: "#000000", backgroundColor: "#000000" }} />
    </>
  );
}

const RECENT_LISTING = "RECENT_LISTING";
const PRICE_HIGH_TO_LOW = "PRICE_HIGH_TO_LOW";
const PRICE_LOW_TO_HIGH = "PRICE_LOW_TO_HIGH";
const settings = {
  apiKey: "-g7YWZzuweY2ZdcgOTDbk7PQnKngraIB",
  network: process.env.REACT_APP_ENVIRONMENT === "development" ? Network.ETH_GOERLI : Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);

export class Shopping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _sortBy: RECENT_LISTING,
      typeSelected: [],
      activePage: 1,
      _openPurchaseToken: false,
      fuzzBalance: {},
      loading: false,
      message: "",
    };
  }

  loadingBox = () => (
    <Box className="loadingBox">
      <CircularProgress sx={{ color: "#516946" }} />
    </Box>
  );

  onChangeSortBy = async (e) => {
    this.setState({
      _sortBy: e.target.value,
    });
    let sort = {};
    if (e.target.value === RECENT_LISTING) {
      sort = {
        sortBy: "id",
        sortMethod: "desc",
      };
    } else if (e.target.value === PRICE_HIGH_TO_LOW) {
      sort = {
        sortBy: "price",
        sortMethod: "desc",
      };
    } else if (e.target.value === PRICE_LOW_TO_HIGH) {
      sort = {
        sortBy: "price",
        sortMethod: "asc",
      };
    }
    await this.props.actionSortBy(sort);
    this.setState(
      {
        activePage: 1,
      },
      () => {
        this.executeFilter();
      }
    );
  };

  connectToDiscord = () => {
    if (this.props.discord.code) {
      window.localStorage.removeItem(LOCAL_STORAGE_DISCORD_CODE);
      this.props.actionSetDiscordCode(null);
    } else {
      window.location.href =
        "https://discord.com/api/oauth2/authorize?client_id=" +
        DISCORD_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(DISCORD_REDIRECT_URI) +
        "&response_type=code&scope=identify";
    }
  };

  yourBalance = () => {
    // alert("your balance");
  };

  purchase = (item) => {
    if (this.props.discord.code) {
      this.props.passThis.purchase(item);
    } else {
      alert("You must login discord first");
    }
  };

  executeFilter = () => {
    const filters = {
      item_types: this.props.shop.filterItemTypes,
      sale_types: this.props.shop.filterSaleTypes,
    };
    let type = null;
    if (this.props.shop.filterType) {
      const typeLabel = this.props.shop.types.find((item) => item.id == this.props.shop.filterType)
        ?.attributes.name;
      type = typeLabel;
      // alert(typeLabel);
      // if (typeLabel === "Active Only") {
      //   filters["is_sold_out"] = false;
      // } else if (typeLabel === "Past Project") {
      //   filters["is_sold_out"] = true;
      // }
      // filters["type"] = this.props.shop.filterType;
    }
    let sort = [];
    if (this.props.shop.sortBy) {
      sort = [this.props.shop.sortBy];
      if (this.props.shop.sortMethod) {
        sort = [this.props.shop.sortBy + ":" + this.props.shop.sortMethod];
      }
    }
    const params = {
      filters,
      sort,
      discord_id: this.props.discord?.user.id,
      "pagination[page]": this.state.activePage,
    };
    // if (type) {
    // params["type"] = type;
    // }
    if (this.state.typeSelected.length === 1) {
      params["type"] = this.state.typeSelected[0] === "1" ? "Active Only" : "Past Project";
    }
    this.props.actionGetProducts(params);
  };

  onClosePurchaseToken = () => {
    this.setState({ _openPurchaseToken: false });
    this.setState({ message: "" });
  };

  onOpenPurchaseToken = () => {
    this.setState({ _openPurchaseToken: true });
  };

  onChangeSaleType = async (e) => {
    await this.props.actionSetSaleTypes(e.target.value);
    this.executeFilter();
  };

  onChangeItemType = async (e) => {
    await this.props.actionSetItemTypes(e.target.value);
    this.executeFilter();
  };

  onChangeType = async (e) => {
    // await this.props.actionSetType(e.target.value);
    const value = e.target.value;
    const a = [...this.state.typeSelected];
    if (a.includes(value)) a.splice(a.indexOf(value), 1);
    else a.push(value);
    this.setState(
      {
        typeSelected: a,
      },
      () => {
        this.executeFilter();
      }
    );
  };

  toNextPage = () => {
    const page = this.props.shop?.meta?.pagination?.page + 1;
    this.setState(
      {
        activePage: page,
      },
      () => {
        this.executeFilter();
      }
    );
  };

  toPreviousPage = () => {
    const page = this.props.shop?.meta?.pagination?.page - 1;
    this.setState(
      {
        activePage: page,
      },
      () => {
        this.executeFilter();
      }
    );
  };

  sendTransaction = async (value, amount) => {
    // const web3 = new Web3("https://goerli.infura.io/v3/87dd647df0934ab2b57238d2fb436bb2");

    // var value = web3.utils.toWei('0.001', 'ether');
    const account = await window.ethereum.request({
      method: "eth_accounts",
    });

    this.setState({ loading: true });
    this.setState({ message: "Transaction is being processed, please do not close this modal " });

    const params = [
      {
        from: account[0],
        to: process.env.REACT_APP_FUZZY_WALLET,
        // gasPrice: Number(25000000).toString(16),
        // gas: Number(210000).toString(16),
        value: value,
        // value : Number(value).toString(16),
      },
    ];

    let result = await window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        this.setState({ message: "Transaction failed" });
      });
    console.log("Transaction Hash is : ", result);
    alchemy.core.getTransaction(result).then((res) => {
      console.log(res);
      setTimeout(() => {
        if (res.from === localStorage.getItem("LOCAL_STORAGE_USER_WALLET_ADDRESS") && res.to === process.env.REACT_APP_FUZZY_WALLET && res.value._hex === `0x${value}`) {
          this.props.actionUpdateBalance({ wallet_address: res.from, amount: Number(amount) });
          this.setState({ loading: false });
          this.setState({ message: "Transaction success" });
        } else {
          this.setState({ loading: false });
          this.setState({ message: "Transaction failed" });
          console.log(value);
          }
      }, 60000);
    });
  };

  componentDidMount() {
    // console.log(this.props.wallet?.address);
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    // console.log("params", params);
    const discordCode = window.localStorage.getItem(LOCAL_STORAGE_DISCORD_CODE);
    const wallet_address = localStorage.getItem("LOCAL_STORAGE_USER_WALLET_ADDRESS");
    if (discordCode) {
      this.props.actionSetDiscordCode(discordCode);
      this.props.actionRestoreDiscordUser().then(() => {
        this.props.actionGetProducts({
          "pagination[page]": 1,
          discord_id: this.props.discord.user?.id,
          sort: "id:desc",
        });
      });
    } else if (params?.code) {
      window.localStorage.setItem(LOCAL_STORAGE_DISCORD_CODE, params.code);
      this.props.actionSetDiscordCode(params.code);

      // this.props.actionGetDiscordToken(params.code);
      this.props.actionGetDiscordMe(params.code).then((response) => {
        console.log(response);
      });
    } else {
      this.props.actionGetProducts({ "pagination[page]": 1, sort: "id:desc" });
    }

    // get user balance from db
    if (wallet_address) {
      this.props.actionGetFuzzBalance({ wallet_address });
    }
    // console.log("ShoppingcomponentDidMount", this.props);
    this.props.actionGetItemTypes();
    this.props.actionGetSaleTypes();
    this.props.actionGetTypes();

    this.props.actionRestoreUserWalletAddress().then(() => {
      // alert(this.props.wallet.address)
      if (!this.props.wallet.address) {
        // alert('Must login wallet first')
        // window.location.href = '/'
        // alert("Must login wallet first");
      } else {
        // console.log("wallet address", this.props.wallet.address);
        // this.props.actionSetConfigStaking(true, this.props.wallet.address)
      }
    });
  }

  render() {
    const { configStaking } = this.props;
    const {} = this.state;
    return (
      <Box
        component="section"
        backgroundColor="#000000"
        display="flex"
        padding="8% 0"
        id="fuzzShop__"
      >
        {/* <Box component="section" className="introduceStack" id="fuzzShop__"> */}
        <Container>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={{ xs: 5, md: 5 }}
            columns={12}
            justifyContent="center"
          >
            <h1 style={{ color: "#ffffff", textAlign: "center", paddingBottom: "3rem", margin: 0 }}>
              Fuzz Shop
            </h1>
          </Grid>
          <Grid container rowSpacing={6} columnSpacing={{ xs: 5, md: 5 }} columns={12}>
            <Grid item xs={12} sm={12} md={3}>
              <Box style={{ backgroundColor: "#BEBAB1" }} pb={5}>
                <Box sx={{ backgroundColor: "#CD7555" }}>
                  <h3 style={{ color: "#000000", textAlign: "center", padding: "1rem", margin: 0 }}>
                    Filter
                  </h3>
                </Box>

                <Box className="default" mx={2} mt={1}>
                  <Box mb={2}>
                    <HeaderFilter title={"Sale Type"} />
                    <FormGroup style={{ color: "#000000" }}>
                      {this.props.shop.saleTypes.map((item) => (
                        <CheckBox
                          sx={{
                            color: "#000000",
                            "&.Mui-checked": {
                              color: "000000",
                            },
                          }}
                          key={item.id}
                          label={item.attributes.name}
                          onChange={this.onChangeSaleType}
                          value={item.id}
                        />
                      ))}
                    </FormGroup>
                  </Box>

                  <Box mb={2}>
                    <HeaderFilter title={"Item Type"} />
                    <FormGroup style={{ color: "#000000" }}>
                      {this.props.shop.itemTypes.map((item) => (
                        <CheckBox
                          sx={{
                            color: "#000000",
                            "&.Mui-checked": {
                              color: "000000",
                            },
                          }}
                          key={item.id}
                          label={item.attributes.name}
                          onChange={this.onChangeItemType}
                          value={item.id}
                        />
                      ))}
                    </FormGroup>
                  </Box>

                  <Box mb={2}>
                    <HeaderFilter title={"Type"} />
                    <FormGroup style={{ color: "#000000" }}>
                      {/* <RadioGroupCustom
                        items={this.props.shop.types}
                        onChange={this.onChangeType}
                      /> */}
                      {this.props.shop.types.map((item) => (
                        <CheckBox
                          sx={{
                            color: "#000000",
                            "&.Mui-checked": {
                              color: "000000",
                            },
                          }}
                          key={item.id}
                          label={item.attributes.name}
                          onChange={this.onChangeType}
                          value={item.id}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={9} style={{ backgroundColor: "transparent" }}>
              <Grid container justifyContent={"space-between"} columns={9} mb={2}>
                <Grid item md={3} xs={9}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ borderColor: "red", borderRadius: 0 }}
                    style={{ borderColor: "red", borderRadius: 0 }}
                  >
                    <CssTextField
                      id="sortBy"
                      value={this.state._sortBy}
                      onChange={this.onChangeSortBy}
                      select
                      label="Sort By"
                      componentsProps={{ sx: { borderColor: "red", paddingY: 8.5 } }}
                      inputProps={{ sx: { borderColor: "red" } }}
                      style={{ borderColor: "red", borderRadius: 0 }}
                      labelId="sortBy-label"
                    >
                      <MenuItem value={PRICE_LOW_TO_HIGH}>Price low to high</MenuItem>
                      <MenuItem value={PRICE_HIGH_TO_LOW}>Price high to low</MenuItem>
                      <MenuItem value={RECENT_LISTING}>Recent listing</MenuItem>
                    </CssTextField>
                  </FormControl>
                </Grid>
                <Box display={"flex"} alignItems="center">
                  {this.props.discord?.code ? (
                    <BtnDropDown
                      color="#ffffff"
                      borderColor="#ffffff"
                      style={{ marginRight: 5 }}
                      label={
                        this.props.discord?.code
                          ? this.props.discord?.user?.username +
                            "#" +
                            this.props.discord?.user?.discriminator
                          : "Connect"
                      }
                      items={[
                        {
                          label: "Logout",
                          onClick: () => {
                            this.connectToDiscord();
                          },
                        },
                      ]}
                    />
                  ) : (
                    // <Button
                    //   sx={{
                    //     borderRadius: 0,
                    //     marginRight: 1,
                    //     borderWidth: 2,
                    //     color: "ffffff"
                    //   }}
                    //   onClick={this.connectToDiscord}
                    //   color="ijo"
                    //   variant="outlined"
                    //   size="medium"
                    //   endIcon={
                    //     <img
                    //       src={IMG_DISCORD_IJO}
                    //       alt="discord"
                    //       style={{
                    //         width: 16,
                    //         height: 16,
                    //       }}
                    //     />
                    //     // <ArrowDropDown />
                    //   }
                    // >
                    //   {/* {this.props.discord?.code ? "Disconnect" : "Connect"} */}
                    //   {this.props.discord?.code
                    //     ? this.props.discord?.user?.username +
                    //       "#" +
                    //       this.props.discord?.user?.discriminator
                    //     : "Connect"}
                    // </Button>
                    <Box
                      sx={{
                        marginRight: 1,
                        color: "#ffffff",
                        background: "transparent",
                        border: "solid 1px #ffffff",
                        padding: ".5rem 1rem",
                        cursor: "pointer",
                      }}
                      display="flex"
                      alignItems="center"
                      onClick={this.connectToDiscord}
                    >
                      <div>CONNECT</div>
                      <img
                        height="24px"
                        width="24px"
                        src={IMG_DISCORD}
                        style={{ marginLeft: "1rem" }}
                        alt=""
                      />
                    </Box>
                  )}
                  {/* topup button */}
                  <BtnOrangeShadow
                    style={{ marginRight: ".5rem", backgroundColor: "#CD7555", height: "40px" }}
                    onClick={this.onOpenPurchaseToken}
                  >
                    <Grid container justifyContent={"space-between"}>
                      <Box justifyContent={"space-between"} display="flex" alignItems={"center"}>
                        <Typography fontSize={12} fontWeight={"light"}>
                          Purchase
                        </Typography>
                        <Box ml={1}>
                          <Typography fontSize={16} fontWeight="bold">
                            $FUZZ
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </BtnOrangeShadow>
                  {/* button balance */}
                  <BtnOrangeShadow
                    style={{ backgroundColor: "#FFD62C", height: "40px" }}
                    onClick={this.yourBalance}
                  >
                    <Grid container justifyContent={"space-between"}>
                      <Box mr={2}>
                        <Typography textAlign={"left"} fontSize={10}>
                          Your
                        </Typography>
                        <Typography fontSize={10}>Balance</Typography>
                      </Box>
                      <Box justifyContent={"space-between"} display="flex" alignItems={"center"}>
                        <Typography fontSize={16} fontWeight={"bold"}>
                          {/* {configStaking?.balance || 0} */}
                          {this.props.shop.fuzzBalance || 0}
                        </Typography>
                        <Box ml={1}>
                          <Typography fontWeight={"bold"} fontSize={10}>
                            $FUZZ
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </BtnOrangeShadow>
                </Box>
              </Grid>

              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                columns={9}
                style={{ backgroundColor: "transparent" }}
              >
                {this.props.shop.products.map((item, index) => (
                  <Grid item xs={12} sm={12} md={3} key={item.id.toString()}>
                    <CardItem
                      onPurchase={this.purchase}
                      item={item}
                      soldOut={item.attributes.is_sold_out}
                      isClosed={item.attributes.is_closed}
                      discord_id={this.props.discord.user?.id}
                      actionGetProducts={this.props.actionGetProducts}
                    />
                  </Grid>
                ))}
              </Grid>
              {this.props.shop.products.length > 0 ? (
                <Grid container justifyContent={"flex-end"} mt={3} alignItems="center">
                  {this.props.shop?.meta?.pagination?.page > 1 ? (
                    <IconButton aria-label="next-page" onClick={this.toPreviousPage}>
                      <ArrowLeft sx={{ color: "white" }} />
                    </IconButton>
                  ) : null}

                  <Typography color={"rgba(255,255,255,1)"} textAlign="center" mb={0} p={0}>
                    {this.props.shop?.meta?.pagination?.page} /{" "}
                    {this.props.shop?.meta?.pagination?.pageCount}
                  </Typography>
                  {this.props.shop?.meta?.pagination?.page <
                  this.props.shop?.meta?.pagination?.pageCount ? (
                    <IconButton aria-label="next-page" onClick={this.toNextPage}>
                      <ArrowRight sx={{ color: "white", cursor: "pointer" }} />
                    </IconButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Container>
        <ModalToken
          open={this.state._openPurchaseToken}
          onClose={this.onClosePurchaseToken}
          sendTransaction={this.sendTransaction}
          loading={this.state.loading}
          message={this.state.message}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  configStaking: state.stake.config,
  shop: state.shop,
  discord: state.discord,
  wallet: state.wallet,
});

const mapDispatchToProps = {
  actionGetProducts: getProducts,
  actionGetItemTypes: getItemTypes,
  actionGetSaleTypes: getSaleTypes,
  actionGetTypes: getTypes,
  actionSetSaleTypes: setSalesTypes,
  actionSetItemTypes: setItemTypes,
  actionSetType: setType,
  actionSortBy: setSortBy,
  actionSetDiscordCode: setDiscordCode,
  actionGetDiscordToken: getToken,
  actionRestoreUserWalletAddress: restoreUserWalletAddress,
  actionSetConfigStaking: setConfigStaking,
  actionRestoreDiscordUser: restoreDiscordUser,
  actionGetDiscordMe: getMeFromCms,
  actionGetFuzzBalance: getFuzzBalance,
  actionUpdateBalance: updateBalance,
  actionCreateUserBalance: createUserBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shopping);