const initialState = {
  accessToken: null,
  code: null,

  user: null,
};

export const SET_DISCORD_CODE = "SET_DISCORD_CODE";
export const SET_DISCORD_ACCESS_TOKEN = "SET_DISCORD_ACCESS_TOKEN";
export const SET_DISCORD_USER = "SET_DISCORD_USER";

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISCORD_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case SET_DISCORD_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_DISCORD_USER:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default shopReducer;
