import { LanguageOutlined, Share, Twitter } from "@mui/icons-material";
import { Alert, Avatar, Grid, Snackbar, Link } from "@mui/material";
import React from "react";
import { IMG_DISCORD, IMG_OPEN_SEA } from "../../../data/images";
import ButtonBase from "@mui/material/ButtonBase";
import SnackBar from "./SnackBar";
import { makeId } from "../../../helper";

const SocialMediaIcon = ({ item }) => {
  const [open, setOpen] = React.useState(false);
  const to = (link) => {
    if (link) window.open(link, true);
  };
  return (
    <>
      <Grid justifyContent={"flex-end"} container columnGap={2}>
        {/* <SnackBar setOpen={setOpen} open={open} /> */}
        <ButtonBase
          onClick={() => {
            const itemId = typeof(item?.id) === 'number' ? item?.id : item?.id.split('&')[0]
            const url =
              window.location.origin + "/rarity?nft=" + itemId + "&_token=" + makeId(30);
            navigator.clipboard.writeText(url);
            setOpen(true);
          }}
        >
              <img src={require("../../../assets/img/ic_forward_red.png")} alt="discord" style={{ width: 16, height: 16 }} />
        </ButtonBase>
        {/* <Link href={`https://opensea.io/assets/ethereum/0xac0e8b91745ac44a6b19e4039cd6f297ef7e2744/${item?.id}`}> */}
          <ButtonBase onClick={() => to(`https://opensea.io/assets/ethereum/0xac0e8b91745ac44a6b19e4039cd6f297ef7e2744/${typeof(item?.id) === 'number' ? item?.id : item?.id.split('&')[0]}`)}>
            {/* <Avatar sx={{ bgcolor: "#CD7555", width: 20, height: 20, cursor: "pointer" }}> */}
              <img src={require("../../../assets/img/ic_opensea_red.png")} alt="discord" style={{ width: 16, height: 16 }} />
            {/* </Avatar> */}
          </ButtonBase>
        {/* </Link> */}
        {/* <ButtonBase onClick={() => to(item?.attributes.web)}>
        <Avatar
          sx={{
            bgcolor: "#CD7555",
            width: 20,
            height: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <LanguageOutlined sx={{ color: "white", fontSize: 12 }} />
        </Avatar>
      </ButtonBase> */}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="info"
          sx={{ width: "100%" }}
        >
          Link copied
        </Alert>
      </Snackbar>
    </>
  );
};

export default SocialMediaIcon;
