import { getShopBE, getStrapi, postShopBE, postStrapi } from "../../helper/axios";
import {
  SET_FILTER_ITEM_TYPES,
  SET_FILTER_SALE_TYPES,
  SET_FILTER_TYPE,
  SET_ITEM_TYPES,
  SET_LOADING,
  SET_META,
  SET_PRODUCTS,
  SET_SALE_TYPES,
  SET_SORT_BY,
  SET_TYPES,
  SET_BALANCE,
  SET_CREATE_BALANCE,
  SET_REDUCE_BALANCE,
  SET_DAILY_REWARD
} from "../reducers/shopReducer";
import qs from "qs";
import axios from "axios";
import { SHOP_BASE_URL } from "../../config";

export const getProducts =
  (params = { "pagination[page]": 1 }) =>
  async (dispatch) => {
    try {
      await dispatch({ type: SET_LOADING, payload: true });
      const query = qs.stringify(
        {
          populate: "item_types,image,sale_types",
          "pagination[pageSize]": 6,
          ...params,
          // populate: "*",
        },
        {
          encodeValuesOnly: false, // prettify URL
          arrayFormat: "repeat", // prettify URL
        }
      );
      const response = await getStrapi("shops?" + query);
      await dispatch({ type: SET_PRODUCTS, payload: response.data.data });
      await dispatch({ type: SET_META, payload: response.data.meta });
      return response;
    } catch (error) {
      console.log("error getProducts", error?.message);
      return error.response;
    }
  };

export const getSaleTypes =
  (params = { "pagination[page]": 1 }) =>
  async (dispatch) => {
    try {
      const response = await getStrapi("sale-types", {
        "pagination[pageSize]": 10,
        ...params,
      });
      await dispatch({ type: SET_SALE_TYPES, payload: response.data.data });
      return response;
    } catch (error) {
      console.log("error getSaleTypes", error?.message);
      return error.response;
    }
  };

export const getItemTypes =
  (params = { "pagination[page]": 1 }) =>
  async (dispatch) => {
    try {
      const response = await getStrapi("item-types", {
        "pagination[pageSize]": 10,
        ...params,
      });
      await dispatch({ type: SET_ITEM_TYPES, payload: response.data.data });
      return response;
    } catch (error) {
      console.log("error getItemTypes", error?.message);
      return error.response;
    }
  };

export const getTypes =
  (params = { "pagination[page]": 1 }) =>
  async (dispatch) => {
    try {
      const response = await getStrapi("types", {
        "pagination[pageSize]": 10,
        ...params,
      });
      await dispatch({
        type: SET_TYPES,
        payload: response.data.data.map((item) => {
          item.value = item.id;
          item.label = item.attributes.name;
          return item;
        }),
      });
      return response;
    } catch (error) {
      console.log("error getTypes", error?.message);
      return error.response;
    }
  };

export const setSalesTypes = (value) => async (dispatch) => {
  await dispatch({ type: SET_FILTER_SALE_TYPES, payload: value });
  return true;
};
export const setItemTypes = (value) => async (dispatch) => {
  await dispatch({ type: SET_FILTER_ITEM_TYPES, payload: value });
  return true;
};
export const setType = (value) => async (dispatch) => {
  await dispatch({ type: SET_FILTER_TYPE, payload: value });
  return true;
};
export const setSortBy = (value) => async (dispatch) => {
  await dispatch({ type: SET_SORT_BY, payload: value });
  return true;
};

export const postOrder =
  (data, params = {}) =>
  async (dispatch) => {
    try {
      const response = await postStrapi("orders", data, params);
      return { response, error: false };
    } catch (error) {
      console.log("error postOrder", error?.message);
      return { response: error.response, error: true };
    }
  };

export const postOrderRaffles =
  (data, params = {}) =>
  async (dispatch) => {
    try {
      const response = await postStrapi("order-raffles", data, params);
      return { response, error: false };
    } catch (error) {
      console.log("error postOrderRaffles", error?.message);
      return { response: error.response, error: true };
    }
  };

export const postPaymentOrder =
  (data, params = {}) =>
  async (dispatch) => {
    try {
      const response = await postStrapi("set-payment", data, params);
      return { response, error: false };
    } catch (error) {
      console.log("error postPaymentOrder", error?.message);
      return { response: error.response, error: true };
    }
  };

  export const setRandomWinner = 
  (data, params={}) => 
  async (dispatch) => {
    try {
      const response = await postStrapi("random-winners", data, params);
      if(response){
        return { response, error: false };
      } else{
        return{response: null, error:true}
      }
    } catch (error) {
      return { response: error.response, error: true };
    }
  }

  export const updateBalance = (payload, callback) => (dispatch) => {
    return axios.put(`${SHOP_BASE_URL}balance`, payload)
    .then((res) => {
        if(callback) callback(res);
        dispatch({
            type: SET_BALANCE,
            payload: res.data.data.amount,
          })
    })
    .catch((e) => {
        return e.response
    });
  }


  export const getFuzzBalance = (payload, callback) => (dispatch) => {
    return axios.get(`${SHOP_BASE_URL}balance/${payload.wallet_address}`)
        .then((res) => {
            if(callback) callback(res);
            dispatch({
                type: SET_BALANCE,
                payload: res.data.data.amount,
            })
        })
        .catch((e) => {
            dispatch(createUserBalance({wallet_address: payload.wallet_address}))
        });
}
  export const reduceBalance = (payload, callback) => (dispatch) => {
    return axios.put(`${SHOP_BASE_URL}balance/reduce`, payload)
        .then((res) => {
            if(callback) callback(res);
            dispatch({
                type: SET_BALANCE,
                payload: res.data.data.amount,
            })
        })
        .catch((e) => {
            return e.response
        });
}

export const createUserBalance = (payload, callback) => (dispatch) => {
  return axios.post(`${SHOP_BASE_URL}balance`, payload)
  .then((res) => {
      if(callback) callback(res);
      dispatch({
          type: SET_CREATE_BALANCE,
          payload: 0,
      })
  })
  .catch((e) => {
      return e.response
  });
}

export const getDailyReward = (payload, callback) => (dispatch) => {
  return axios.get(`${SHOP_BASE_URL}daily-reward/${payload.wallet_address}`)
      .then((res) => {
          if(callback) callback(res);
          dispatch({
              type: SET_DAILY_REWARD,
              payload: res.data.data.dailyReward,
          })
      })
      .catch((e) => {
        return e.response
      });
}

export const setWithdraw = (payload, callback) => (dispatch) => {
  return axios.post(`${SHOP_BASE_URL}withdraw`, payload)
      .then((res) => {
          if(callback) callback(res);
          dispatch({
              type: SET_BALANCE,
              payload: res.data.data.amount,
          })
      })
      .catch((e) => {
        return e.response
      });
}