const initialState = {
  show: false,
  wording: null,
  type: null
}

const alertReducer = (state = initialState, action) => {
  switch (action.type){
    case 'SET_ALERT_SUCCESS':
      return{ 
        ...state, 
        show: true,
        wording: action.payload.wording,
        type: action.payload.type
      }
    case 'SET_ALERT_UNMOUNT':
      return{ 
        ...state, 
        show: false,
        wording: null,
        type: null
      }
    default:
      return state
  }
}

export default alertReducer;