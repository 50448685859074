import {
  IMG_CHAMP,
  IMG_DAGELAN,
  IMG_JOHN_GAMBOA,
  IMG_KAI,
  IMG_KEVIN_RUM,
  IMG_TANIA,
  IMG_WIMO,
} from "./images";

const clan = [
  {
    name: "KEVIN RUM",
    role: "Conceptor",
    twitter: "https://twitter.com/kevinrum",
    character: "kevin.png",
    img: IMG_KEVIN_RUM,
  },
  {
    name: "TINY COZY CUB",
    role: "Artist",
    twitter: "https://twitter.com/tinycozycub",
    character: "tania.png",
    img: IMG_TANIA,
  },
  { name: "WIMO", role: "Project Lead", character: "wimo.png", img: IMG_WIMO },
  {
    name: "JOHN GAMBOA",
    role: "Strategist",
    character: "johng.png",
    twitter: "https://twitter.com/johng_9000?s=21",
    img: IMG_JOHN_GAMBOA,
  },

  {
    name: "KAI",
    role: "Advisor",
    twitter: "https://twitter.com/pangxuekai",
    character: "kai.png",
    img: IMG_KAI,
  },
  {
    name: "DAGELAN",
    role: "Advisor",
    twitter: "https://twitter.com/Dagelanco",
    character: "dagelan.png",
    img: IMG_DAGELAN,
  },
  {
    name: "Champ T",
    role: "Advisor",
    twitter: "https://twitter.com/champtgram",
    character: "champ.png",
    img: IMG_CHAMP,
  },
  {
    name: "PLAYHOUSE",
    role: "Blockchain Advisor",
    character: "playhouse.png",
    img: IMG_CHAMP,
  },
];

export default clan;
